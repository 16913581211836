<template>
    <div>
        <!-- // Header Bar START // -->
                            <div class="top-navigation h-[70px]">
                                <div class="block">
                                    <div class="top-navigation--route flex items-center">
                                        <div class="h-7 w-7"></div>
                                        <h2 class="top-navigation--title whitespace-nowrap">
                                        Users List
                                        </h2>
                                    </div>
                                    <div class="flex items-center space-x-2">
                                    </div>
                                </div>
                            </div>
        <!-- // Header Bar END //  -->
        <div class="mx-6 mt-6">
            <ControlBar
                :isAdminPanelView="isAdminPanelView"
                :handleFilterQuery="handleFilterQuery"
            />
            <AdminPanelHeaderBar
                :toggleExportUserState="toggleExportUserState"
                :isUserList="isUserList"
                :fetchUserCallback="getList"
                :handleRoleSelect="handleRoleSelect"
                :parentsList="parentsList"
            />
        </div>

        <div class="users-list-wrapper">
            <div class="users-list-wrapper-heading">
                <div
                    v-if="exportUserState"
                    class="users-list-wrapper-heading-all"
                >
                    <input
                        type="checkbox"
                        class="checkbox-template"
                        :value="true"
                        v-model="toggleAllUserExport"
                        v-on:change="toggleAllUserExportMapArray"
                    />
                </div>
                <div class="users-list-wrapper-heading-email">
                    <p class="email">Email</p>
                </div>
                <div class="users-list-wrapper-heading-name">
                    <p class="name">Full name</p>
                </div>
                <div class="users-list-wrapper-heading-role">
                    <p class="role">Role</p>
                </div>
                <div class="users-list-wrapper-heading-access">
                    <p class="access">Access</p>
                </div>
                <div class="users-list-wrapper-heading-parent">
                    <p class="parent">Parent</p>
                </div>
                <div class="users-list-wrapper-heading-subscription">
                    <p class="subscription">Subscription</p>
                </div>
                <div class="users-list-wrapper-heading-date">
                    <p class="date">Till date</p>
                </div>
                <div v-if="userInfo.role === 'admin'" class="users-list-wrapper-body-item-button-action" style="text-align: end">
                  <span @click="requestExcelView"><i class="fas fa-file-excel"></i></span>
                </div>
            </div>
            <div class="users-list-wrapper-body">
                <div
                    class="users-list-item-wrapper"
                    v-for="(item, index) in list"
                    :key="item._id"
                >
                    <div
                        class="users-list-wrapper-body-item-control"
                        v-if="exportUserState"
                    >
                        <input
                            type="checkbox"
                            class="checkbox-template"
                            v-model="exportUserMapArray[index].state"
                            :value="exportUserMapArray[index].id"
                        />
                    </div>
                    <div class="users-list-wrapper-body-item">
                        <p class="users-list-wrapper-body-item-email">
                            {{ item.email }}
                        </p>
                        <p class="users-list-wrapper-body-item-name">
                            {{ item.name }} {{ item.lastname }}
                        </p>
                        <p class="users-list-wrapper-body-item-role">
                            {{ item.role.toUpperCase() }}
                        </p>
                        <p class="users-list-wrapper-body-item-access">
                            {{ item.user_role }} {{ item.affiliation }}
                        </p>
                        <p class="users-list-wrapper-body-item-parent">
                            {{ (item.parent && item.parent.email) || '' }}
                        </p>
                        <p class="users-list-wrapper-body-item-subscription">
                            {{getSubscriptionAndDiscount(item)}}
                        </p>
                        <p class="users-list-wrapper-body-item-date">
                            {{ getTillTo(item) }}
                        </p>

                        <div class="users-list-wrapper-body-item-button-action">
                            <div
                                v-if="
                                    userInfo.role === 'admin' ||
                                    userInfo.role === 'agent'
                                "
                            >
                                <span
                                    @click="
                                        () =>
                                            handleModalWindow({
                                                type: 'EditUser',
                                                config: {
                                                    editId: item._id,
                                                    userEmail: item.email,
                                                    role: `${item.role
                                                        .charAt(0)
                                                        .toUpperCase()}${item.role.slice(
                                                        1
                                                    )}`,
                                                    parent: item.parent,
                                                    parentsList: parentsList,
                                                    fullName: {
                                                        name: item.name,
                                                        lastName: item.lastname,
                                                    },
                                                    expiry: item.stripe .subscription_expiry && item.stripe.subscription ? item.stripe.subscription_expiry : 0,
                                                    affiliation: item.affiliation,
                                                    userRole: item.user_role,
                                                    subscription: {
                                                        value: item.stripe.subscription? item.stripe.subscription_type : 'none',
                                                    },
                                                    active: item.active,
                                                    is_domain_default: item.is_domain_default,
                                                    fetchUserCallback: getList,
                                                    featurePreviewAllowed: !!item.extra_data?.featurePreviewAllowed
                                                },
                                            })
                                    "
                                >
                                    <i class="fas fa-pen"></i>
                                </span>
                                <span title="Delete User" v-if="userInfo.role === 'admin'"
                                    @click="
                                        () =>
                                            handleModalWindow({
                                                type: 'DeleteUser',
                                                config: {
                                                    userId: item._id,
                                                    deleteCallback: getList
                                                }
                                            })
                                    "
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </span>
                                <span
                                    @click="(e) => handleContextMenu(e, item)"
                                >
                                    <i class="fas fa-ellipsis-h"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-pagination">
                <div id="pagination">
                    <paginate
                        ref="pagin"
                        :page-count="totalPages"
                        :click-handler="nextPage"
                        :prev-text="'←'"
                        :next-text="'→'"
                        :container-class="'page-numbers'"
                    >
                    </paginate>
                </div>
            </div>
        </div>

        <div class="confirmationBlock" v-if="exportUserState">
            <multiselect
                v-model="valueOptionFormat"
                :disabled="true"
                :searchable="false"
                :show-labels="false"
                :options="optionFormat"
            ></multiselect>
            <button
                type="button"
                class="button button--green"
                @click="handleExportUser"
            >
                <i class="fas fa-file-export"></i>
                Export selected
            </button>
            <button type="button" class="cancel" @click="hideExportUserState">
                <i class="fa fa-times" aria-hidden="true"></i>
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import ControlBar from '../../components/ui/ControlBar.vue'
import AdminPanelHeaderBar from '../../components/ui/AdminPanelHeaderBar.vue'
import Multiselect from 'vue-multiselect'
import {DTFormat} from "../../util/helpersItem";
import * as Sentry from "@sentry/vue";
import { captureReportableError } from '@/util/errorTracker';

export default {
    data() {
        return {
            isAdminPanelView: true,
            isUserList: true,
            selectImportType: 'user-import',
            ifExist: 'skip',
            pricingPlan: '',
            parentAgent: '',
            bulkDomain: '',
            bulkCancelNow: true,
            sending: false,
            showSummary: -1,
            showResellerReport: -1,
            viewItem: null,
            viewReport: null,
            totalPages: 0,
            filters: {
                search: '',
                page: 1,
                page_size: 10,
                role: '',
            },
            list: [],
            progress: 0,
            file: null,
            loading: false,
            selItem: null,
            curSearch: '',
            selIds: [],
            checkAll: false,
            editLoading: false,
            editId: '',
            selectSubscription: '',
            selectedSubscriptionPrev: '',
            parentsList: { agent: [], reseller: [] },
            mapParents: { user: 'agent', agent: 'reseller' },
            selectExpiry: 0,
            viewOrderCol: null /* order column name or null */,
            viewOrderDir: -1 /* -1 => order desc, 1 => order asc */,
            curAddedCnt: 0 /* -1 => order desc, 1 => order asc */,
            totalAddCnt: 0 /* -1 => order desc, 1 => order asc */,
            productList: null,
            pricingList: null,
            valueOptionFormat: 'csv',
            optionFormat: ['csv'],
            exportUserState: false,
            toggleAllUserExport: false,
            exportUserMapArray: [],
            filterQuery: '',
            isSearchViewMode: false,
          contextMenuOptions: [
            {value: 1, text: 'Login as...'},
            {value: 2, text: 'Summary'}
          ]
        }
    },
    methods: {
      ...mapActions(['handleModalWindow']),
      ...mapMutations(['storeSetContextMenuOptions', 'storeSetGlobalDocumentBusy']),
      handleContextMenu(e, item) {
        this.selItem = item;
        const contextMenuOptions = {
          top: e.pageY,
          left: e.pageX,
          options: this.contextMenuOptions,
          show: true
        }
        this.storeSetContextMenuOptions(contextMenuOptions);
      },
      async handleContextMenuSelect(payload) {
        this.storeSetContextMenuOptions({show: false});
        const item = this.selItem;
        switch(payload?.value) {
          case 1:
            this.storeSetGlobalDocumentBusy(true);
            try {
              const result = await this.$api.post('/admin/login-as', {
                email: item.email
              });

              const userData = result.data.user;
              this.$store.commit("UPDATE_USER_INFO", userData);

              Sentry.setUser({id: userData._id, email: userData.email});
              this.$store.commit("LOGIN_USER");

              await this.$router.push('/');
            }
            catch (e) {
              captureReportableError(e);

              this.$notify({
                group: 'auth',
                type: 'error',
                title: 'Error',
                text: e.response?.data?.message || e.message,
              })
            }
            this.storeSetGlobalDocumentBusy(false);
            break;
          case 2:
            this.handleModalWindow({
              type: 'SummaryUser',
              config: {
                userId: item._id,
                userEmail: item.email,
                userName: item.name,
                userLastName: item.lastname,
                userRole: item.role,
                userUserRole: item.user_role,
                userParent: item.parent,
                userActive: item.active,
                lastLog: item.last_login ? this.staticDTFormat(item.last_login, 'DD/MM/YYYY, HH:mm:ss') : '',
                userSubscriptionPlan: this.getSubscriptionAndDiscount(item),
                isMonthlyReport: item.report_monthly_user
              }
            });
            break;
        }
      },
      staticDTFormat(date) {
        return DTFormat(date);
      },
      async handleRoleSelect(val) {
        this.filters.role = val;
        this.filters.page = 1;
        await this.getList();
      },
      getSubscriptionAndDiscount(item) {
          if (!item || !item.stripe || !item.stripe.subscription) return ''
          return (
        (item.stripe.subscriptionProductId && this.productName(item.stripe.subscriptionProductId) || item.stripe.subscription) + this.getDiscountInfo(item)
          )
      },
      toggleExportUserState(state) {
          this.exportUserState =
              state !== undefined ? state : !this.exportUserState
      },
      hideExportUserState() {
          this.exportUserState = false
      },
      getProductList() {
          this.$api.get('/admin/productList').then((list) => {
              this.productList = list.data
          })
      },
      getPricingPlansList() {
          this.$api.get('/admin/pricing-plans').then((list) => {
              this.pricingList = list.data
              this.pricingPlan = this.pricingList.institution.new
          })
      },
      async getList(page) {
          this.selIds = []
          if (page) {
              this.filters.page = page
              this.$refs.pagin.selected = page
              await this.$router.push({ query: this.filters })
          }
          const params = {
              search: this.filters.search,
              role: this.filters.role,
              skip: (this.filters.page - 1) * this.filters.page_size,
              page_size: this.filters.page_size,
              needCalculate: true,
          }
        this.storeSetGlobalDocumentBusy(true);
        this.$api
              .get('/user/list', { params: params })
              .then((result) => {
                  this.curSearch = this.filters.search
                  this.totalPages = Math.ceil(
                      result.data.count / this.filters.page_size
                  )
                  this.list = result.data.list
                  if (result.data && result.data.parents) {
                      this.parentsList = result.data.parents
                  }
              })
              .then(() => {
                this.getUserExportMapArray()
              })
              .catch((err) => {
                captureReportableError(err);

                this.$notify({
                  group: "auth",
                  type: "error",
                  title: "Error",
                  text: err.response?.data?.message || err.response?.message || err.message
                });
              })
              .finally(() => {
                this.storeSetGlobalDocumentBusy(false);
              })
      },
      datetimeFormat(date) {
          return date === null || date === ''
              ? ''
              : DTFormat(date,'DD/MM/YYYY, HH:mm:ss')
      },
      productName(productId) {
        if(typeof productId !== 'string') {
          productId = productId.id;
        }
          if (this.productList) {
              const item = this.productList.find((product) => {
                  return product.id === productId
              })
              return item ? item.name : ''
          } else {
              return ''
          }
      },
      async nextPage(page) {
          this.filters.page = page
          await this.$router.push({ query: this.filters })
          this.getList(page)
      },
      getTillTo(item) {
        if(!item.stripe.subscription_state) return '';

        if (item.trialTill) {
            return ('Trial: ' + DTFormat(item.stripe.subscriptionTrialEnd*1000,'DD/MM/YYYY'))
        } else {
            let v
            if (item.stripe.subscriptionCancelsAt) {
                v = DTFormat(item.stripe.subscriptionCancelsAt*1000,'DD/MM/YYYY');
            } else {
              v = DTFormat(item.stripe.subscriptionCurrentPeriodEnd*1000,'DD/MM/YYYY');
            }
            return v
        }
      },
      getDiscountInfo(item) {
      if (!item.stripe.subscriptionDiscountEnd) return ''
      return (' (discount till ' + DTFormat(item.stripe.subscriptionDiscountEnd * 1000, 'DD/MM/YYYY') + ')')
    },
      getUserExportMapArray() {
          this.exportUserMapArray = this.list.map((el) => {
              return {
                  id: el._id,
                  state: false,
              }
          })
      },
      toggleAllUserExportMapArray() {
          this.exportUserMapArray.forEach((el) => {
              el.state = this.toggleAllUserExport
          })
      },
      handleExportUser() {
          this.selIds = this.exportUserMapArray.map((e) => e.id)
          this.$api({
              url: '/admin/export-users',
              method: 'POST',
              responseType: 'arraybuffer'
              })
              .then((response) => {
                  const blobData = new Blob([response.data])
                  const url = window.URL.createObjectURL(blobData)
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', "export-users.zip")
                  document.body.appendChild(link)
                  link.click()
              })
              .then(() => {
                  this.toggleAllUserExport = false
                  this.toggleAllUserExportMapArray()
                  this.hideExportUserState()
              })
              .catch((e) => {
                  captureReportableError(e);

                  this.$notify({
                      group: 'auth',
                      type: 'error',
                      title: 'Error in export',
                      text: e.message,
                  })
              })
      },
      handleFilterQuery(value) {
          this.filterQuery = value
          this.filters.search = value
          this.isSearchViewMode = this.filterQuery.length > 0
          this.getList(1)
      },
      requestExcelView() {
        const params = {
          search: this.filters.search,
          role: this.filters.role,
          skip: (this.filters.page - 1) * this.filters.page_size,
          page_size: this.filters.page_size,
          needCalculate: true,
          excel_view: 1
        }
        this.storeSetGlobalDocumentBusy(true);

        this.$api.get('/user/list', { params: params, responseType : 'arraybuffer' })
            .then((result) => {
              const blobData = new Blob([result.data])
              const url = window.URL.createObjectURL(blobData)
              const link = document.createElement('a')
              link.href = url
              link.setAttribute('download', "users.xlsx")
              document.body.appendChild(link)
              link.click()
            })
          .catch((err) => {
              captureReportableError(err);

              this.$notify({
                group: "auth",
                type: "error",
                title: "Error",
                text: err.response?.data?.message || err.response?.message || err.message
              });
            })
            .finally(() => {
              this.storeSetGlobalDocumentBusy(false);
            })
      }
    },
    computed: {
      ...mapGetters(['storeUserInfo']),
        userInfo() {
            return this.storeUserInfo
        },
    },
    mounted() {
      this.$root.$on('ContextMenu:select', this.handleContextMenuSelect);

      if (this.$route.query.page) {
            this.filters = Object.assign({}, this.$route.query)
            setTimeout(() => {
                this.$refs.pagin.selected = this.$route.query.page - 1
            }, 100)
        }
        if (this.userInfo.role === 'admin' || this.userInfo.role === 'agent') {
            this.getProductList()
            this.getPricingPlansList()
        }
        this.getList()
    },
    beforeDestroy() {
      this.$root.$off('ContextMenu:select', this.handleContextMenuSelect);
    },
  watch: {
        '$route.path'() {
            this.getList()
        },
        checkAll: function (val) {
            if (val) {
                this.selIds = this.list.map((item) => item._id)
            } else {
                this.selIds = []
            }
        },
    },
    components: {
        ControlBar,
        AdminPanelHeaderBar,
        Multiselect
    },
}
</script>
