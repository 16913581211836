<template>
    <div class="attachment-list-container" :class="{'my-6' : tempAttachments.length > 0 }">
        <div
            class="dz-preview-custom"
            v-for="(tempAttachment, index) in tempAttachments"
        >
            <AttachmentItem
                :tempAttachment="tempAttachment"
                :dropzonePageCounterSettings="dropzonePageCounterSettings"
                :removeFile="removeFile"
                :index="index"
                :handleDropzonePageCounterSettings="
                    handleDropzonePageCounterSettings
                "
            />
        </div>
    </div>
</template>

<script>
import AttachmentItem from '@/components/ui/AttachmentItem.vue'
export default {
    props: {
        tempAttachments: {
            type: Array,
        },
        attachments: {
            type: Array,
        },
        removeFile: {
            type: Function,
        },
        dropzonePageCounterSettings: {
            type: Array,
        },
        handleDropzonePageCounterSettings: {
            type: Function,
        },
    },
    data() {
        return {}
    },
    methods: {},
    components: {
        AttachmentItem,
    },
}
</script>
