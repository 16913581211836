import * as Sentry from "@sentry/vue";

/*
 * @format
 */
const domain = new URL(window.location.href);
const msalParams = {
  auth: {
    authority: "https://login.microsoftonline.com/consumers",
    clientId: import.meta.env.VITE_MICROSOFT_ONEDRIVE_ID,
    redirectUri: `${domain.protocol}//${domain.host}`,
  },
};

const app = new msal.PublicClientApplication(msalParams);

export async function getAccessTokens() {
  let accessToken = "";
  let oid = null;

  const authParams = { scopes: ["OneDrive.ReadWrite"] };
  try {
    const resp = await app.acquireTokenSilent(authParams);

    accessToken = resp.accessToken;
    oid = resp.idTokenClaims.oid;

  } catch (e) {
    Sentry.captureException(e);

    const resp = await app.loginPopup(authParams);
    app.setActiveAccount(resp.account);
    if (resp.idToken) {
      const resp2 = await app.acquireTokenSilent(authParams);
      accessToken = resp2.accessToken;
      oid = resp2.idTokenClaims.oid;
    }
  }
  return [accessToken, oid];
}

export async function removeAccessToken() {
  await app.logoutPopup();
}

export function getOnedriveOrigin() {
  return `${domain.protocol}//${domain.host}`;
}
