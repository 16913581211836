<!-- @format -->
<template>
  <div class="page home-page h-[100%] w-full">
    <ContextMenu ref="refContextMenu" v-show="contextMenuShow"></ContextMenu>
    <right-click-context-menu
      v-show="getShowContextMenuArticleView"
      :showData="getShowContextMenuArticleView"
      :styleData="getShowContextMenuArticlePosition"
      :actionFunction="actionShowContextMenuArticle"
      ref="rightContextMenuComponent"
    ></right-click-context-menu>
    <notifications group="auth"></notifications>
    <div class="page-content flex justify-end">
      <div class="modal-cover-panel" v-if="isBusy">
        <Spinner size="huge"></Spinner>
      </div>
      <side-bar v-if="isLoggedIn"></side-bar>
      <notifications group="signup" position="top center" width="26%"></notifications>
      <div
        @click="
          () => {
            if (isSideBarOpen) {
              handleSideBar();
            }
          }
        "
        class="w-full"
        :class="{
          'w-full opacity-100': !isLoggedIn,
          'lg:w-[calc(100%-250px)]': isLoggedIn,
          'max-lg:fixed max-lg:opacity-40': isSideBarOpen,
          'opacity-100': !isSideBarOpen,
        }"
      >
        <vue-ins-progress-bar></vue-ins-progress-bar>
        <vue-simple-progress-bar
          v-if="progressBarValue > 0"
          text-position="inside"
          :font-size="14"
          text-align="center"
          :val="progressBarValue"
          :text="progressBarText"
          id="id-simple-progress-bar"
          style="display: block; z-index: 999999; position: relative; width: 100%"
          bar-color="#9fe2bf"
        >
        </vue-simple-progress-bar>
        <div class="main-view" v-bind:class="{ logged: isLoggedIn }" ref="main-view" id="main-view">
          <router-view />
        </div>
      </div>
    </div>
    <BaseModal />
    <OneDrivePicker />
    <ZoteroPicker />
    <EditZoteroCredentials :clientID="zoteroClientID" :apiKey="zoteroApiKey" />
  </div>
</template>

<script>
import { SideBar } from "@/components/index.js";
import BaseModal from "@/components/modal/BaseModal.vue";
import OneDrivePicker from "@/components/modal/OneDriveNoBaseModal.vue";
import ContextMenu from "@/components/ui/ContextMenu.vue";
import RightClickContextMenu from "@/components/ui/RightClickContextMenu.vue";
import Spinner from "vue-simple-spinner";
import { mapActions, mapGetters } from "vuex";
import EditZoteroCredentials from "./components/modal/EditZoteroCredentials.vue";
import ZoteroPicker from "./components/modal/ZoteroNoBaseModal.vue";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions([
      "fetchLibrariesSummary",
      "increaseGeneralProgressBarState",
      "decreaseGeneralProgressBarState",
      "actionShowContextMenuArticle",
      "handleSideBar",
      "handleModalWindow",
    ]),
  },
  computed: {
    ...mapGetters([
      "isLoggedIn",
      "storeUserInfo",
      "generalProgressBarState",
      "generalUploadProgressBarCount",
      "generalUploadProgressBarCurrent",
      "getShowContextMenuArticleView",
      "getShowContextMenuArticlePosition",
      "storeGetContextMenuOptions",
      "storeGetGlobalDocumentBusy",
      "sideBarState",
      "storeLeftMenu",
    ]),
    progressBarValue() {
      return this.generalUploadProgressBarCount
        ? Math.round((this.generalUploadProgressBarCurrent / this.generalUploadProgressBarCount) * 100)
        : 0;
    },
    progressBarText() {
      return this.generalUploadProgressBarCount
        ? `${this.generalUploadProgressBarCurrent}/${this.generalUploadProgressBarCount}`
        : "";
    },
    contextMenuShow() {
      return this.storeGetContextMenuOptions.show;
    },
    isBusy() {
      return this.storeGetGlobalDocumentBusy;
    },
    userInfo() {
      return this.storeUserInfo;
    },
    zoteroClientID() {
      return this.userInfo?.extra_data?.zotero?.userID || "";
    },
    zoteroApiKey() {
      return this.userInfo?.extra_data?.zotero?.apiKey || "";
    },
    isSideBarOpen() {
      return this.sideBarState.isOpen;
    },
    leftMenu() {
      return this.storeLeftMenu;
    },
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.increaseGeneralProgressBarState();
      next();
    });

    this.$router.afterEach((to, from) => {
      this.decreaseGeneralProgressBarState();
    });
  },
  mounted() {
    window.scroll(0, 0);
    this.isLoggedIn && this.fetchLibrariesSummary();
  },
  watch: {
    isLoggedIn() {
      if (!this.isLoggedIn) {
        this.$router.push("/login");
      }
    },
    $route(to, from) {
      to.name === "home" && this.isLoggedIn && this.fetchLibrariesSummary();
    },
    generalProgressBarState(val) {
      if (val > 0) {
        this.$insProgress.start();
      } else {
        this.$insProgress.finish();
      }
    },
  },
  components: {
    EditZoteroCredentials,
    ZoteroPicker,
    SideBar,
    Spinner,
    BaseModal,
    OneDrivePicker,
    RightClickContextMenu,
    ContextMenu,
  },
};
</script>

<style lang="scss">
@import "../public/css/main.css";
@import "./styles/fonts.scss";
// New tailwind styles go here.
@import "./styles/app.css";
@import "./styles/main.scss";
</style>
