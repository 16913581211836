<template>
    <div>
         <!-- // Header Bar START // -->
                <div class="top-navigation h-[70px]">
                    <div class="block">
                        <div class="top-navigation--route flex items-center">
                            <div class="h-7 w-7"></div>
                            <h2 class="top-navigation--title whitespace-nowrap">
                            Blocked List
                            </h2>
                        </div>
                        <div class="flex items-center space-x-2">
                        </div>
                    </div>
                </div>
                <!-- // Header Bar END //  -->
        <div class="mx-6 mt-6">
            <ControlBar
                :isAdminPanelView="isAdminPanelView"
                :handleFilterQuery="handleFilterQuery"
            />
            <AdminPanelHeaderBar
                :isBlockList="isBlockList"
                :addEmailCallback="getList"
            />
        </div>
        <div class="users-list-wrapper">
            <div class="users-list-wrapper-heading">
                <div class="users-list-wrapper-heading-blocklist-email">
                    <p class="email">Email</p>
                </div>
                <div class="users-list-wrapper-heading-description">
                    <p class="description">Description</p>
                </div>
                <div class="users-list-wrapper-heading-date">
                    <p class="date">Updated</p>
                </div>
                <div class="users-list-wrapper-heading-empty"></div>
            </div>
            <div class="users-list-wrapper-body">
                <div
                    v-for="item in list"
                    :key="item._id"
                    class="users-list-item-wrapper"
                >
                    <div class="users-list-wrapper-body-item">
                        <p class="users-list-wrapper-body-item-blocklist-email">
                            {{ item.email }}
                        </p>
                        <p class="users-list-wrapper-body-item-description">
                            {{ item.description }}
                        </p>
                        <p class="users-list-wrapper-body-item-date">
                            {{ dateTimeFormat(item.modified_at) }}
                        </p>
                        <div class="users-list-wrapper-body-item-button-action">
                            <span
                                @click="
                                    () =>
                                        handleModalWindow({
                                            type: 'EditEmail',
                                            config: {
                                                editId: item._id,
                                                userEmail: item.email,
                                                userDescription:
                                                    item.description,
                                                editEmailCallback: getList,
                                            },
                                        })
                                "
                            >
                                <i class="fas fa-pen"></i>
                            </span>
                            <span
                                @click="
                                    () =>
                                        handleModalWindow({
                                            type: 'DeleteEmail',
                                            config: {
                                                emailId: item._id,
                                                deleteEmailCallback: getList,
                                            },
                                        })
                                "
                            >
                                <i class="fa fa-trash"></i
                            ></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

import ControlBar from '@/components/ui/ControlBar.vue'
import AdminPanelHeaderBar from '@/components/ui/AdminPanelHeaderBar.vue'
import {DTFormat} from "../../util/helpersItem";

export default {
    data() {
        return {
            isAdminPanelView: true,
            isBlockList: true,
            list: [],
            selItem: null,
            search: '',
            curSearch: '',
            selIds: [],
            filterQuery: '',
            isSearchViewMode: '',
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
        getList() {
            this.selIds = []
            const params = { search: this.search }
            this.$api
                .get('/admin/blacklist', { params: params })
                .then((result) => {
                    this.curSearch = this.search
                    this.list = result.data
                })
        },
        dateTimeFormat(datetime) {
          return DTFormat(datetime,'DD/MM/YYYY, HH:mm')
        },
        handleFilterQuery(value) {
            this.filterQuery = value
            this.search = value
            this.isSearchViewMode = this.filterQuery.length > 0
            this.getList(1)
        }
    },
    watch: {
        '$route.path'() {
            this.getList()
        },
    },
    mounted() {
        this.getList()
    },
    components: {
        ControlBar,
        AdminPanelHeaderBar,
    },
}
</script>
