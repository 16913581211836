<template>
    <div>
        <!-- // Header Bar START // -->
            <div class="top-navigation h-[70px]">
                <div class="block">
                    <div class="top-navigation--route flex items-center">
                        <div class="h-7 w-7"></div>
                        <h2 class="top-navigation--title whitespace-nowrap">
                        Approved List
                        </h2>
                    </div>
                    <div class="flex items-center space-x-2">
                    </div>
                </div>
            </div>
            <!-- // Header Bar END //  -->
        <div class="mx-6 mt-6">
            <ControlBar
                :isAdminPanelView="isAdminPanelView"
                :handleFilterQuery="handleFilterQuery"
            />
            <AdminPanelHeaderBar
                :isApproveList="isApproveList"
                :domaineCallback="getList"
            />
        </div>
        <div class="users-list-wrapper">
            <div class="users-list-wrapper-heading">
                <div class="users-list-wrapper-heading-domain">
                    <p class="email">Domain</p>
                </div>
                <div class="users-list-wrapper-heading-product">
                    <p class="name">Product</p>
                </div>
                <div class="users-list-wrapper-heading-plan">
                    <p class="role">Plan</p>
                </div>
                <div class="users-list-wrapper-heading-currency">
                    <p class="access">Currency</p>
                </div>
                <div class="users-list-wrapper-heading-updated">
                    <p class="parent">Updated</p>
                </div>
                <div class="users-list-wrapper-heading-empty"></div>
            </div>
            <div class="users-list-wrapper-body">
                <div
                    class="users-list-item-wrapper"
                    v-for="item in list"
                    :key="item._id"
                >
                    <div class="users-list-wrapper-body-item">
                        <p class="users-list-wrapper-body-item-domain">
                            {{ item.domain }}
                        </p>
                        <p class="users-list-wrapper-body-item-product">
                            {{ item.product_name }}
                        </p>
                        <p class="users-list-wrapper-body-item-plan">
                            {{ item.plan_id }}
                        </p>
                        <p class="users-list-wrapper-body-item-currency">
                            {{ item.currency }}
                        </p>
                        <p class="users-list-wrapper-body-item-updated">
                            {{ dateTimeFormat(item.updated_at || item.modified_at) }}
                        </p>

                        <div class="users-list-wrapper-body-item-button-action">
                            <div
                                v-if="
                                    userInfo.role === 'admin' ||
                                    userInfo.role === 'agent'
                                "
                            >
                                <span
                                    @click="
                                        handleModalWindow({
                                            type: 'AddDomain',
                                            config: {
                                                domaineCallback: getList,
                                                editId: item._id,
                                                editPlanId: item.plan_id,
                                                editDomain: item.domain,
                                                editMaximumSignups: item.signups || 0,
                                                editExpireDate: item.expire || '',
                                                editAllowedIP: item.allowed_ips
                                            },
                                        })
                                    "
                                >
                                    <i class="fas fa-pen"></i>
                                </span>
                                <span
                                    @click="
                                        handleModalWindow({
                                            type: 'DeleteDomain',
                                            config: {
                                                deleteCallback: getList,
                                                editId: item._id,
                                            },
                                        })
                                    "
                                >
                                    <i class="fa fa-trash"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ControlBar from '@/components/ui/ControlBar.vue'
import AdminPanelHeaderBar from '@/components/ui/AdminPanelHeaderBar.vue'
import {DTFormat} from "../../util/helpersItem";

export default {
    data() {
        return {
            isAdminPanelView: true,
            isApproveList: true,
            list: [],
            selIds: [],
            filters: {
                search: '',
                role: '',
            },
            filterQuery: '',
            isSearchViewMode: '',
            curSearch: '',
            checkAll: false,
            totalPages: 0,
            parentsList: { agent: [], reseller: [] },
            mapParents: { user: 'agent', agent: 'reseller' },
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
        async getList() {
            const params = { search: this.filters.search }
            this.$api
                .get('/admin/whitelist', { params: params })
                .then((result) => {
                    this.list = result.data
                })
          // Request from Stripe latest products+prices
          await this.$api.get("/config/caching");
        },
        dateTimeFormat(datetime) {
          return DTFormat(datetime,'DD/MM/YYYY, HH:mm')
        },
        handleFilterQuery(value) {
            this.filterQuery = value
            this.filters.search = value
            this.isSearchViewMode = this.filterQuery.length > 0
            this.getList(1)
        },
    },
    computed: {
      ...mapGetters(['storeUserInfo']),
        userInfo() {
            return this.storeUserInfo
        },
    },
    mounted() {
        this.getList()
    },
    watch: {
        '$route.path'() {
            this.getList()
        },
    },
    components: {
        ControlBar,
        AdminPanelHeaderBar,
    },
}
</script>
