/** @format */

import * as Sentry from "@sentry/vue";
import { ButtonPlugin, FormPlugin, LayoutPlugin, ModalPlugin } from "bootstrap-vue";
import Vue from "vue";
import VueInsProgressBar from "vue-ins-progress-bar";
import Notifications from "vue-notification";
import VueSimpleProgressBar from "vue-simple-progress";
import Paginate from "vuejs-paginate";

import api, { apiBaseURL } from "./api";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { captureReportableError } from "./util/errorTracker";

import "bootstrap-vue/dist/bootstrap-vue.css";

const GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID;

Sentry.init({
  Vue,
  enabled: import.meta.env.VITE_SENTRY_DSN,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: import.meta.env.VITE_SENTRY_RELEASE,
  environment: import.meta.env.VITE_SENTRY_ENV,
  // Session Replay: Capture 0% of all sessions by default, as they're very expensive!
  replaysSessionSampleRate: 0,
  // Session Replay: Capture all sessions that have errors
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      networkDetailAllowUrls: [apiBaseURL],
    }),
  ],
  beforeSend(event, hint) {
    // Augment the event with the error response data if it's an Axios error
    // https://github.com/getsentry/sentry-javascript/issues/4377#issuecomment-1413032118
    if (hint && hint.originalException && hint.originalException.isAxiosError) {
      if (hint.originalException.response && hint.originalException.response.data) {
        event.contexts = {
          ...event.contexts,
          response: {
            data: hint.originalException.response.data,
          },
        };
      }
    }
    return event;
  },
});

Vue.use(Notifications);
Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(FormPlugin);
Vue.use(ButtonPlugin);

Vue.use(VueInsProgressBar, {
  position: "fixed",
  show: true,
  height: "16px",
});

Vue.component("VueSimpleProgressBar", VueSimpleProgressBar);
Vue.component("paginate", Paginate);

Vue.prototype.$googleClientId = GOOGLE_CLIENT_ID;
Vue.prototype.$trashLibId = Number.parseInt(import.meta.env.VITE_TRASHLIBID);
Vue.prototype.$favouritesId = Number.parseInt(import.meta.env.VITE_FAVOURITESID);

Vue.config.productionTip = false;

/**
 *
 * @param {Error} [error]
 * @param {{type: string; title: string; text: string; duration?: number} } [notification] - Notification to display on app init
 */
const handleNewSession = (error, notification) => {
  if (error) captureReportableError(error);

  const notify = {
    type: "success",
    title: "Welcome",
    text: "Please log in to continue.",
  };

  initApp(notification || notify);
};

/**
 * @param {Error} error
 * @param {{type: string; title: string; text: string; duration?: number} } [notification] - Notification to display on app init
 */
const handleExpiredSession = (error, notification) => {
  captureReportableError(error);

  store.commit("LOGOUT_USER");
  Sentry.setUser(null);

  const notify = {
    type: "error",
    title: "Session Expired",
    text: "Your session has expired. Please log in again.",
  };
  initApp(notification || notify);
};

/**
 *
 * @param {{type: string; title: string; text: string; duration?: number} } [notification] - Notification to display on app init
 */
const initApp = (notification) => {
  const app = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");

  Vue.directive("click-outside", {
    bind: function (el, binding, vnode) {
      el.clickEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener("click", el.clickEvent);
    },
    unbind: function (el) {
      document.body.removeEventListener("click", el.clickEvent);
    },
  });

  if (notification?.title) {
    app.$notify({
      group: "auth",
      ...notification,
    });
  }

  return app;
};

let notificationData;

api
  .get("/auth/signed-in")
  .then((response) => {
    if (response.data?.signedIn) {
      api
        .get("/user/profile")
        .then((profileResponse) => {
          const userData = profileResponse.data;

          store.commit("UPDATE_USER_INFO", profileResponse.data);

          Sentry.setUser({ id: userData._id, email: userData.email });
          store.commit("LOGIN_USER");
          initApp(notificationData);
        })
        .catch((e) => handleExpiredSession(e, notificationData));
    } else {
      handleNewSession(null, notificationData);
    }
  })
  .catch((e) => handleNewSession(e, notificationData));
