<template>
    <div>
        <form class="form">
            <div class="form-group">
                <label>Email</label>
                <input
                    type="email"
                    placeholder="Paste email here"
                    v-model="editEmail"
                />
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea
                    placeholder="Add description"
                    v-model="editDescription"
                ></textarea>
            </div>
        </form>
        <div class="modal-footer">
            <button
                class="button button--purple button--small"
                @click="handleSaveEmail"
                :disabled="isSubmitting"
            >
                <i class="fas fa-check-circle"></i>
                Save
            </button>
            <button
                class="button button--white button--small"
                @click.prevent="hideModal"
                :disabled="isSubmitting"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    data() {
        return {
            editEmail: '',
            editDescription: ''
        }
    },
    methods: {
        ...mapActions(['startModalNetworkRequest', 'endModalNetworkRequest']),
        async handleSaveEmail() {
            this.startModalNetworkRequest();
            const url = '/admin/blacklist/'
            const oUpdate = {
                email: this.editEmail,
                description: this.editDescription,
            }
            try {
                await this.$api.post(url, oUpdate)
                this.$notify({
                    group: 'auth',
                    type: 'success',
                    title: 'Success',
                    text: 'Blocklist record updated successfully',
                })
            } catch (err) {
                captureReportableError(err);

                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: err.response?.data?.message || err.response?.message || "Unknown error. Please try again later",
                })
            }
            this.modalWindowState.config.addEmailCallback()

            this.endModalNetworkRequest();
            this.hideModal()
        },
    },
    computed: {
        ...mapGetters(['modalWindowState']),
      isSubmitting() {
        return this.modalWindowState.isNetworkRequestLoading
      }
    },
}
</script>
