<template>
    <div>
      <!-- // Header Bar START // -->
                  <div class="top-navigation h-[70px]">
                      <div class="block">
                          <div class="top-navigation--route flex items-center">
                              <div class="h-7 w-7"></div>
                              <h2 class="top-navigation--title whitespace-nowrap">
                              Dead Accounts
                              </h2>
                          </div>
                          <div class="flex items-center space-x-2">
                          </div>
                      </div>
                  </div>
        <!-- // Header Bar END //  -->

        <div class="mx-6 mt-6">
            <ControlBar
              :isAdminPanelView="isAdminPanelView"
              :handleFilterQuery="handleFilterQuery"
          />
          <AdminPanelHeaderBar
              :isDeadList="true"
              :fetchUserCallback="getList"
              :parentsList="parentsList"
              @exportXLSX="handleExportUsers"
              @calculateDead="calculateDeadAccounts"
              @bulkEmail="handleBulkEmail"
              @bulkDelete="handleBulkDelete"
              @pickedDeadReason="handlePickedDeadReason"
              @pickedSelectedSearch="handlePickedSelectedSearch"
              :deadParams="{ createdAt: this.deadCreatedAt, emailedAt: this.deadEmailedAt }"
          />
        </div>


        <div class="users-list-wrapper">
            <div class="users-list-wrapper-heading">
              <div class="users-list-wrapper-heading-all">
                <div class="flex justify-start h-6 items-center">
                    <input aria-describedby="comments-description" :value="true" v-model="toggleAllDeadUsers" @change="toggleAllDeadUsersMapArray" type="checkbox" class="form-checkbox h-5 w-5 rounded border-gray-400 shadow-md text-sch-main-purple focus:ring-sch-main-purple" />
                </div>
              </div>
                <div class="users-list-wrapper-heading-email">
                    <p class="email">Email</p>
                </div>
                <div class="users-list-wrapper-heading-name">
                    <p class="name">Full name</p>
                </div>
                <div class="users-list-wrapper-heading-role">
                    <p class="role">Role</p>
                </div>
                <div class="users-list-wrapper-heading-date">
                    <p class="date">Created</p>
                </div>
                <div class="users-list-wrapper-heading-date">
                    <p class="date">Activated</p>
                </div>
                <div class="users-list-wrapper-heading-date">
                    <p class="date">Last login</p>
                </div>
                <div class="users-list-wrapper-heading-date">
                    <p class="date">Last doc</p>
                </div>
              <div v-if="userInfo.role === 'admin'" class="users-list-wrapper-body-item-button-action" style="text-align: end">&nbsp;
              </div>
            </div>
            <div class="users-list-wrapper-body">
                <div
                    class="users-list-item-wrapper"
                    v-for="(item, index) in list"
                    :key="item._id"
                    style="margin-bottom: 6px"
                >
                  <div class="users-list-wrapper-body-item-control">
                    <input
                        type="checkbox"
                        class="checkbox-template"
                        v-model="deadUsersMapArray[item._id.toString()]"
                        :value="item.selected"
                        @change="handleDeadSelectOne(item._id)"
                    />
                  </div>
                    <div class="users-list-wrapper-body-item" style="padding-top: 4px; padding-bottom: 4px">
                        <p class="users-list-wrapper-body-item-email">
                            {{ item.email }}
                        </p>
                        <p class="users-list-wrapper-body-item-name">
                            {{ item.name }} {{ item.lastname }}
                        </p>
                        <p class="users-list-wrapper-body-item-role">
                            {{ item.role.toUpperCase() }}
                        </p>
                        <p class="users-list-wrapper-body-item-date">
                            {{ staticDTFormat(item.created_at) }}
                        </p>
                        <p class="users-list-wrapper-body-item-date">
                            {{ staticDTFormat(item.activated_at) }}
                        </p>
                        <p class="users-list-wrapper-body-item-date">
                            {{ staticDTFormat(item.last_login) }}
                        </p>
                        <p class="users-list-wrapper-body-item-date">
                            {{ staticDTFormat(item.latest_doc) }}
                        </p>
                        <div class="users-list-wrapper-body-item-button-action">
                            <div
                                v-if="userInfo.role === 'admin'">
                                <span title="Delete from list"
                                    @click="handleRemove(item._id)"
                                >
                                    <i class="fas fa-trash-alt"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-pagination">
                <div id="pagination">
                    <paginate
                        ref="pagin"
                        :page-count="totalPages"
                        :click-handler="nextPage"
                        :prev-text="'←'"
                        :next-text="'→'"
                        :container-class="'page-numbers'"
                    >
                    </paginate>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import ControlBar from '@/components/ui/ControlBar.vue'
import AdminPanelHeaderBar from '@/components/ui/AdminPanelHeaderBar.vue'
import Multiselect from 'vue-multiselect'
import {DTFormat} from "../../util/helpersItem";
import { captureReportableError } from '@/util/errorTracker';

const PAGE_SIZE = import.meta.env.VITE_DEAD_ACCOUNTS_ON_PAGE || 25;
export default {
    data() {
        return {
            isAdminPanelView: true,
            isUserList: true,
            selectImportType: 'user-import',
            ifExist: 'skip',
            pricingPlan: '',
            parentAgent: '',
            bulkDomain: '',
            bulkCancelNow: true,
            sending: false,
            showSummary: -1,
            showResellerReport: -1,
            viewItem: null,
            viewReport: null,
            totalPages: 0,
            filters: {
              search: '',
              page: 1,
              page_size: PAGE_SIZE,
              reason: -1,
              selectedOnly: 'any'
            },
            list: [],
            progress: 0,
            file: null,
            loading: false,
            selItem: null,
            curSearch: '',
            checkAll: false,
            editLoading: false,
            editId: '',
            selectSubscription: '',
            selectedSubscriptionPrev: '',
            parentsList: { agent: [], reseller: [] },
            mapParents: { user: 'agent', agent: 'reseller' },
            selectExpiry: 0,
            viewOrderCol: null /* order column name or null */,
            viewOrderDir: -1 /* -1 => order desc, 1 => order asc */,
            curAddedCnt: 0 /* -1 => order desc, 1 => order asc */,
            totalAddCnt: 0 /* -1 => order desc, 1 => order asc */,
            productList: null,
            pricingList: null,
            valueOptionFormat: 'csv',
            optionFormat: ['csv'],
            exportUserState: false,
            toggleAllDeadUsers: false,
            deadUsersMapArray: {},
            filterQuery: '',
            isSearchViewMode: false,
          deadReason: ['30d No activation', '90d No trial', '12m canceled'],
          deadCreatedAt:'',
          deadEmailedAt:''
        }
    },
    methods: {
      ...mapActions(['handleModalWindow']),
      ...mapMutations(['storeSetGlobalDocumentBusy']),
      staticDTFormat(date) {
        return DTFormat(date);
      },
      async calculateDeadAccounts() {
        try{
          this.storeSetGlobalDocumentBusy(true);
          const response = await this.$api.get('/user/dead-users');
          if(response.data.status === 'ok') {
            this.$notify({
              group: 'auth',
              type: 'success',
              title: 'Success',
              text: `Dead accounts calculated. Found ${response.data.cnt} accounts.`,
            })
            this.filters.page = 1;
            this.filters.search = '';
            await this.getList();
          }
          else {
            this.$notify({
              group: "auth",
              type: "error",
              title: "Error",
              text: (response.data.message)?response.data.message:'Unexpected error!'
            });
          }
        }
        catch (e) {
          captureReportableError(e);

          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: e.response?.data?.message || e.response?.message || e.message
          });
        }
        this.storeSetGlobalDocumentBusy(false);
      },
      async getList(page) {
          if (page) {
              this.filters.page = page
              this.$refs.pagin.selected = page
              await this.$router.push({ query: this.filters })
          }
          const params = {
            search: this.filters.search,
            reason: this.filters.reason,
            skip: (this.filters.page - 1) * this.filters.page_size,
            page_size: this.filters.page_size,
            selected: this.filters.selectedOnly
          }
        this.storeSetGlobalDocumentBusy(true);

        this.$api
              .get('/user/dead-list', { params: params })
              .then((result) => {
                this.curSearch = this.filters.search
                this.totalPages = Math.ceil(
                    result.data.count / this.filters.page_size
                )
                this.list = result.data.list;
                this.deadUsersMapArray={};
                if(Array.isArray(this.list)) {
                  this.list.map(item => this.deadUsersMapArray[item._id.toString()]=item.selected);
                  this.toggleAllDeadUsers = Object.values(this.deadUsersMapArray).indexOf(true) > -1;
                }
                this.deadCreatedAt = (result.data.created_at)?DTFormat(new Date(result.data.created_at)):'';
                this.deadEmailedAt = (result.data.emailed_at)?DTFormat(new Date(result.data.emailed_at)):'';
              })
              .catch((err) => {
                captureReportableError(err);

                this.$notify({
                  group: "auth",
                  type: "error",
                  title: "Error",
                  text: err.response?.data?.message || err.response?.message || err.message
                });
              })
              .finally(() => {
                this.storeSetGlobalDocumentBusy(false);
              })
      },
      datetimeFormat(date) {
          return date === null || date === ''
              ? ''
              : DTFormat(date,'DD/MM/YYYY, HH:mm:ss')
      },
      async nextPage(page) {
          this.filters.page = page
          await this.$router.push({ query: this.filters })
          await this.getList(page)
      },
      handleExportUsers() {
        this.storeSetGlobalDocumentBusy(true);
          this.$api({
              url: '/user/dead-list/xlsx',
              method: 'get',
              responseType: 'arraybuffer'
              })
              .then((response) => {
                  const blobData = new Blob([response.data])
                  const url = window.URL.createObjectURL(blobData)
                  const link = document.createElement('a')
                  link.href = url
                  link.setAttribute('download', "export-dead-users.xlsx")
                  document.body.appendChild(link)
                  link.click()
              })
              .then(() => {
              })
              .catch((e) => {
                captureReportableError(e);

                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error in export',
                    text: e.message,
                })
              })
        .finally(() => {
          this.storeSetGlobalDocumentBusy(false);
        })
      },
      handleFilterQuery(value) {
          this.filterQuery = value
          this.filters.search = value
          this.isSearchViewMode = this.filterQuery.length > 0
          this.getList(1)
      },
      async handleRemove(id) {
        this.storeSetGlobalDocumentBusy(true);
        try {
          const response = await this.$api.delete(`/user/dead-list/${id}`);
          this.storeSetGlobalDocumentBusy(false);
          if(response.data.success) {
            this.$notify({
              group: 'auth',
              type: 'success',
              title: 'Success',
              text: 'The account has been removed from Dead list',
            });
            await this.getList();
          }
          else {
            this.$notify({
              group: "auth",
              type: "error",
              title: "Error",
              text: (response.data.message)?response.data.message:'Unexpected error!'
            });
          }
        }
        catch (err) {
          captureReportableError(err);

          this.storeSetGlobalDocumentBusy(false);
          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: err.response?.data?.message || err.response?.message || err.message
          });
        }
      },
      async handleBulkEmail() {
        this.storeSetGlobalDocumentBusy(true);
        try {
          const response = await this.$api.get(`/user/dead-list/emails`);
          this.deadEmailedAt = (response.data.emailed_at)?DTFormat(new Date(response.data.emailed_at)):'';
          this.$notify({
            group: 'auth',
            type: 'success',
            title: 'Success',
            text: `${response.data.cnt} emails found. Message sending started. Upon completion, Scholarcy will send an email.`
          });
        }
        catch (e) {
          captureReportableError(e);

          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: e.response?.data?.message || e.response?.message || e.message
          });
        }
        this.storeSetGlobalDocumentBusy(false);
      },
      async handleBulkDelete() {
        this.storeSetGlobalDocumentBusy(true);
        try {
          const response = await this.$api.delete(`/user/dead-list/bulk`);
          await this.getList();
          this.$notify({
            group: 'auth',
            type: 'success',
            title: 'Success',
            text: `${response.data.cnt} accounts deleted. Please check email for report.`
          });
        }
        catch (e) {
          captureReportableError(e);

          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: e.response?.data?.message || e.response?.message || e.message
          });
        }
        this.storeSetGlobalDocumentBusy(false);
      },
      async handlePickedDeadReason(val) {
        this.filters.reason = val.value;
        this.filters.page = 1;
        await this.getList(1);
      },
      async handlePickedSelectedSearch(val) {
        this.filters.selectedOnly = val.value;
        this.filters.page = 1;
        await this.getList(1);
      },
      async toggleAllDeadUsersMapArray() {
        const ids = [];
        for(const [key, value] of Object.entries(this.deadUsersMapArray)) {
          this.deadUsersMapArray[key]=this.toggleAllDeadUsers;
          ids.push(Number.parseInt(key));
        }
        const target = (this.toggleAllDeadUsers)?'select':'unselect';
        try {
          const response = await this.$api.patch(`/user/dead-list/mark`, {target, ids});

          if(response.data.success) {
            this.$notify({
              group: 'auth',
              type: 'success',
              title: 'Success',
              text: `${response.data.cnt} accounts updated.`
            });
          }
          else {
            this.$notify({
              group: "auth",
              type: "error",
              title: "Error",
              text: (response.data.message)?response.data.message:(response.message)?response.message:'Unexpected error!'
            });
          }
        }
        catch (e) {
          captureReportableError(e);

          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: e.response?.data?.message || e.response?.message || e.message
          });
        }
      },
      async handleDeadSelectOne(_id) {
        const target = (this.deadUsersMapArray[_id.toString()])?'select':'unselect';
        try {
          const response = await this.$api.patch(`/user/dead-list/mark`, {target, ids: [_id]});
          if(!response.data.success) {
            this.$notify({
              group: "auth",
              type: "error",
              title: "Error",
              text: (response.data.message)?response.data.message:(response.message)?response.message:'Unexpected error!'
            });
          }
        }
        catch (e) {
          captureReportableError(e);

          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: e.response?.data?.message || e.response?.message || e.message
          });
        }
      }
    },
    computed: {
      ...mapGetters(['storeUserInfo']),
        userInfo() {
            return this.storeUserInfo
        },
    },
    mounted() {
        if (this.$route.query.page) {
            this.filters = Object.assign({}, this.$route.query)
            setTimeout(() => {
                this.$refs.pagin.selected = this.$route.query.page - 1
            }, 100)
        }
        this.getList()
    },
    watch: {
        '$route.path'() {
            this.getList()
        }
    },
    components: {
        ControlBar,
        AdminPanelHeaderBar,
        Multiselect,
    },
}
</script>
