import Vue from 'vue'
import axios from 'axios';
import store from './store';

const apiScheme = import.meta.env.VITE_API_SCHEME;
const apiHost = import.meta.env.VITE_API_HOST;
const apiPath = (import.meta.env.VITE_API_PATH === 'empty')?'':import.meta.env.VITE_API_PATH;

export const apiBaseURL = apiScheme + '://' + apiHost + apiPath;

const api = axios.create({
  baseURL: apiBaseURL,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  withCredentials: true
});

api.interceptors.response.use(function(response) {
  return response;
}, async function(err) {
  const originalRequest = err.config;
  if(err.response) {
    if(err.response.data instanceof ArrayBuffer) {
      const json = new TextDecoder('utf-8').decode(err.response.data);
      err.response.data = JSON.parse(json);
    }
  }
  else {
    return await Promise.reject(err);
  }

  if(err.response?.status === 401) {
    store.commit('LOGOUT2_USER');
    return await Promise.reject(err);
  }
  else if(err.response && err.response.data.message === 'FromMongoRedirect') {
    const targetId = err.response.data.data.target_id;
    const sourceId = err.response.data.data.source_id;
    await store.dispatch('redirectToUrl', '/item/'+targetId);
    originalRequest.url = originalRequest.url.replace(sourceId, targetId);
    return api(originalRequest);
  }
  else {
    return await Promise.reject(err);
  }
});

Vue.prototype.$api = api;

export default api;
