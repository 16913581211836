<template>
  <ul id="right-click-menu" tabindex="-1" ref="rightContextMenu" :style="styleData" @blur="actionFunction({show: false})">
    <li @click="$root.$emit('rightContextMenu:Notes', {clause : 1})">Add Notes</li>
  </ul>
</template>

<script>
import { mapGetters, mapActions} from "vuex";

export default {
  name: "RightClickContextMenu",
  props: {
    showData : { type: Boolean},
    styleData :{ type: Object} ,
    actionFunction : { type: Function }
  },
  data() {
    return {

    }
  },
  methods: {
    ...mapGetters(['getShowContextMenuArticleView', 'getShowContextMenuArticlePosition']),
    ...mapActions(['actionShowContextMenuArticle'])
  },
  computed: {
    ...mapGetters.getShowContextMenuArticleView,
    isVisible() {
      return this.getShowContextMenuArticleView();
    }
  },
  watch: {
  }

}
</script>
