<template>
    <div>
      <div id="id-summary-user-header" v-if="userRole ==='user' && !userActive">
        <b-button class='button button--green' @click="doResendAE">Resend activation email</b-button>
      </div>

        <div class="user-email">
            <i class="fas fa-user"></i>
            <p>{{ userEmail }}</p>
        </div>
        <form>
            <div class="form-group">
                <label>Full name</label>
                <p class="info-user">{{ userName }} {{ userLastName }}</p>
            </div>
            <div class="form-group">
                <label for="">Role and affiliation</label>
                <p class="info-user">{{ userRole }}</p>
            </div>
            <div class="row">
                <div class="form-group">
                    <label for="">Total documents</label>
                    <p class="info-user">{{ totalDocument }}</p>
                </div>

                <div class="form-group">
                    <label for="">Current month documents</label>
                    <p class="info-user">{{ currentMonthDocuments }}</p>
                </div>
            </div>
            <div class="form-group">
                <label for="">Subscription</label>
                <p class="info-user">{{ userSubscriptionPlan }}</p>
            </div>
            <div class="form-group">
                <label for="">Last login</label>
                <p class="info-user">{{ lastLogin }}</p>
            </div>
          <div class="toggle-button-wrapper" v-if="userRole==='agent' || userRole==='admin'">
            <label for="export-highlights" class="text">Monthly user activity report</label>&nbsp;
            <input
                type="checkbox"
                id="export-highlights"
                v-model="isMonthlyReport"
                class="toggle-button"
                @change="onChangeMonthlyReport"
                style="margin: auto"
            />
          </div>
            <accordion v-if="userRole === 'reseller'">
                <accordion-item title="Report">
                    <div class="form-group">
                        <label>Agent</label>
                        <multiselect
                            v-model="pickedReportAgentList"
                            :searchable="false"
                            :show-labels="false"
                            :options="reportAgentList"
                            track-by="_id"
                            label="email"
                        ></multiselect>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <label>Start Report Date</label>
                            <div>
                                <input type="date" v-model="startReportDate" id="datepicker-sm-1" class="rounded-lg border-[1px] border-[#e6e6e6] bg-white p-2" :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                    }">
                            </div>
                        </div>
                        <div class="form-group">
                            <label>End Report Date</label>
                            <div>
                                <input type="date" v-model="endReportDate" id="datepicker-sm-2" class="rounded-lg border-[1px] border-[#e6e6e6] bg-white p-2" :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                    }">
                            </div>
                        </div>
                    </div>
<!--
                    <div class="row">
                        <div class="form-group">
                            <label>Format</label>
                            <multiselect
                                v-model="pickedFormatList"
                                :searchable="false"
                                :show-labels="false"
                                :options="formatList"
                            ></multiselect>
                        </div>
                    </div>
-->
                    <div class="action-button">
                        <button
                            class="button button--purple button--small"
                            @click.prevent="generateReport"
                        >
                            <i class="fas fa-check-circle"></i>
                            Make report
                        </button>
                        <button
                            class="button button--white button--small"
                            @click.prevent="hideModal"
                        >
                            Cancel
                        </button>
                    </div>

                    <div v-if="item">
                        <hr />
                        <div class="form-group">
                            <label>Report dates:</label>
                            <p class="info-user">
                                {{ startDate }} - {{ endDate }}
                            </p>
                        </div>
                        <div class="form-group" v-if="item && item.total">
                            <label>Report currency:</label>
                            <p class="info-user">{{ currency }}</p>
                        </div>
                        <div v-if="!item || !item.total">
                            <div class="row">
                                <div class="font-weight-bold">
                                    No chargeable subscriptions for this period.
                                </div>
                            </div>
                        </div>
                        <div v-if="item && item.total">
                            <div class="row">
                                <h4>Agents:</h4>
                            </div>
                            <div
                                class="report-wrapper"
                                v-for="agent in item.agents"
                            >
                                <div class="form-group">
                                    <p class="info-user">{{ agent.email }}</p>
                                </div>
                                <div class="row">
                                    <div
                                        v-if="agent.month > 0"
                                        class="form-group"
                                    >
                                        <p class="info-user">
                                            Scholarcy monthly subscription x
                                            {{ agent.invoiced_month }}
                                        </p>
                                    </div>
                                    <div
                                        v-if="agent.month > 0"
                                        class="form-group"
                                    >
                                        <p class="info-user">
                                            {{ item.prices.monthly.toFixed(2) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        v-if="agent.year > 0"
                                        class="form-group"
                                    >
                                        <p class="info-user">
                                            Scholarcy annual subscription x
                                            {{ agent.invoiced_year }}
                                        </p>
                                    </div>
                                    <div
                                        v-if="agent.year > 0"
                                        class="form-group"
                                    >
                                        <p class="info-user">
                                            {{ item.prices.annual.toFixed(2) }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="form-group"
                                        style="font-weight: bold"
                                    >
                                        <p class="info-user">Agent subtotal:</p>
                                    </div>
                                    <div class="form-group">
                                        <p class="info-user">
                                            {{
                                                (
                                                    item.prices.annual *
                                                        agent.invoiced_year +
                                                    item.prices.monthly *
                                                        agent.invoiced_month
                                                ).toFixed(2)
                                            }}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div
                                        class="form-group"
                                        style="font-weight: bold"
                                    >
                                        <p class="info-user">Agent discount:</p>
                                    </div>
                                    <div
                                        class="form-group"
                                        style="font-weight: bold"
                                    >
                                        <p class="info-user">
                                            {{ agent.discount }}%
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <p
                                            class="info-user"
                                            style="font-weight: bold"
                                        >
                                            Agent total:
                                        </p>
                                    </div>
                                    <div class="form-group">
                                        <p
                                            class="info-user"
                                            style="font-weight: bold"
                                        >
                                            {{
                                                (
                                                    (agent.subtotal *
                                                        (100 -
                                                            agent.discount)) /
                                                    100
                                                ).toFixed(2)
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="action-button">
                            <b-button
                                v-if="item && item.total"
                                class="button button--purple button--small"
                                variant="outline-success"
                                @click.prevent="downloadReport"
                                >Download</b-button
                            >
                            <b-button
                                v-if="item && item.total"
                                class="button button--purple button--small"
                                variant="outline-success"
                                @click.prevent="createInvoice"
                                >Create invoices</b-button
                            >
                            <b-button
                                class="button button--white button--small"
                                variant="outline-success"
                                @click.prevent="hideModal"
                                >Close</b-button
                            >
                        </div>
                    </div>
                </accordion-item>
            </accordion>
        </form>
        <b-button
            class="button button--purple mt-4"
            @click="hideModal"
        >
            <i class="fa fa-check-circle" aria-hidden="true"></i>
            Close
        </b-button>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Accordion from '@/components/ui/Accordion.vue'
import AccordionItem from '@/components/ui/AccordionItem.vue'
import {DTFormat} from "../../util/helpersItem";
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        hideModal: {
            type: Function,
        },
        msgBoxOk: {
            type: Function,
        },
    },
    data() {
        return {
          pickedRolesList: null,
          pickedUserRolesData: null,
          pickedParentList: null,
          pickedSubscribtionList: null,
          pickedExpiryDateList: null,

          parentRole: '',
          userEmail: '',
          userName: '',
          userLastName: '',
          userRole: '',
          userUserRole: '',
          userParent: '',
          totalDocument: '',
          currentMonthDocuments: '',
          userSubscriptionPlan: '',
          lastLogin: '',
          userActive: true,

          item: null,
          pickedReportAgentList: null,
          reportAgentList: [],
          startDate: new Date(),
          endDate: new Date(),
          startReportDate: null,
          endReportDate: null,
          pickedFormatList: 'CSV',
          formatList: ['CSV', 'JSON'],
          isMonthlyReport: false
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
      ...mapMutations(['storeSetGlobalDocumentBusy']),
      async onChangeMonthlyReport() {
        try {
          await this.$api
            .patch('/user/profile/settings-general', {
              user_id: this.modalWindowState.config.userId,
              settings: {
                report_monthly_user: this.isMonthlyReport
              }
            });
          this.$notify({
            group: 'auth',
            type: 'success',
            title: 'Success',
            text: 'Admin report setting updated.',
          })
        }
        catch (e) {
          captureReportableError(e);

          this.$notify({
            group: 'auth',
            type: 'error',
            title: 'Error',
            text: e.response?.data?.message || e.response?.message || "Unexpected error. Please try again later."
          })
        }
      },
        fetchExtendedUserInfo() {
            this.$api
                .get('/user/extended', {
                    params: { userId: this.modalWindowState.config.userId },
                })
                .then((data) => {
                    this.totalDocument = data.data.data.docs
                    this.currentMonthDocuments = data.data.data.mon
                })
                .catch((err) => {
                  captureReportableError(err);

                  this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: err.response?.data?.message || err.response?.message || "Unexpected error. Please try again later."
                  })
                })
        },
        async prepareReport() {
            this.item = null
            let dt = new Date();
            this.endReportDate = DTFormat(dt,'YYYY-MM-DD');
            dt = dt.setMonth(dt.getMonth()-1);
            this.startReportDate = DTFormat(dt, 'YYYY-MM-DD');

            if(this.userRole !== 'reseller') {
                return;
            }
            try {
                const response = await this.$api.get('/user/simple-list', {
                    params: {
                        role: 'agent',
                        parent: this.modalWindowState.config.userId,
                    },
                })
                this.reportAgentList = response.data.data
            } catch (e) {
              captureReportableError(e);

              this.$notify({
                  group: 'auth',
                  type: 'error',
                  title: 'Error',
                  text: e.response?.data?.message || "Unexpected error. Please try again later.",
              })
            }
        },
        generateReport() {
            if (this.startReportDate === '') {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: 'Please enter a start report date',
                })
            }
            if (this.endReportDate === '') {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: 'Please enter a last report date',
                })
            }
            if (this.item) {
                this.item = null
            }
            this.loading = true
          this.storeSetGlobalDocumentBusy(true);
            this.$api
                .post('/user/generate-report', {
                    userId: this.modalWindowState.config.userId,
                    startDate: this.startReportDate,
                    endDate: this.endReportDate,
                    agent: (this.pickedReportAgentList && this.pickedReportAgentList._id)?this.pickedReportAgentList._id: null,
                    reportType: this.selectReportType,
                }, {timeout: 0})
                .then((result) => {
                  this.loading = false
                  this.storeSetGlobalDocumentBusy(false);
                  this.$notify({
                    group: 'auth',
                    type: 'success',
                    title: 'Success',
                    text: result.data.message,
                  });
                })
                .catch((err) => {
                  captureReportableError(err);

                  this.loading = false
                  this.storeSetGlobalDocumentBusy(false);
                  this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: err.response?.data?.message || err.message,
                  })
                })
        },
        async createInvoice() {
            this.waiting = true
            try {
                const invoices = await this.$api.post(
                    '/user/create-invoice',
                    this.item
                )
                this.waiting = false
                this.msgBoxOk(`Scholarcy created in Stripe a new draft invoice for ${invoices.data.data[0].customer_email}.`,{ centered: true });
            } catch (err) {
                captureReportableError(err);

                this.waiting = false
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: err.response?.data?.message || err.response?.message || "Unexpected error. Please try again later."
                })
            }
        },
      async doResendAE() {
        try {
          await this.$api.post('/admin/send-activation-email', {userId: this.modalWindowState.config.userId, reset_logins:0})
          this.$notify({
            group: 'auth',
            type: 'success',
            title: 'Success',
            text: 'Scholarcy activation email has been resent.',
          })
        } catch (e) {
          captureReportableError(e);

          this.$notify({
            group: 'auth',
            type: 'error',
            title: 'Error',
            text: e.response?.data?.message || "Unexpected error. Please try again later.",
          })
        }
      }
    },
    computed: {
        ...mapGetters([
            'modalWindowState',
            'userRolesList'
        ]),
        userRolesData() {
            return this.userRolesList
        }
    },
    mounted() {
      this.fetchExtendedUserInfo()
      this.userEmail = this.modalWindowState.config.userEmail
      this.userName = this.modalWindowState.config.userName
      this.userLastName = this.modalWindowState.config.userLastName
      this.userRole = this.modalWindowState.config.userRole
      this.userUserRole = this.modalWindowState.config.userUserRole
      this.userParent = this.modalWindowState.config.userParent
      this.userActive = this.modalWindowState.config.userActive;
      this.userSubscriptionPlan = this.modalWindowState.config.userSubscriptionPlan
      this.lastLogin = this.modalWindowState.config.lastLog
      this.isMonthlyReport = this.modalWindowState.config.isMonthlyReport || false

      this.prepareReport()
    },
    components: {
        Multiselect,
        Accordion,
        AccordionItem
    },
}
</script>
