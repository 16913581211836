<!-- @format -->
<template>
  <div>
    <form>
      <div class="form-group" v-if="modalWindowState.type === 'AddUser'">
        <label for="">Email</label>
        <input type="email" required placeholder="Email" v-model="userEmail" ref="input-edit-email" />
      </div>
      <div class="form-group" v-else-if="!isEditingEmail" style="display: flex">
        <i class="fas fa-user" style="margin-right: 0.5em"></i>
        <p style="width: 100%; text-align: left">{{ this.userEmail }}</p>
        <i
          class="fas fa-edit"
          style="cursor: pointer"
          @click="
            editEmail = userEmail;
            isEditingEmail = true;
          "
        ></i>
      </div>
      <div class="form-group" v-else>
        <div class="input-group" style="align-items: center">
          <input type="email" required placeholder="Email" v-model="editEmail" ref="input-edit-email" />
          <div
            class="bg-transparent"
            style="position: absolute; right: 32px; cursor: pointer; color: darkgreen"
            @click="onChangeEmail"
          >
            <i class="fa fa-check-circle"></i>
          </div>
          <div
            class="bg-transparent"
            style="position: absolute; right: 8px; cursor: pointer; color: darkred"
            @click="isEditingEmail = false"
          >
            <i class="fa fa-times-circle"></i>
          </div>
        </div>
      </div>

      <div v-if="isAdmin" class="form-group">
        <label for="">Role</label>
        <multiselect v-model="pickedRolesList" :options="rolesList" :searchable="false" :show-labels="false">
          <span slot="caret" slot-scope="{ toggle }" class="arrow" @mousedown.prevent.stop="toggle">
            <i class="fa fa-chevron-down"></i>
          </span>
        </multiselect>
      </div>
      <div class="form-group" v-if="isAdmin && (pickedRolesList === 'User' || pickedRolesList === 'Agent')">
        <label for="">Parent</label>
        <multiselect
          v-model="pickedParentList"
          :options="parsedParentsList"
          label="email"
          :searchable="false"
          :show-labels="false"
        >
          <span slot="caret" slot-scope="{ toggle }" class="arrow" @mousedown.prevent.stop="toggle">
            <i class="fa fa-chevron-down"></i>
          </span>
        </multiselect>
      </div>
      <div class="form-group" v-if="isAdmin && pickedRolesList === 'Agent'">
        <label for="">Default for domain?</label>
        <multiselect v-model="pickedIsDefaultList" :options="isDefaultList" :searchable="false" :show-labels="false">
          <span slot="caret" slot-scope="{ toggle }" class="arrow" @mousedown.prevent.stop="toggle">
            <i class="fa fa-chevron-down"></i>
          </span>
        </multiselect>
      </div>
      <div class="form-group">
        <label for="">First name</label>
        <input type="text" v-model="editName" placeholder="Enter full name here" />
      </div>
      <div class="form-group">
        <label for="">Last name</label>
        <input type="text" v-model="editLastName" placeholder="Enter full name here" />
      </div>

      <div class="form-group">
        <label for="">Subscription</label>
        <multiselect
          v-if="filteredSubscribtionPlanList"
          v-model="pickedSubscribtionList"
          :options="filteredSubscribtionPlanList"
          label="name"
          :searchable="false"
          :show-labels="false"
        >
          <span slot="caret" slot-scope="{ toggle }" class="arrow" @mousedown.prevent.stop="toggle">
            <i class="fa fa-chevron-down"></i>
          </span>
        </multiselect>
      </div>

      <div class="form-group">
        <label for="">Expiry</label>
        <multiselect
          :disabled="
            modalWindowState.type !== 'AddUser' &&
            modalWindowState.config.editId !== '' &&
            (!pickedSubscribtionList || pickedSubscribtionList.value === 'none')
          "
          v-model="pickedExpiryDateList"
          label="name"
          :options="expiryDateList"
          :searchable="false"
          :show-labels="false"
        >
          <span slot="caret" slot-scope="{ toggle }" class="arrow" @mousedown.prevent.stop="toggle">
            <i class="fa fa-chevron-down"></i>
          </span>
        </multiselect>
      </div>

      <div class="row">
        <div class="form-group">
          <label for="">Affiliation</label>
          <input type="text" v-model="editAffiliation" placeholder="Enter affiliation here" />
        </div>

        <div class="form-group">
          <label for="">User role</label>
          <multiselect
            v-model="pickedUserRolesData"
            label="name"
            :options="userRolesData"
            :searchable="false"
            :show-labels="false"
          >
            <span slot="caret" slot-scope="{ toggle }" class="arrow" @mousedown.prevent.stop="toggle">
              <i class="fa fa-chevron-down"></i>
            </span>
          </multiselect>
        </div>
      </div>
      <div class="form-group">
        <label for="">Password</label>
        <input type="text" v-model="editPassword" placeholder="Enter password here" />
      </div>
      <div class="form-group">
        <label for="">Confirm Password</label>
        <input type="text" v-model="confirmPassword" placeholder="Confirm password here" />
      </div>
    </form>
    <div v-if="isAdmin" class="form-group">
      <div class="relative flex items-start">
        <div class="flex h-6 items-center">
          <input
            v-model="featurePreviewAllowed"
            id="allow-feature-preview-access"
            aria-describedby="allow-feature-preview-access-description"
            name="allow-feature-preview-access"
            type="checkbox"
            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
          />
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="allow-feature-preview-access" class="font-medium text-gray-900">Feature Preview Access</label>
          <span id="allow-feature-preview-access-description" class="block text-xs text-gray-500">
            Allow this user to access the Feature Preview panel in Profile.
          </span>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button class="button button--purple button--small" @click="handleSaveUser" :disabled="!isSaveEnabled">
        <i class="fas fa-check-circle"></i>
        Save
      </button>
      <button class="button button--white button--small" @click.prevent="hideModal" :disabled="isSubmitting">
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { captureReportableError } from "@/util/errorTracker";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { mapActions, mapGetters } from "vuex";
import { validateEmail } from "../../util/helpersItem";

export default {
  props: {
    hideModal: {
      type: Function,
    },
  },
  data() {
    return {
      isEditingEmail: false,
      pickedRolesList: null,
      pickedUserRolesData: { value: "", name: "" },
      pickedParentList: null,
      pickedSubscribtionList: null,
      filteredSubscribtionPlanList: null,
      rolesList: ["User", "Agent", "Reseller", "Admin"],
      parentsList: {
        agent: [],
        reseller: [],
      },
      mapParents: {
        user: "agent",
        agent: "reseller",
        reseller: "",
      },
      pickedExpiryDateList: { name: "Never", value: 0 },
      expiryDateList: [
        { name: "Never", value: 0 },
        { name: "2 weeks", value: 0.5 },
        { name: "1 month", value: 1 },
        { name: "3 months", value: 3 },
        { name: "6 months", value: 6 },
        { name: "12 months", value: 12 },
        { name: "24 months", value: 24 },
        { name: "36 months", value: 36 },
        { name: "48 months", value: 48 },
        { name: "60 months", value: 60 },
      ],
      parsedParentsList: [],
      emptyParent: { _id: "", email: "None" },
      pickedIsDefaultList: "No",
      isDefaultList: ["No", "Yes"],
      editEmail: "",
      userEmail: "",
      editLastName: "",
      editName: "",
      editPassword: "",
      editAffiliation: "",
      editRole: "",
      editUserRole: "",
      editActivation: false,
      editItem: null,
      editParent: "",
      editParents: [],
      confirmPassword: "",
      editId: "",
      featurePreviewAllowed: undefined,
    };
  },
  methods: {
    ...mapActions(["handleModalWindow", "startModalNetworkRequest", "endModalNetworkRequest"]),
    checkCorrectEmail(el) {
      if (el?.validity) {
        if (
          (!el.validity.valid && (el.validity.valueMissing || el.validity.typeMismatch)) ||
          !validateEmail(this.editEmail)
        ) {
          this.$notify({
            group: "auth",
            type: "error",
            title: "Incorrect email format",
            text: el.validationMessage || "Incorrect email format",
          });
          el.focus();
          return false;
        }
      }
      return true;
    },
    async onChangeEmail(e) {
      const isCorrect = this.checkCorrectEmail(this.$refs["input-edit-email"]);
      if (isCorrect) {
        const answer = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you wish to change an user email to ${this.editEmail}?`,
          {
            title: "Change confirmation",
            okVariant: "success",
            okTitle: "Confirm",
            cancelTitle: "Reject",
            cancelVariant: "danger",
            centered: true,
          },
        );
        if (answer === true) {
          try {
            await this.$api.patch("/user/profile/change-email", { userId: this.editId, email: this.editEmail });
            this.userEmail = this.editEmail;
            this.$notify({
              group: "auth",
              type: "success",
              title: "Success",
              text: "Email has been changed successfully.",
            });
            await this.modalWindowState.config.fetchUserCallback();
          } catch (e) {
            captureReportableError(e);

            this.$notify({
              group: "auth",
              type: "error",
              title: "Error",
              text: e.response?.data?.message || e.response?.message || "Unexpected error. Please try again later.",
            });
          }
        }
        this.isEditingEmail = false;
      }
    },
    handleSaveUser() {
      this.startModalNetworkRequest();
      const isCorrect = this.checkCorrectEmail(this.$refs["input-edit-email"]);
      if (!isCorrect) {
        this.endModalNetworkRequest();
        return;
      }
      const url = this.modalWindowState.type === "AddUser" ? "/user/create" : "/user/profile";
      const oUpdate = {
        userId: this.editId,
        name: this.editName,
        lastname: this.editLastName,
        affiliation: this.editAffiliation,
        user_role: this.pickedUserRolesData ? this.pickedUserRolesData.value : "",
        active: this.modalWindowState.config.active,
        role: this.pickedRolesList?.toLowerCase() || "user",
        parent: this.pickedParentList ? this.pickedParentList._id : "",
        is_domain_default: this.pickedIsDefaultList === "Yes",
        featurePreviewAllowed: this.featurePreviewAllowed,
      };

      try {
        if (this.editId === "") {
          oUpdate.subscription = this.pickedSubscribtionList ? this.pickedSubscribtionList.value : "none";
        } else {
          if (!this.selectedSubscriptionPrev || this.selectedSubscriptionPrev.value === "none") {
            if (this.pickedSubscribtionList && this.pickedSubscribtionList.value !== "none") {
              if (this.pickedSubscribtionList.value === "premium") {
                this.$notify({
                  group: "auth",
                  type: "error",
                  title: "Error",
                  text: "Scholarcy Premium subscription has to be bought or changed only directly!",
                });

                this.endModalNetworkRequest();
                return false;
              } else {
                oUpdate.subscription = this.pickedSubscribtionList.value;
              }
            }
          } else if (!this.pickedSubscribtionList || this.pickedSubscribtionList.value === "none") {
            if (this.selectedSubscriptionPrev.value === "premium") {
              this.$notify({
                group: "auth",
                type: "error",
                title: "Error",
                text: "Scholarcy Premium subscription has to be bought or changed only directly!",
              });

              this.endModalNetworkRequest();
              return false;
            } else {
              oUpdate.subscription = this.pickedSubscribtionList.value;
            }
          } else if (this.selectedSubscriptionPrev.value !== this.pickedSubscribtionList.value) {
            if (this.pickedSubscribtionList.value === "premium" || this.selectedSubscriptionPrev.value === "premium") {
              this.$notify({
                group: "auth",
                type: "error",
                title: "Error",
                text: "Scholarcy Premium subscription has to be bought or changed only directly!",
              });

              this.endModalNetworkRequest();
              return false;
            } else {
              oUpdate.subscription = this.pickedSubscribtionList.value;
            }
          }
        }
      } catch (e) {
        captureReportableError(e, { extra: { type: this.modalWindowState.type, updateData: oUpdate } });

        this.$notify({
          group: "auth",
          type: "error",
          title: "Error",
          text: e.message,
        });

        this.endModalNetworkRequest();
        return false;
      }

      if (this.editPassword !== "") {
        if (this.editPassword === this.confirmPassword) {
          oUpdate.password = this.editPassword;
        } else {
          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: "Password and password confirmation fields are different!",
          });

          this.endModalNetworkRequest();
          return false;
        }
      } else if (this.editId === "") {
        this.$notify({
          group: "auth",
          type: "error",
          title: "Error",
          text: "Password can't be empty!",
        });

        this.endModalNetworkRequest();
        return false;
      }
      if (this.editId === "") {
        oUpdate.email = this.userEmail.replace(/\\s/g, "");
      }
      oUpdate.subscription_expiry = this.pickedExpiryDateList.value;

      this.$api
        .post(url, oUpdate)
        .then(() => {
          this.$notify({
            group: "auth",
            type: "success",
            title: "Success",
            text: "Changes saved successfully",
          });
          this.modalWindowState.config.fetchUserCallback();

          this.endModalNetworkRequest();
          this.hideModal();
        })
        .catch((err) => {
          captureReportableError(err);

          this.$notify({
            group: "auth",
            type: "error",
            title: "Error",
            text: err.response?.data?.message || err.response?.message || "Unexpected error. Please try again later.",
          });

          this.modalWindowState.config.fetchUserCallback();
          this.endModalNetworkRequest();
          this.hideModal();
        });
    },
  },
  computed: {
    ...mapGetters(["modalWindowState", "userRolesList", "subscribtionPlanList", "storeUserInfo"]),
    userInfo() {
      return this.storeUserInfo;
    },
    isAdmin() {
      return this.userInfo.role === "admin";
    },
    userRolesData() {
      return this.userRolesList;
    },
    computedSubscribtionPlanList() {
      return this.subscribtionPlanList;
    },
    isSaveEnabled() {
      if (this.isSubmitting) {
        return false;
      }

      if (this.modalWindowState.type === "AddUser") {
        return this.userEmail !== "" && this.confirmPassword === this.editPassword && this.editPassword !== "";
      } else {
        return this.confirmPassword === this.editPassword;
      }
    },
    isSubmitting() {
      return this.modalWindowState.isNetworkRequestLoading;
    },
  },
  mounted() {
    if (this.modalWindowState.type === "AddUser") {
      this.pickedRolesList = "User";
      this.editPassword = Math.random().toString(36).substr(2, 8).toUpperCase();
      this.confirmPassword = this.editPassword;
    }

    const subscription = this.modalWindowState.config.subscription;
    this.editId = this.modalWindowState.config.editId ? this.modalWindowState.config.editId : this.editId;
    this.userEmail = this.modalWindowState.config.userEmail ? this.modalWindowState.config.userEmail : this.userEmail;
    this.pickedRolesList = this.modalWindowState.config.role ? this.modalWindowState.config.role : this.pickedRolesList;
    this.editName = this.modalWindowState.config.fullName ? this.modalWindowState.config.fullName.name : this.editName;
    this.editLastName = this.modalWindowState.config.fullName
      ? this.modalWindowState.config.fullName.lastName
      : this.editLastName;

    this.pickedSubscribtionList = subscription
      ? this.computedSubscribtionPlanList.find((e) => e.value === subscription.value)
      : "";
    this.selectedSubscriptionPrev = this.pickedSubscribtionList;
    this.filteredSubscribtionPlanList = [];
    for (const item of this.computedSubscribtionPlanList) {
      if ((item.value === "premium-free" && this.isAdmin) || item.value !== "premium-free") {
        this.filteredSubscribtionPlanList.push(item);
      } else if (item.value === "premium-free" && !this.isAdmin) {
        this.filteredSubscribtionPlanList.push({});
      }
    }

    if (this.modalWindowState.type === "EditUser") {
      this.pickedExpiryDateList = this.expiryDateList.find((e) => e.value === this.modalWindowState.config.expiry);
      this.pickedIsDefaultList = this.modalWindowState.config.is_domain_default ? "Yes" : "No";
    }

    this.editAffiliation = this.modalWindowState.config.affiliation;
    this.pickedUserRolesData = this.userRolesData.find((e) => this.modalWindowState.config.userRole === e.value);

    this.pickedUserRolesData =
      this.pickedUserRolesData === undefined ? { value: "", name: "" } : this.pickedUserRolesData;

    this.parentsList = this.modalWindowState.config.parentsList
      ? this.modalWindowState.config.parentsList
      : this.parentsList;

    let _parentsList = [];
    if (
      this.mapParents[this.pickedRolesList.toLowerCase()] &&
      Array.isArray(this.parentsList[this.mapParents[this.pickedRolesList.toLowerCase()]])
    ) {
      _parentsList = this.parentsList[this.mapParents[this.pickedRolesList.toLowerCase()]];
    }
    this.parsedParentsList = this.pickedRolesList ? [this.emptyParent, ..._parentsList] : [];

    if (this.modalWindowState.type === "EditUser" && this.modalWindowState.config.parent) {
      this.pickedParentList = this.modalWindowState.config.parent;
    }

    if (this.modalWindowState.type === "AddUser") {
      this.pickedParentList = this.parsedParentsList[0];
      this.pickedSubscribtionList = this.filteredSubscribtionPlanList.find((e) => e.value === "institution");
    }

    this.featurePreviewAllowed = this.modalWindowState.config.featurePreviewAllowed;
  },
  watch: {
    pickedRolesList() {
      if (
        this.mapParents[this.pickedRolesList.toLowerCase()] &&
        Array.isArray(this.parentsList[this.mapParents[this.pickedRolesList.toLowerCase()]])
      ) {
        this.parsedParentsList = [
          this.emptyParent,
          ...this.parentsList[this.mapParents[this.pickedRolesList.toLowerCase()]],
        ];
      } else {
        this.parsedParentsList = [];
      }
    },
  },
  components: {
    Multiselect,
  },
};
</script>
