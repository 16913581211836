<template>
    <div class="user-folder-list">
        <div class="subtitle-action-block">
            <h2 class="subtitle">
                Groups
                <span>({{ groups.length}})</span>
                &nbsp;-&nbsp;{{selectedGroup?.name}}
            </h2>
            <div class="p-1 cursor-pointer" @click="onToggleGroups">
              <i class="fas fa-chevron-down transition duration-300 ease-in-out" :class="{'rotate-180' : !bShowGroups}"></i>
            </div>
        </div>
        <slot></slot>
        <div :class="{'hidden':!bShowGroups}">
        <template v-for="(group) in groups">
            <ZoteroGroupItem
                :key="group._id"
                :groupItem="group"
                @change-group="onChangeGroup"
            />
        </template>
        </div>
    </div>
</template>

<script>

import ZoteroGroupItem from "./ZoteroGroupItem.vue"

export default {
    props: {
      groups: {
          type: Array,
          required: true
      },
      selectedGroup: {
        type: Object
      }
    },
    data() {
      return {
        bShowGroups: true,
      }
    },
    methods: {
      onToggleGroups() {
        this.bShowGroups = !this.bShowGroups;
      },
      onChangeGroup(payload) {
        this.$emit("change-group", payload)
      }
    },
    components: {
      ZoteroGroupItem
    },
}
</script>
