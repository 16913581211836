<template>
<div id="container" class="page login-page">
  <div class="block">
    <div class="block-item block-item-form">
      <div class="form">
        <div class="heading">&nbsp;</div>
        <div class="content">
          <h2 class="text-center">Invoice is creating. Please wait...</h2>
          <Spinner v-if="loading"></Spinner>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { captureReportableError } from '@/util/errorTracker';

export default {
  components: {
    Spinner
  },
 data () {
    return {
      loading: true
    }
  },
  async mounted() {
    try {
      const invoices = await this.$api.get('/admin/create-invoice/' + this.$route.params.token);
      this.$notify({
        group: 'auth',
        duration: -1,
        type: 'success',
        title: 'Success',
        text: `${invoices.data.data.length} draft invoices has created in Stripe.`
      })
      this.loading = false;
    }
    catch (err) {
      captureReportableError(err);

      this.loading = false;
      this.$notify({
        group: 'auth',
        type: 'error',
        title: 'Error',
        text: err.response?.data?.message ||err.response?.message || "Unexpected error. Please try again later."
      });
    }
    await this.$router.push('/');
  }
}
</script>
