import * as Sentry from "@sentry/vue";

/**
 * Captures an error to Sentry if it is a reportable error.
 * Only captures the error if it is an HTTP response error with a status code outside the 400-499 range.
 * @param {object} error - the error object
 * @param {object} extra - extra data to send to Sentry
 * @returns {void}
 */
export const captureReportableError = (error, extra = {}) => {
  if (isReportableError(error)) {
    Sentry.captureException(error, extra);
  }
};

/**
 * Checks if the error is an error that should be reported to Sentry.
 * Only returns true if the error is an HTTP response error with a status code outside the 400-499 range.
 *
 * @param {object} error - the error object
 * @returns {boolean} true if the error should be reported to Sentry
 */

const isReportableError = error => !(error.response && error.response.status >= 400 && error.response.status < 500);
