<template>
    <div>
        <p>Are you sure you want to delete this account?</p>

        <div class="modal-footer">
            <button class="button button--purple" @click="handleConfirmDelete" :disabled="isSubmitting">
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Confirm
            </button>

            <button class="button button--white" @click.prevent="hideModal" :disabled="isSubmitting">
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    methods: {
        ...mapActions(['startModalNetworkRequest', 'endModalNetworkRequest']),
        async handleConfirmDelete() {
            this.startModalNetworkRequest();
            await this.removeItem()
            await this.modalWindowState.config.deleteCallback()

            this.endModalNetworkRequest();
            this.hideModal()
        },
        async removeItem() {
          try {
            await this.$api.post('/user/delete', { userIds: [this.modalWindowState.config.userId] });
            this.$notify({
              group: 'auth',
              type: 'success',
              title: 'Success',
              text: `User(s) deleted successfully`
            });
          }
          catch (err) {
            captureReportableError(err);

            this.$notify({
                group: 'auth',
                type: 'error',
                title: 'Error',
                text: err.response?.data?.message || err.response?.message || "Unexpected error. Please try again later.",
            })
            console.log(err);
          }
        }
    },
    computed: {
        ...mapGetters(['modalWindowState']),
        isSubmitting() {
          return this.modalWindowState.isNetworkRequestLoading;
        }
    },
}
</script>
