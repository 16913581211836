<template>
<div class="w-full border-b-[1px] border-gray-300 py-2 px-4">
    <div class="flex flex-row md:flex-col w-full md:ml-0">
        <div class="w-full grid md:grid-cols-12 ">
            <div class="col-span-7 flex items-center justify-start">
                <p class="file-name text-ellipsis overflow-hidden">
                    {{ tempAttachment.title }}
                </p>
            </div>
            <div class="col-span-4 flex flex-col">
                <div v-if="tempAttachment.title.endsWith('.pdf')" class="flex flex-row md:justify-center items-center pt-2 md:pt-0" :class="{'hidden' : !tempAttachment.title.endsWith('.pdf')}">
                    <select @change="handleShowDoubleRangeSlider" class="form-select text-center max-w-[150px] mt-2 block w-full rounded-md border-0 py-1 text-sm pl-1 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sch-main-purple sm:text-sm sm:leading-6">
                        <option value="all" selected>All pages</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>
                <div v-if="showDoubleRangeSlider" class="w-full flex md:justify-center pt-2">
                        <div class="range">
                            <div class="flex flex-row items-center justify-end">
                                <div class="flex pr-1">
                                    <input
                                        type="number"
                                        placeholder="From"
                                        :min="startAt"
                                        :max="endAt"
                                        class="input-number text-xs text-center"
                                        @change="handleStartPage"
                                    />
                                </div>
                                <p class="text-lg px-1 font-medium">-</p>
                                <div class="flex pl-1">
                                    <input
                                        type="number"
                                        placeholder="To"
                                        :min="startAt"
                                        :max="endAt"
                                        class="input-number text-xs text-center"
                                        @change="handleEndPage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <div class="col-span-1 items-center justify-end hidden md:flex" @click="removeFile(index)">
                <i aria-hidden="true" class="fa fa-times"></i>
            </div>
        </div>
        <div class="items-center flex md:hidden" @click="removeFile(index)">
            <i aria-hidden="true" class="fa fa-times"></i>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        tempAttachment: {
            type: Object,
        },
        removeFile: {
            type: Function,
        },
        dropzonePageCounterSettings: {
            type: Array,
        },
        index: {
            type: Number,
        },
        handleDropzonePageCounterSettings: {
            type: Function,
        },
    },
    data() {
        return {
            showDoubleRangeSlider: false,
            showIndex: null,
            startAt: "",
            endAt: "",
        }
    },
    methods: {
        handleShowDoubleRangeSlider(event) {
            event.preventDefault()
            if (event.target.value === "custom") {
                this.showDoubleRangeSlider = !this.showDoubleRangeSlider
            } else if (event.target.value === "all") {
                this.showDoubleRangeSlider = false;
            }
        },
        handleStartPage(event) {
            this.startAt = event.target.value;
            // Max value set for the endPage. BE needs both values, so if the user didn't set the endPage value, and set 5 in the startPage, the BE will take the full text.
            this.endAt = 1000;
            this.handleDropzonePageCounterSettings(
                this.index,
                this.startPage = this.startAt,
                this.endPage = this.endAt,
            )
        },
        handleEndPage(event) {
            this.endAt = event.target.value;
            this.handleDropzonePageCounterSettings(
                this.index,
                this.startPage = this.startAt,
                this.endPage = this.endAt,
            )
        }
    },
}
</script>
