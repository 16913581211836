import api from '../api';
import * as Sentry from "@sentry/vue";

export class ZoteroAPI {
  getGroups = async () => {
    try {
      const response = await api.get("/zotero/groups");
      return response.data;
    }
    catch(e) {
      console.error(e.message);
    }
  }

  getCollections = async (group, parent, userId=null, apiKey=null) => {
    const params = {};
    params.parent = parent || undefined;
    params.userId = userId !== null && userId || undefined;
    params.apiKey = apiKey !== null && apiKey || undefined;
    if(group > 0) {
      params.group = group;
    }

    try {
      const response = await api.get("/zotero/collections", { params });
      return response.data;
    }
    catch(e) {
      if(userId || apiKey) {
        throw new Error("Unexpected error. Zotero credentials are incorrect.");
      }
      else {
        throw e;
      }
    }
  }

  getItems = async (group, parent=null, search="", start=0, limit=0, sort="") => {
    const params = { parent, search, start, limit, sort };
    if(group > 0) {
      params.group = group;
    }
    const response = await api.get("/zotero/items", { params });
    return response.data;
  }

  fixAndTestCredentials = async (userId, apiKey) => {
    const uId = userId && userId.replace(/\s/g,"") || "";
    const aKey = apiKey && apiKey.replace(/\s/g,"") || "";
    // test correct userID and apiKey before save
    try {
      const data = await this.getCollections(0, null, uId, aKey);
      if (!data.list) {
        return {success: false, message: "Unexpected error. Zotero credentials are incorrect."};
      }
      return { success: true, data: {userId: uId, apiKey: aKey}}
    }
    catch (e) {
      Sentry.captureException(e);
      return {success: false, message: e.message};
    }
  }

  getAttachments = async (group, itemKey) => {
    const params = { itemKey };
    if(group > 0) {
      params.group = group;
    }
    const response = await api.get("/zotero/attachments", { params });
    return response.data;
  }
}
