<!-- @format -->

<template>
  <div>
    <p>Are you sure you want to delete these domains?</p>

    <div class="modal-footer">
      <b-button class="button button--purple" @click.prevent="handleConfirmDelete" :disabled="isSubmitting">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
        Confirm
      </b-button>

      <b-button class="button button--white" @click.prevent="hideModal" :disabled="isSubmitting"> Cancel </b-button>
    </div>
  </div>
</template>

<script>
import { captureReportableError } from "@/util/errorTracker";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    hideModal: {
      type: Function,
    },
  },
  methods: {
    ...mapActions(["startModalNetworkRequest", "endModalNetworkRequest"]),
    async handleConfirmDelete() {
      this.startModalNetworkRequest();
      await this.removeItem();
      await this.modalWindowState.config.deleteCallback();

      this.endModalNetworkRequest();
      this.hideModal();
    },
    removeItem() {
      this.$api.post("/admin/whitelist-delete", { ids: [this.editId] }).catch((err) => {
        captureReportableError(err);

        this.$notify({
          group: "auth",
          type: "error",
          title: "Error",
          text: err.response?.data?.message || err.response?.message || "Unknown error. Please try again later.",
        });
      });
    },
  },
  computed: {
    ...mapGetters(["modalWindowState"]),
    isSubmitting() {
      return this.modalWindowState.isNetworkRequestLoading;
    },
  },
  mounted() {
    this.editId = this.modalWindowState.config.editId;
  },
};
</script>
