<template>
  <div id="slideout-container" ref="slideout-container">
    <div id="panel-1-content" style="padding: 0.75rem; font-size: .8rem" @keyup="onKeyPressed" ref="panel-1-content">
    <div>
      <div class="card">
        <div class="card-header" :class="cardHeaderClass">{{cardHeaderTitle}}<button id="slideout-panel-close" class="close opacity-50" style="float: right;font-size: 1.25rem" @keydown="onKeyPressed" @click="hidePanels()">&#x2715;</button></div>
        <ul class="list-group list-group-flush" v-if="listData">
          <li class="p-2 relative block bg-[#fff] border-[0.5px] border-[#00000020]" v-for="(fact, index) in listData">
            <a v-if="isAutoHighlighting" @click="scrollDown($event, index)" :href="`#main-text-h1 #main-text mark.${typeData}.${typeData}_${index}`">{{fact}}&nbsp;</a>
            <span v-else>{{fact}}&nbsp;</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'panel-1',
  mounted() {
    this.$root.$on('showSlideoutPanel', this.handleSliderEmit);
    this.$root.$on('forceHideSlideoutPanel', this.handleForceHide);
  },
  beforeDestroy() {
    this.$root.$off('showSlideoutPanel', this.handleSliderEmit);
    this.$root.$off('forceHideSlideoutPanel', this.handleForceHide);
  },
  data() {
    return {
      listData : [],
      typeData: "fact",
      slideoutElementIndex:-1, // Index from 0 of selected lost option in slideoutPanel
      serialIndex:0 // serial index inside the highlighted group of selected option
    }
  },
    methods: {
      ...mapGetters(["getOpenedArticleContent"]),
      handleSliderEmit(type) {
        this.clearBackgrounds();
        const article = this.getOpenedArticleContent();
        this.listData = (article?.data)? article?.data[type+"s"] || [] : [];
        this.typeData = type;
        if(type === "keyword") {
          this.listData = this.listData.map(item => item.term || item);
        }

        this.$refs["slideout-container"].classList.add("on");

        const c = document.querySelector("#main-text-h1 div");
        if (c && this.isAutoHighlighting) {
          if (!c.classList.contains("active")) {
            this.triggerEvent(c, "click")
          }
          document.querySelector("#main-text-h1").scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        }
      },
      onKeyPressed(e) {
        if(e.keyCode === 27) {
          this.hidePanels();
        }
      },
      triggerEvent( elem, event ) {
        const clickEvent = new Event( event ); // Create the event.
        elem.dispatchEvent( clickEvent );    // Dispatch the event.
        this.$refs["panel-1-content"].focus();
      },
      scrollDown(e, elementIndex) {
        e.preventDefault();
        const el = document.querySelector(e.target.getAttribute("href"));
        if(el) {
          this.clearBackgrounds();
          e.target.parentNode.classList.remove("bg-[#fff]");
          const _class = (this.typeData === "fact")? "bg-[#c8e5ff]"
            : (this.typeData === "claim") ? "bg-[#ffd9c8]"
            : "bg-[#e8e8e8]";
          e.target.parentNode.classList.add(_class);
          const aHighlightedElements = document.querySelectorAll(e.target.getAttribute("href"));
          if(elementIndex === this.slideoutElementIndex) {
            if(aHighlightedElements?.length && this.serialIndex < aHighlightedElements.length-1 ) {
              this.serialIndex++;
            }
            else {
              this.serialIndex=0;
            }
          }
          else {
            this.slideoutElementIndex = elementIndex;
            this.serialIndex=0;
          }
          aHighlightedElements[this.serialIndex].scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          this.$refs["panel-1-content"].focus();
        }
      },
      clearBackgrounds() {
        document.querySelectorAll("#slideout-container ul>li").forEach((li) => {
          li.classList.remove("bg-[#c8e5ff]");
          li.classList.remove("bg-[#ffd9c8]");
          li.classList.remove("bg-[#e8e8e8]");
          li.classList.add("bg-[#fff]")});
      },
      hidePanels() {
        this.$root.$emit("hideSlideoutPanel");
        this.$refs["slideout-container"].classList.remove("on");
      },
      handleForceHide() {
        this.$refs['slideout-container'].classList.remove('on');
      }
    },
    computed: {
      ...mapGetters(["storeUserInfo"]),
      userInfo() {
        return this.storeUserInfo;
      },
      isAutoHighlighting() {
        return (this.userInfo?.settings?.userView?.auto_highlighting && (this.typeData === "fact" || this.typeData === "claim")) || true;
      },
      cardHeaderClass() {
        return "slideout-"+this.typeData;
      },
      cardHeaderTitle() {
        return this.typeData === "fact" ? "Important points"
          : this.typeData === "claim" ? "Contributions"
            : "Key concepts"
      }
    },
    components: { },
}
</script>
