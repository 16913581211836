<template>
    <div class="content-wrapper content-wrapper-folders">
        <div class="libraryList-item folderList-item folder-fix1">
            <div class="libraryList-item--heading">
                <div class="wrapper">
                    <div class="wrapper item-name">
                        <a href="#" @click="onChangeGroup">
                            <h2 class="library--title folder-container--title folder--title">
                                <i v-if="groupItem._id > 0" class="far fa-share-square folder-icon"></i>
                                <i v-else class="fas fa-book folder-icon"></i>
                                {{ groupItem.name }}
                            </h2>
                        </a>
                    </div>
                </div>
                <div class="wrapper">
                    <p class="documentCounter" v-if="groupItem?.meta?.numItems">
                        <i class="fa fa-file" aria-hidden="true" />
                        {{ groupItem?.meta?.numItems }}
                        <span>items</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import {mapGetters, mapActions} from "vuex"

export default {
    props: {
      groupItem: {
          type: Object,
          required: true
      }
    },
    methods: {
      onChangeGroup() {
        this.$emit("change-group", this.groupItem);
      }
    }
}
</script>
