<template>
  <b-modal
    hide-footer
    centered
    no-close-on-backdrop
    no-close-on-esc
    id="idEditZoteroCredentials"
    title="Connect your Zotero Account"
  >
        <form class="form">
            <p class="form-title">
              Please click the Connect button to link your Zotero account with Scholarcy.
            </p>
        </form>
        <div class="modal-footer">
            <button
                class="button button--purple button--small"
                @click="handleSaveZotero"
                :disabled="isSubmitting"
            >
                <i class="fas fa-check-circle"></i>
                Connect
            </button>
            <button
                class="button button--white button--small"
                @click.prevent="hideModal"
                :disabled="isSubmitting"
            >
                Cancel
            </button>
        </div>
      <BusyPanel :b-show-loading-spinner="bLoadingPanel"/>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions} from "vuex"
import BusyPanel from "../ui/ParentBlockBusyPanel.vue";

export default {
    data() {
        return {
            bLoadingPanel: false
        }
    },
    methods: {
      ...mapActions(['startModalNetworkRequest', 'endModalNetworkRequest']),
      async handleSaveZotero() {
        this.startModalNetworkRequest();
        this.bLoadingPanel=true;
        window.location.href = import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST+"/resources/zotero/auth"
        this.bLoadingPanel=false;
      },
      hideModal() {
        this.$bvModal.hide('idEditZoteroCredentials')
      }
    },
    computed: {
      ...mapGetters(["modalWindowState"]),
      isSubmitting() {
        return this.modalWindowState.isNetworkRequestLoading
      },
    },
    components: {
      BusyPanel
    },
}
</script>
