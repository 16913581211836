<template>
    <div class="accordion">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
      activeItem: {
        type: Number,
        default : 0
      }
    },
    data() {
        return {
            Accordion: {
                count: 0,
                active: 0, // force first item to be open.
            },
        }
    },
    provide() {
        return { Accordion: this.Accordion }
    },
    mounted() {
      if(this.activeItem) {
        this.Accordion.active = this.activeItem;
      }
    }
}
</script>
