<template>
    <b-modal
        :visible="isModalWindowOpen"
        hide-footer
        centered
        @close="onCloseModalWindow"
        @hide="handleModalWindow"
        :title="this.getModalWindowParams.title"
        :id="this.getModalWindowParams.id"
    >
        <ImportUsers
            v-if="modalWindowType === 'ImportUsers'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <ImportInvoices
            v-else-if="modalWindowType === 'ImportInvoices'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <BulkUnsubscribe
            v-else-if="modalWindowType === 'BulkUnsubscribe'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <EditUser
            v-else-if="
                modalWindowType === 'EditUser' || modalWindowType === 'AddUser'
            "
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <AddDomain
            v-else-if="modalWindowType === 'AddDomain'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <ImportApproveListFile
            v-else-if="modalWindowType === 'ImportApproveListFile'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <AddEmailBlocklist
            v-else-if="modalWindowType === 'AddEmail'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <EditEmailBlocklist
            v-else-if="modalWindowType === 'EditEmail'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <DeleteEmailBlocklist
            v-else-if="modalWindowType === 'DeleteEmail'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <DeleteUser
            v-else-if="modalWindowType === 'DeleteUser'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <DeleteDomain
            v-else-if="modalWindowType === 'DeleteDomain'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
        />
        <SummaryUser
            v-else-if="modalWindowType === 'SummaryUser'"
            :hideModal="() => this.$bvModal.hide(this.getModalWindowParams.id)"
            :msgBoxOk="(text) => this.$bvModal.msgBoxOk(text)"
        />
    </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ImportUsers from './ImportUsers.vue'
import ImportInvoices from './ImportInvoices.vue'
import BulkUnsubscribe from './BulkUnsubscribe.vue'
import EditUser from './EditUser.vue'
import AddDomain from './AddDomain.vue'
import ImportApproveListFile from './ImportApproveListFile.vue'
import AddEmailBlocklist from './AddEmailBlocklist.vue'
import EditEmailBlocklist from './EditEmailBlocklist.vue'
import DeleteEmailBlocklist from './DeleteEmailBlocklist.vue'
import DeleteUser from './DeleteUser.vue'
import SummaryUser from './SummaryUser.vue'
import DeleteDomain from './DeleteDomain.vue'

export default {
    data() {
        return {}
    },
    methods: {
        ...mapActions(["handleModalWindow", "setGeneralUploadProgressBarCurrent", "setGeneralUploadProgressBarCount"]),
      onCloseModalWindow(evt) {
        if(evt?.trigger === "headerclose" && evt?.componentId === "modalAdd") {
          // We try to close UploadDocument modal. Need to cancel all uploads without many messages!
          if(this.$refs.UploadDocument.$refs.myVueDropzone?.dropzone?.getQueuedFiles().length > 0) {
            this.setGeneralUploadProgressBarCurrent(0);
            this.setGeneralUploadProgressBarCount(0);
            this.$refs.UploadDocument.$refs.myVueDropzone.dropzone.removeAllFiles();
          }
        }
      }
    },
    computed: {
        ...mapGetters(['modalWindowState']),
        isModalWindowOpen() {
            return this.modalWindowState.isOpen
        },
        modalWindowType() {
            return this.modalWindowState.type
        },
        deleteLibraryItemTitle() {
            return this.modalWindowState.itemTitle
                ? this.modalWindowState.itemTitle
                : 'library'
        },
        summaryHighlightsTitle() {
            return this.modalWindowState.itemTitle
                ? this.modalWindowState.itemTitle
                : 'Edit'
        },
        summaryFiguresTitle() {
            return this.modalWindowState.itemTitle
                ? this.modalWindowState.itemTitle
                : 'Edit'
        },
        uploadDocumentItemTitle() {
            return this.modalWindowState.itemTitle
                ? `to ${this.modalWindowState.itemTitle}`
                : ''
        },
        getModalWindowParams() {
            switch (this.modalWindowType) {
                case 'EditZoteroCredentials':
                    return {
                        id: 'editZoteroCredentials',
                        title: 'Edit Zotero Credentials',
                    }
                case 'ArticleSynopsis':
                    return {
                        id: 'articleSynopsis',
                        title: 'Edit Synopsis',
                    }
                case 'ArticleAbstract':
                    return {
                        id: 'articleAbstract',
                        title: 'Edit Abstract',
                    }
                case 'RssFeed':
                    return {
                        id: 'rssFeed',
                        title: 'RSS Feed',
                    }
                case 'EditArticle':
                    return {
                        id: 'editArticle',
                        title: 'Edit article data',
                    }
                case 'MoveDocument':
                    return {
                        id: 'moveDocument',
                        title: 'Move documents',
                    }
                case 'ArticleNotes':
                    return {
                        id: 'notes',
                        title: 'Notes',
                    }
                case 'UploadDocument':
                    return {
                        id: 'modalAdd',
                        title: `Import documents ${this.uploadDocumentItemTitle}`,
                    }
                case 'ShareLibrary':
                    return {
                        id: 'modalShare',
                        title: 'Share library',
                    }
                case 'SharingStop':
                    return {
                        id: 'modalSharingStop',
                        title: 'Stop sharing',
                    }
                case 'SharingUpdate':
                    return {
                        id: 'modalSharingUpdate',
                        title: 'Update sharing permissions',
                    }
                case 'DeleteLibrary':
                    return {
                        id: 'modalDelete',
                        title: `Delete Library ${this.deleteLibraryItemTitle}`,
                    }
                case 'DeleteFolder':
                    return {
                        id: 'modalDelete',
                        title: 'Delete folder',
                    }
                case 'DeleteArticle':
                    return {
                        id: 'deleteArticle',
                        title: 'Delete Flashcard?',
                    }
                case 'KeyConcept':
                    return {
                        id: 'keyConcept',
                        title: 'Key Concepts',
                    }
                case 'SummaryHighlights':
                    return {
                        id: 'SummaryHighlights',
                        title: `${this.summaryHighlightsTitle}`,
                    }
                case 'SummaryFigures':
                    return {
                        id: 'SummaryFigures',
                        title: `${this.summaryFiguresTitle}`,
                    }
                case 'AccessRights':
                    return {
                        id: 'accessRights',
                        title: 'Access rights',
                    }
                case 'ImportUsers':
                    return {
                        id: 'importUsers',
                        title: 'Import users',
                    }
                case 'ImportInvoices':
                    return {
                        id: 'importInvoices',
                        title: 'Import invoices from Stripe',
                    }
                case 'AddUser':
                    return {
                        id: 'addUser',
                        title: 'Add user',
                    }
                case 'BulkUnsubscribe':
                    return {
                        id: 'bulkUnsubscribe',
                        title: 'Bulk unsubscribe',
                    }
                case 'EditUser':
                    return {
                        id: 'editUser',
                        title: 'Edit user',
                    }
                case 'ImportApproveListFile':
                    return {
                        id: 'ImportApproveListFile',
                        title: 'Import domains',
                    }
                case 'AddDomain':
                    return {
                        id: 'addDomain',
                        title: 'Add Domain',
                    }
                case 'AddEmail':
                    return {
                        id: 'addEmail',
                        title: 'Add Email',
                    }
                case 'EditEmail':
                    return {
                        id: 'editEmail',
                        title: 'Edit email blocklist',
                    }
                case 'DeleteEmail':
                    return {
                        id: 'deleteEmail',
                        title: 'Delete Email',
                    }
                case 'DeleteDomain':
                    return {
                        id: 'deleteDomain',
                        title: 'Delete Domain',
                    }
                case 'DeleteUser':
                    return {
                        id: 'deleteUser',
                        title: 'Delete User',
                    }
                case 'SummaryUser':
                    return {
                        id: 'summaryUser',
                        title: 'Summary User',
                    }
                case 'EnhanceOptions':
                    return {
                        id: 'enhanceOptions',
                        title: 'Enhance your summary',
                    }
                default:
                    return {}
            }
        },
    },
    components: {
        ImportUsers,
        BulkUnsubscribe,
        EditUser,
        ImportApproveListFile,
        AddDomain,
        AddEmailBlocklist,
        EditEmailBlocklist,
        DeleteEmailBlocklist,
        DeleteUser,
        SummaryUser,
        DeleteDomain,
        ImportInvoices,
    }
}
</script>
