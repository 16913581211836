<template>
    <div>
        <form>
            <vue-dropzone
                ref="myVueDropzone"
                id="id-documents-dropzone"
                class="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                :include-styling="false"
                @vdropzone-file-added="fileAdded"
                @vdropzone-removed-file="removeFile"
            >
                <div class="dropzone-custom-content">
                    <i class="fa fa-inbox" aria-hidden="true"></i>
                    <p class="drag-title">
                        Click or drag file to this area to upload
                    </p>
                    <p class="drag-subtitle">
                        Support for a single or bulk upload
                    </p>
                </div>
            </vue-dropzone>
        </form>
        <div class="modal-footer">
            <button
                class="button button--white button--small"
                @click.prevent="hideModal"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AttachmentList from '@/components/ui/AttachmentList.vue'

const apiScheme = import.meta.env.VITE_API_SCHEME || 'http'
const apiHost = import.meta.env.VITE_API_HOST || 'localhost:4000'
const apiPath =
    import.meta.env.VITE_API_PATH === 'empty' || !import.meta.env.VITE_API_PATH
        ? ''
        : import.meta.env.VITE_API_PATH

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    data() {
        return {
            tempAttachments: [],
            attachments: [],

            dropzoneOptions: {
                success: this.fileComplete,
                error: this.dropzoneError,
                sending: this.sendingEvent,
                previewsContainer: false,
                timeout: 120000,
                url:
                    apiScheme +
                    '://' +
                    apiHost +
                    apiPath +
                    '/admin/whitelist-import',
                createImageThumbnails: false,
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize: 30,
                acceptedFiles: '.json,application/json',
                withCredentials: true,
            },
            fileAdded: '',
            removeFile: ''
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
        fileComplete(file, response) {
            this.$notify({
                group: 'auth',
                type: 'success',
                title: 'Success',
                text: `Finished uploading and processing file. Imported:${response.imported}, Skipped:${response.skipped}`,
            })
            this.importCallback && this.importCallback()
            this.hideModal()
        },
        sendingEvent(file, xhr, formData) {
            this.loading = true
        },
        dropzoneError(file, message, xhr) {
            if (xhr && xhr.statusText) {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text:
                        xhr.status + ' : ' + message.message
                            ? message.message
                            : xhr.statusText,
                })
            } else if (xhr && xhr.status !== 200 && message.message) {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Import error',
                    text: message.message,
                })
            }
            this.hideModal()
        },
    },
    computed: {
        ...mapGetters(['modalWindowState', 'userLibraryList']),
        libraryItemId() {
            return (
                this.modalWindowState.config &&
                this.modalWindowState.config.libraryItemId
            )
        },
        getTempAttachments() {
            return this.tempAttachments
        },
        getAttachments() {
            return this.attachments
        },
    },
    components: {
        vueDropzone: vue2Dropzone,
        AttachmentList,
    },
}
</script>
