<template>
    <div class="user-folder-list">
        <div class="subtitle-action-block">
            <h2 class="subtitle">
                {{ parentCollectionId && collections.length > 2 ? "Collections" : "Collection" }}
                <span v-if="collections.length > 1">({{ parentCollectionId && collections.length - 1 || collections.length}})</span>
                <span v-if="currentName" class="ml-1">-&nbsp;{{currentName}}</span>
            </h2>
            <div class="p-1 cursor-pointer" @click="onToggleCollections">
              <i class="fas fa-chevron-down transition duration-300 ease-in-out" :class="{'rotate-180' : !bShowCollections}"></i>
            </div>
        </div>
        <slot></slot>
        <div :class="{'hidden':!bShowCollections}">
          <template v-for="(collection) in collections">
              <ZoteroCollectionItem
                  :key="collection._id"
                  :collectionItem="collection"
                  @change-collection="onChangeCollection"
              />
          </template>
        </div>
    </div>
</template>

<script>

import ZoteroCollectionItem from "./ZoteroCollectionItem.vue"

export default {
    props: {
      collections: {
          type: Array,
          required: true
      },
      parentCollectionId: {
        type: String
      },
      currentName: {
        type: String
      }
    },
    mounted() {
    },
    data() {
        return {
          bShowCollections: true
        }
    },
    computed: {
    },
    methods: {
      onToggleCollections() {
        this.bShowCollections = !this.bShowCollections;
      },
      onChangeCollection(payload) {
        this.$emit("change-collection", payload)
      }
    },
    watch: {
    },
    components: {
      ZoteroCollectionItem
    },
}
</script>
