import 'tippy.js/dist/tippy.css';

/**
 * Formats a given value into a specified date-time format.
 *
 * @export
 * @param {(Date|string|number|null)} [value=new Date()] The value to be formatted. Can be a Date object, string, number or null.
 * @param {string} [formatType="DD/MM/YYYY, HH:mm"] The desired format type.
 * @returns {string} Formatted date string.
 */
export const DTFormat = (value=new Date(), formatType="DD/MM/YYYY, HH:mm") => {
    if(value === null || value === '') {
        return '';
    }
    if(typeof value === 'string') {
        value = Date.parse(value);
    }
    const IntlDTF = {};
    IntlDTF["DD MMM YYYY at HH:mm"] = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
    });
    IntlDTF["DD/MM/YYYY, HH:mm"] = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
    });
    IntlDTF["DD/MM/YYYY, HH:mm:ss"] = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false
    });
    IntlDTF["DD/MM/YYYY"] = new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false
    });
    IntlDTF["YYYY-MM-DD"] = new Intl.DateTimeFormat('en-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour12: false
    });
    return (IntlDTF[formatType])?IntlDTF[formatType].format(value):''
}

export const validateEmail = (testEmail='') => {
  const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_"{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]: {2}(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  return testEmail==='' || testEmail.match(emailRegEx);
}
