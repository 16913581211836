<template>
    <div>
        <form class="form">
            <div class="form-group">
                <label for="">Domain</label>
                <input
                    type="email"
                    placeholder="Paste domain here"
                    v-model="editDomain"
                />
            </div>
            <div class="form-group">
                <label for="">Payment plan</label>
                <multiselect
                    v-model="editPlanName"
                    :options="paymentPlanList"
                    :max-height="200"
                    group-values="options"
                    group-label="group"
                    :multiple="false"
                    :group-select="false"
                    :searchable="false"
                    :show-labels="false"
                >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </multiselect>
            </div>

            <div class="row">
                <div class="form-group">
                    <label for="">Product</label>
                    <input
                        type="text"
                        v-model="currentProductPlanName"
                        placeholder="Product name here"
                        readonly
                    />
                </div>
                <div class="form-group">
                    <label for="">Currency</label>
                    <input
                        type="text"
                        v-model="calcCurrency"
                        placeholder="Currency here"
                        readonly
                    />
                </div>
            </div>

            <div class="row">
                <div class="form-group">
                    <label for="id-wl-max-signups">Maximum signups</label>
                    <input
                        id="id-wl-max-signups"
                        type="number"
                        v-model="editMaximumSignups"
                        placeholder="Maximum signups here"
                        min="0"
                        style="padding-left: 8px;padding-right: 8px"
                    />
                </div>
                <div class="form-group">
                  <label for="id-wl-exp-date">Expire date</label>
                  <div>
                    <b-form-datepicker
                        v-model="editExpireDate"
                        id="id-wl-exp-date"
                        placeholder="No expiration date"
                        size="sm"
                        local="en"
                        class="form-control"
                        :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                    }"
                        reset-button
                        :hide-header="true"
                        :show-decade-nav="false"
                        style="border-radius: 8px"
                    />
                  </div>
                </div>
            </div>
          <div class="form-group">
            <label for="">Allowed IPs</label>
            <input
                type="text"
                placeholder="Empty of Allowed IPs here"
                v-model="editAllowedIP"
            />
          </div>
        </form>
        <div class="modal-footer">
            <button
                class="button button--purple button--small"
                @click="handleDomain"
                :disabled="isSubmitting"
            >
                <i class="fas fa-check-circle"></i>
                Save
            </button>
            <button
                class="button button--white button--small"
                @click.prevent="hideModal"
                :disabled="isSubmitting"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    data() {
        return {
            editId: '',
            editPlanName: '',
            editPlanId: '',
            editDomain: '',
            currentProductPlanName: '',
            paymentPlanList: [],
            list: [],
            productList: [],
            freeProductList: [],
          editMaximumSignups:0,
          editExpireDate:null,
          editAllowedIP:''
        }
    },
    methods: {
        ...mapActions(['startModalNetworkRequest', 'endModalNetworkRequest']),
        async getProductList() {
            this.$api.get('/admin/productList').then((list) => {
                this.productList = list.data
                this.freeProductList = this.productList.filter((product) => {
                    const freePlans = product.payment_plans.filter((price) => {
                        return price.unit_amount === 0
                    })
                    return freePlans.length > 0
                })
                this.handlePaymentPlanList()
            })
        },
        handlePaymentPlanList() {
            this.paymentPlanList = this.freeProductList.map((e) => {
                return {
                    group: e.name,
                    options: e.payment_plans.map((plan) => plan.nickname),
                }
            })
        },
        async handleDomain() {
            this.startModalNetworkRequest();
            const url = !this.editId
                ? '/admin/whitelist-add'
                : '/admin/whitelist-edit'
            const oUpdate = {
              _id: this.editId,
              domain: this.editDomain,
              plan: this.editPlanId,
              signups: this.editMaximumSignups,
              expire:this.editExpireDate,
              allowed_ips: this.editAllowedIP
            }

            try {
                await this.$api.post(url, oUpdate);
                this.$notify({
                    group: 'auth',
                    type: 'success',
                    title: 'Success',
                    text: 'Approved domain updated successfully',
                });
            }
            catch(err) {
                captureReportableError(err);

                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: err.response.data.message
                        ? err.response.data.message
                        : err.response.message,
                })
            }
            this.modalWindowState.config.domaineCallback();

            this.endModalNetworkRequest()
            this.hideModal()
        },
    },
    computed: {
      ...mapGetters(['modalWindowState']),
      calcCurrency() {
        if (this.editPlanId) {
          for(const product of this.freeProductList) {
            for (const plan of product.payment_plans) {
              if (plan.id === this.editPlanId) {
                return plan.currency.toUpperCase()
              }
            }
          }
          return ''
        } else {
          return ''
        }
      },
      isSubmitting() {
        return this.modalWindowState.isNetworkRequestLoading
      }
    },
    async mounted() {
      console.log(this.modalWindowState.config);
      await this.getProductList()
      this.editId = this.modalWindowState.config.editId
      this.editPlanId = this.modalWindowState.config.editPlanId
      this.editDomain = this.modalWindowState.config.editDomain
      this.editMaximumSignups = this.modalWindowState.config.editMaximumSignups || 0
      this.editExpireDate = this.modalWindowState.config.editExpireDate || ''
      this.editAllowedIP = this.modalWindowState.config.editAllowedIP || ''
    },

    watch: {
        editPlanName(val) {
            const currentProduct = this.freeProductList.find((product) => {
                return product.payment_plans.find((plan) => {
                    return plan.nickname === val
                })
            })

            this.currentProductPlanName = currentProduct
                ? currentProduct.name
                : ''

            this.editPlanId = currentProduct.payment_plans.find(
                (e) => e.nickname === val
            ).id
        },
        freeProductList() {
            if (this.editId && this.editPlanId) {
                const filteredPlanGroup = this.freeProductList.find((group) => {
                    return group.payment_plans.find((plan) => {
                        return plan.id === this.editPlanId
                    })
                })
                const filteredPlanItem = filteredPlanGroup.payment_plans.find(
                    (plan) => {
                        return plan.id === this.editPlanId
                    }
                )
                this.editPlanName = filteredPlanItem.nickname
            }
        },
    },
    components: {
        Multiselect,
    },
}
</script>
