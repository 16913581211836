<template>
    <div>
        <p>Are you sure you want to delete this email?</p>

        <div class="modal-footer">
            <b-button
                class="button button--purple"
                @click.prevent="handleConfirmDelete"
                :disabled="isSubmitting"
            >
                <i class="fa fa-check-circle" aria-hidden="true"></i>
                Confirm
            </b-button>

            <b-button class="button button--white" @click.prevent="hideModal" :disabled="isSubmitting">
                Cancel
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    methods: {
      ...mapActions(['startModalNetworkRequest', 'endModalNetworkRequest']),
        async handleConfirmDelete() {
            this.startModalNetworkRequest();
            await this.removeItem()
            this.modalWindowState.config.deleteEmailCallback()

            this.endModalNetworkRequest();
            this.hideModal()
        },
        async removeItem() {
            try {
                const id = this.modalWindowState.config.emailId
                await this.$api.delete(
                    `/admin/blacklist/${encodeURIComponent(id)}`
                )
            } catch (err) {
                captureReportableError(err);

                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text: err.response?.data?.message || err.response?.message || "Unexpected error. Please try again later.",
                })
            }
        },
    },
    computed: {
        ...mapGetters(['modalWindowState']),
        isSubmitting() {
          return this.modalWindowState.isNetworkRequestLoading;
        }
    },
}
</script>
