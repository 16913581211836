import * as Sentry from "@sentry/vue";

import Vue from 'vue'
import Router from 'vue-router'

import CreateInvoice from "./views/CreateInvoice.vue";

import UsersList from "./views/admin/UsersList.vue";
import DeadList from "./views/admin/DeadList.vue";
import Approvelist from "./views/admin/ApproveList.vue";
import ReportList from "./views/admin/ReportList.vue";
import Blocklist from "@/views/admin/BlockList.vue";

import store from './store';

const { isNavigationFailure, NavigationFailureType } = Router

Vue.use(Router);

const redirectToPergamonPath = (pathName) => window.location.href = `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}${pathName}`;

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/createInvoice/:token',
      name: 'createInvoice',
      component: CreateInvoice,
      meta: { auth: true, title: 'Create Stripe Invoice' }
    },
    {
      path: '/usersList',
      name: 'usersList',
      component: UsersList,
      meta: { auth: true, title: 'Users list' }
    },
    {
      path: '/deadList',
      name: 'deadList',
      component: DeadList,
      meta: { auth: true, title: 'Dead accounts' }
    },
    {
      path: '/approvelist',
      name: 'approvelist',
      component: Approvelist,
      meta: { auth: true, title: 'Approved list' }
    },
    {
      path: '/blocklist',
      name: 'blocklist',
      component: Blocklist,
      meta: { auth: true, title: 'Blocked list' }
    },
    {
      path: '/reports',
      name: 'reportslist',
      component: ReportList,
      meta: { auth: true, title: 'Reports list' }
    },
    {
      path: '/login',
      name: 'login',
      beforeEnter() {
        redirectToPergamonPath('/login')
      },
    },
        {
      path: '/',
      name: 'root',
      redirect: {'name': 'usersList'}
    },
  ]
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((e) => {
    if(isNavigationFailure(e, NavigationFailureType.redirected)) {
      return Promise.resolve(false);
    }
    if(isNavigationFailure(e, NavigationFailureType.duplicated)) {
      return Promise.resolve(false);
    }
    // Otherwise throw error
    return Promise.reject(e);
  });
};

const requireAuth = function (to, from, next) {
  if (to.fullPath.substring(0,1) === "/#") {
    const path = to.fullPath.substring(2);
    next(path);
    return;
  }

  if(to.name === 'pageNotFound') {
    if(to.fullPath !== '/') {
      next({path: '/'});
    }
    else {
      next();
    }
    return;
  }

  const route = to.matched.find(e => e.meta.auth != null);
  const auth = (store.getters)?store.getters.isLoggedIn:false;
  const userInfo = (store.getters)?store.getters.storeUserInfo:{};

  document.title = to.meta.title;
  if (route) {
    const needAuth = route.meta.auth;
    if (needAuth && !auth) {
      Sentry.setUser(null);
      store.commit('LOGOUT_USER');

      const redirect = (to.fullPath && to.fullPath.trim().length > 0)?`?redirect=${encodeURIComponent(to.fullPath)}`:''
      if(to.fullPath.substring(0,6) !== '/login') {
        next({path: '/login'+redirect})
      }
      else {
        next();
      }
    }
    else if(['confirmUser'].indexOf(route.name) >= 0) {
      Sentry.setUser(null);
      store.commit('LOGOUT_USER');
      next();
    }
    else if(route.name === 'createInvoice') {
      next();
    }
    else if (!needAuth && auth) {
      if(to.fullPath !== '/') {
        next({path: '/'})
      }
      else {
        next();
      }
    }
    else if (['approvelist', 'blocklist'].indexOf(route.name) >= 0 && userInfo.role !== 'admin') {
      if(to.fullPath !== '/') {
        next({path: '/'})
      }
      else {
        next();
      }
    }
    else if (route.name === 'usersList' && userInfo.role === 'user') {
      if(to.fullPath !== '/') {
        next({path: '/'})
      }
      else {
        next();
      }
    }
    else if (needAuth && auth && (!userInfo || !userInfo.stripe || !userInfo.stripe.subscription || ['active', 'trialing'].indexOf(userInfo.stripe.subscription_state) === -1) && route.path !== '/subscription' && route.path !== '/profile') {
      if (userInfo.trialTill === undefined || userInfo.trialTill === null || userInfo.trialTill < new Date()) {
        redirectToPergamonPath('/subscription');
      }
      else {
        next();
      }
    }
    else {
      if(!to.fullPath.includes('/login') && !to.fullPath.toUpperCase().includes('/MFA')) {
        localStorage.setItem("oldPath", localStorage.getItem('curPath'));
        localStorage.setItem("curPath", to.fullPath);
      }
      else if(to.fullPath.startsWith('/login?redirect=')) {
        localStorage.setItem("curPath", decodeURIComponent(to.fullPath.substring(16)));
        localStorage.setItem("oldPath", null);
      }
      next()
    }
  }
  else {
    next()
  }
};

router.beforeEach(requireAuth);

export default router;
