<template>
    <b-modal
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        title="OneDrive Picker"
        id="idOnedrivePicker"
        @shown="() => startPicker()"
    >
      <div>
        <Spinner size="huge" style="top:30%; left:45%; position: absolute" v-if="loading"></Spinner>
        <iframe id="id-onedrive-picker" ref="ref-onedrive-picker" width="100%" style="height: 60vh"></iframe>
      </div>
    </b-modal>
</template>

<script>
import {getAccessTokens, removeAccessToken, getOnedriveOrigin} from '../../util/helperMicrosoftAuth';
import Spinner from "vue-simple-spinner";
import * as Sentry from "@sentry/vue";

let oneWindow = null;
let authToken = '';
let oid = '';

export default {
  data() {
    return {
      loading : true,
      port: null,
      baseUrl: "https://onedrive.live.com/picker",
      params: {
        sdk: "8.0",
        entry: {
          oneDrive: {
            files: {},
          }
        },
        selection: {
          mode: "multiple"
        },
        authentication: {},
        messaging: {
          origin: "",
          channelId: "27"
        },
        typesAndSources: {
          mode: "files",
          filters: [".pdf",".bib",".nbib"],
          pivots: {
            oneDrive: true,
            recent: true,
            sharedLibraries: true
          },
        },
        accessibility: {
          trapFocusOnLoad: true,
          showFocusOnLoad: true
        }
      },
      pickedData: null
    }
  },
  methods: {
    hideModal() {
      this.$bvModal.hide('idOnedrivePicker')
    },
    async logoutOnedrive() {
      await removeAccessToken()
      oneWindow = null;
      this.$refs["ref-onedrive-picker"].src='';
    },
    async startPicker() {
      try {
        this.params.messaging.origin = getOnedriveOrigin()
        oneWindow = this.$refs["ref-onedrive-picker"].contentWindow;

        [authToken, oid] = await getAccessTokens();

        const queryString = new URLSearchParams({
          filePicker: JSON.stringify(this.params),

          // This addition is required for the File picker to work as of 2023-11-28,
          // see https://stackoverflow.com/a/77492041
          cid: oid.replace(/-/g, "").slice(16),
        });

        const url = `${this.baseUrl}?${queryString}`;

        const form = oneWindow.document.createElement("form");
        form.setAttribute("action", url);
        form.setAttribute("method", "POST");
        oneWindow.document.body.append(form);

        const input = oneWindow.document.createElement("input");
        input.setAttribute("type", "hidden")
        input.setAttribute("name", "access_token");
        input.setAttribute("value", authToken);
        form.appendChild(input);

        form.submit();
        window.addEventListener("message", (event) => {
          if (event.source && event.source === oneWindow) {
            const message = event.data;
            if (message.type === "initialize" && message.channelId === this.params.messaging.channelId) {
              this.port = event.ports[0];
              this.port.addEventListener("message", this.messageListener);
              this.port.start();
              this.port.postMessage({
                type: "activate",
              });
            }
          }
        });
      }
      catch (e) {
        if(e.message && e.message.includes('user_cancelled')) {
          e.message = 'Authentication canceled.';
        } else {
          Sentry.captureException(e);
        }

        this.$notify({
          group: 'auth',
          type: 'error',
          title: 'Error',
          text: e.error_description || e.response?.data?.message || e.response?.message || e.message
        })
        this.loading = false;
        this.hideModal();
      }
    },
    async messageListener(message) {
      switch (message.data.type) {
        case "notification":
          // console.log(`notification:`, message.data);
          if(message.data.data && message.data.data.notification) {
            if(message.data.data.notification === 'page-loaded') {
              this.loading = false;
            }
          }
          break;
        case "command":
          this.port.postMessage({
            type: "acknowledge",
            id: message.data.id,
          });
          const command = message.data.data;
          switch (command.command) {
            case "authenticate":
              const [token, _oid] = await getAccessTokens();
              if (typeof token !== "undefined" && token !== null) {
                this.port.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "token",
                    token,
                  }
                });
              }
              else {
                console.error(`Could not get auth token for command: ${JSON.stringify(command)}`);
              }
              break;
            case "close":
              const answer = await this.$root.$bvModal.msgBoxConfirm('Save OneDrive account data for future sessions?', {
                okTitle: 'Yes, save',
                okVariant: 'success',
                cancelTitle: 'No, clear it',
                cancelVariant: 'danger',
                centered: true,
                id: 'my-box-id'
              });
              if(answer === false) {
                await this.logoutOnedrive();
              }
              this.hideModal();
              break;
            case "pick":
              const myHeaders = new Headers();
              myHeaders.append('Authorization',`bearer ${authToken}`);
              myHeaders.append('Content-Type',`application/json`);
              // Loop picked DriveItems && add DirectUrl via additional API request
              for(let i = 0; i < command.items.length; i++) {
                const odDirectUrl = `${command.items[i]["@sharePoint.endpoint"]}/drive/items/${command.items[i].id}`
                try {
                  // Get full DriveItem info includes Direct DownloadUrl
                  const response = await fetch(odDirectUrl, {
                    headers: myHeaders,
                  })
                  if (response.ok) {
                    const json = await response.json();
                    command.items[i].url = json['@content.downloadUrl'];
                  }
                  else {
                    Sentry.captureException(new Error(`OneDrive direct URL error`), {extra: { url: odDirectUrl, response}});
                  }
                }
                catch (e) {
                  Sentry.captureException(e);
                }
              }
              this.pickedData = command;
              this.port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "success",
                },
              });
              this.$root.$emit('OnedrivePicked', this.pickedData);
              this.hideModal();
              break;
            default:
              this.port.postMessage({
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command
                },
                isExpected: true,
              });
          }
          break;
      }
    }
  },
  components: {
    Spinner
  }
}
</script>
