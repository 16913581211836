<template>
    <div>
        <accordion>
            <accordion-item title="Filter users">
                <div class="row">
                    <div class="form-group">
                        <label for="">Role</label>
                        <multiselect
                            v-model="pickedUserRolesData"
                            :options="userRolesData"
                            :searchable="false"
                            :show-labels="false"
                            :disabled="true"
                        >
                            <span
                                slot="caret"
                                slot-scope="{ toggle }"
                                class="arrow"
                                @mousedown.prevent.stop="toggle"
                            >
                                <i class="fa fa-chevron-down"></i>
                            </span>
                        </multiselect>
                    </div>
                    <div class="form-group">
                        <label for="">Access type</label>
                        <multiselect
                            v-model="pickedAccessTypeList"
                            :options="accessTypeList"
                            :searchable="false"
                            :show-labels="false"
                            :disabled="true"
                        >
                            <span
                                slot="caret"
                                slot-scope="{ toggle }"
                                class="arrow"
                                @mousedown.prevent.stop="toggle"
                            >
                                <i class="fa fa-chevron-down"></i>
                            </span>
                        </multiselect>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group">
                        <label for="">Subscription</label>
                        <multiselect
                            v-model="pickedSubscribtionPlanList"
                            :options="computedSubscribtionPlanList"
                            :searchable="false"
                            :show-labels="false"
                            :disabled="true"
                        >
                            <span
                                slot="caret"
                                slot-scope="{ toggle }"
                                class="arrow"
                                @mousedown.prevent.stop="toggle"
                            >
                                <i class="fa fa-chevron-down"></i>
                            </span>
                        </multiselect>
                    </div>
                    <div class="form-group">
                        <label for="">Parent agent</label>
                        <multiselect
                            v-model="pickedParentAgentList"
                            :options="parentAgentList"
                            :searchable="false"
                            :show-labels="false"
                            :disabled="true"
                        >
                            <span
                                slot="caret"
                                slot-scope="{ toggle }"
                                class="arrow"
                                @mousedown.prevent.stop="toggle"
                            >
                                <i class="fa fa-chevron-down"></i>
                            </span>
                        </multiselect>
                    </div>
                </div>

                <div class="form-group">
                    <label for="">Mail domain</label>

                    <div class="input-icon-wrapper">
                        <i class="fas fa-at"></i>

                        <multiselect
                            v-model="selectedMailDomains"
                            :options="mailDomainsOptions"
                            @tag="addTag"
                            :multiple="true"
                            :taggable="true"
                            :show-labels="false"
                            tag-placeholder="Add"
                        >
                            <span slot="search">
                                <i class="fas fa-at"></i>
                            </span>
                            <span
                                slot="caret"
                                slot-scope="{ toggle }"
                                class="arrow"
                                @mousedown.prevent.stop="toggle"
                            >
                                <i class="fa fa-chevron-down"></i>
                            </span>
                        </multiselect>
                    </div>
                </div>

                <div class="form-group">
                    <label for="">Expiry</label>
                    <multiselect
                        v-model="pickedExpiryDateList"
                        :options="expiryDateList"
                        :searchable="false"
                        :show-labels="false"
                        :disabled="true"
                    >
                        <span
                            slot="caret"
                            slot-scope="{ toggle }"
                            class="arrow"
                            @mousedown.prevent.stop="toggle"
                        >
                            <i class="fa fa-chevron-down"></i>
                        </span>
                    </multiselect>
                </div>
                <div class="table-body-item-control">
                    <input
                        id="bulkCancelNowFilter"
                        type="checkbox"
                        class="checkbox-template"
                        v-model="bulkCancelNowFilter"
                    />
                    <label for="bulkCancelNowFilter">Cancel immediately</label>
                </div>
                <div class="modal-footer">
                    <button
                        class="button button--purple"
                        @click="handleFilterBulkDomain"
                    >
                        <i class="fas fa-times-circle"></i>
                        Unsubscribe
                    </button>
                    <button
                        class="button button--white button--small"
                        @click.prevent="hideModal"
                    >
                        Cancel
                    </button>
                </div>
            </accordion-item>
            <accordion-item title="Upload CSV file with emails">
                <vue-dropzone
                    ref="myVueDropzone"
                    id="id-documents-dropzone"
                    class="dropzone"
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    :include-styling="false"
                >
                    <div class="dropzone-custom-content">
                        <i class="fa fa-inbox" aria-hidden="true"></i>
                        <p class="drag-title">
                            Click or drag file to this area to upload
                        </p>
                        <p class="drag-subtitle">
                            Support for a single or bulk upload
                        </p>
                    </div>
                </vue-dropzone>
                <div class="table-body-item-control">
                    <input
                        id="bulkCancelNowDropzone"
                        type="checkbox"
                        class="checkbox-template"
                        v-model="bulkCancelNowDropzone"
                    />
                    <label for="cancelImmediatelyDropzone"
                        >Cancel immediately</label
                    >
                </div>

                <div class="modal-footer">
                    <button
                        class="button button--white button--small"
                        @click.prevent="hideModal"
                    >
                        Cancel
                    </button>
                </div>
            </accordion-item>
        </accordion>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Accordion from '@/components/ui/Accordion.vue'
import AccordionItem from '@/components/ui/AccordionItem.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { captureReportableError } from '@/util/errorTracker';

const apiScheme = import.meta.env.VITE_API_SCHEME || 'http'
const apiHost = import.meta.env.VITE_API_HOST || 'localhost:4000'
const apiPath =
    import.meta.env.VITE_API_PATH === 'empty' || !import.meta.env.VITE_API_PATH
        ? ''
        : import.meta.env.VITE_API_PATH

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    data() {
        return {
            bulkCancelNowFilter: false,
            bulkCancelNowDropzone: false,
            selectedMailDomains: [],
            mailDomainsOptions: [],
            dropzoneOptions: {
                success: this.fileComplete,
                error: this.dropzoneError,
                sending: this.sendingBulkEvent,
                previewsContainer: false,
                url:
                    apiScheme +
                    '://' +
                    apiHost +
                    apiPath +
                    '/admin/bulk-delete-subscriptions-l',
                createImageThumbnails: false,
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize: 30,
                acceptedFiles: '.txt,.csv,text/plain,text/csv',
                withCredentials: true,
            },

            pickedUserRolesData: null,
            pickedAccessTypeList: null,
            pickedSubscribtionPlanList: null,
            pickedParentAgentList: null,
            pickedExpiryDateList: null,

            parentAgentList: ['Access Type 1', 'Access Type 2'],
            accessTypeList: ['Access Type 1', 'Access Type 2'],
            mailDomains: [
                { domain: 'mail.ru' },
                { domain: 'icloud.com' },
                { domain: 'icloud.com' },
            ],
            expiryDateList: [
                'Never',
                '2 weeks',
                '1 month',
                '3 months',
                '6 months',
                '12 months',
            ],
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
        addTag(newTag) {
            this.mailDomainsOptions.push(newTag)
            this.selectedMailDomains.push(newTag)
        },
        fileComplete(file, response) {
            this.$notify({
                group: 'auth',
                type: 'success',
                title: 'Success',
                text: 'Finished uploading and processing file',
            })
            if (response.success && response.data) {
                const jsonContent =
                    'data:text/json;charset=utf-8,' +
                    JSON.stringify(response.data)
                const data = encodeURI(jsonContent)
                const link = document.createElement('a')
                link.setAttribute('href', data)
                link.setAttribute('download', 'import-result.json')
                this.importCallback && this.importCallback()
                link.click()
            }
            this.hideModal()
        },
        dropzoneError(file, message, xhr) {
            if (xhr && xhr.statusText) {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text:
                        xhr.status + ' : ' + message.message
                            ? message.message
                            : xhr.statusText,
                })
            } else if (xhr && xhr.status !== 200 && message.message) {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Import error',
                    text: message.message,
                })
            }
            this.hideModal()
        },
        sendingBulkEvent(file, xhr, formData) {
            formData.append('now', this.bulkCancelNowDropzone)
        },
        handleFilterBulkDomain() {
            this.$api
                .post('/admin/bulk-delete-subscriptions-d', {
                    now: this.bulkCancelNowFilter,
                    domain: this.selectedMailDomains.join(', '),
                })
                .then((result) => {
                    this.$notify({
                        group: 'auth',
                        type: 'success',
                        title: 'Success',
                        text:
                            'Bulk operation completed successfully. Unsubscribed '+result.data.data.length+' users. List will be updated in some seconds.',
                    })
                    this.importCallback && this.importCallback()
                    this.hideModal()
                })
                .catch((err) => {
                    captureReportableError(err);

                    this.$notify({
                        group: 'auth',
                        type: 'error',
                        title: 'Error',
                        text: err.response.data.message
                            ? err.response.data.message
                            : err.response.message,
                    })
                })
        },
    },
    computed: {
        ...mapGetters([
            'modalWindowState',
            'userRolesList',
            'subscribtionPlanList',
        ]),
        userRolesData() {
            return this.userRolesList
        },
        computedSubscribtionPlanList() {
            return this.subscribtionPlanList
        },
        getTempAttachments() {
            return this.tempAttachments
        },
        getAttachments() {
            return this.attachments
        },
    },
    components: {
        Accordion,
        AccordionItem,
        Multiselect,
        vueDropzone: vue2Dropzone,
    },
}
</script>
