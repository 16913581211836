<template>
    <div class="user-article-list">
        <div class="subtitle-action-block">
            <h2 class="subtitle">
                Items
                <span>({{ files.length }})</span>
            </h2>
        </div>
        <div class="empty-library" v-if="isEmptyLibrary">
          <h2>It's a little empty here ...</h2>
        </div>
        <template v-if="!isEmptyLibrary">
            <div class="content-wrapper">
                <div class="table w-full">
                    <div class="flex bg-sch-gray-300 py-2">
                      <div class="pseudo-zotero-header-wrapper w-full">
                        <div class="pseudo-zotero-header-item flex w-full px-2">
                          <div class="flex items-center justify-center mr-4">
                              <div class="flex h-6 items-center">
                                <input aria-describedby="comments-description" v-model="isSelectedAll" type="checkbox" class="form-checkbox h-5 w-5 rounded border-gray-400 shadow-md text-sch-main-purple focus:ring-sch-main-purple" />
                              </div>
                          </div>
                          <div class="flex w-full md:w-[65%] font-bold items-center">
                              <h2 class="md:ml-8">Title<i class="fas ml-1 cursor-pointer" :class="sortIconTitle" @click.prevent="sortBy('title')"/></h2>
                          </div>
                          <div class="hidden md:flex w-[26%] font-bold items-center">
                              <h2>Author<i class="fas ml-1 cursor-pointer" :class="sortIconAuthor" @click.prevent="sortBy('creator')"/></h2>
                          </div>
                          <div class="hidden md:flex w-[9%] font-bold items-center justify-center">
                              <h2>Date<i class="fas ml-1 cursor-pointer" :class="sortIconDate" @click.prevent="sortBy('dateModified')"/></h2>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="table-body">
                        <template v-for="(file) in files">
                            <ZoteroItemsItem
                                :fileItem="file"
                                :key="file._id"
                                :selectedFiles = "selectedFiles"
                                @select-file="onSelectFile"
                            />
                        </template>
                        <div class="table-pagination">
                            <paginate
                                v-if="pageCount > 1"
                                v-model="localPageNumber"
                                :page-count="pageCount"
                                :click-handler="toPage"
                                :prev-text="'←'"
                                :next-text="'→'"
                                :container-class="'page-numbers'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>

import ZoteroItemsItem from './ZoteroItemsItem.vue'

export default {
  props: {
    files: {
      type: Array
    },
    itemsPerPage: {
      type: Number,
      require: true
    },
    itemsCount: {
      type: Number,
      require: true
    },
    pageNumber: {
      type: Number,
      default: 1
    },
    selectedFiles: {
      type: Object
    }
  },
  mounted() {
    this.localPageNumber = this.pageNumber;
  },
  data() {
    return {
      localPageNumber: 1,
      isSelectedAll : false,
      skipEvent: false,
      sortCol: "title",
      sortDir: "asc"
    }
  },
  methods: {
    toPage(page) {
      this.$emit('setpage', { page, sortBy:this.sortCol+":"+this.sortDir });
    },
    onSelectFile(file) {
      this.$emit("select-file", file);
    },
    sortBy(col) {
      this.sortDir = this.sortCol === col ? (this.sortDir === "asc" ? "desc" : "asc") : "asc";
      this.sortCol = col ;
      this.toPage(1);
    },
  },
  computed: {
    isEmptyLibrary() {
      return this.files.length === 0
    },
    pageCount() {
      return Math.ceil(this.itemsCount / this.itemsPerPage)
    },
    sortIconTitle() {
      return this.sortCol === "title" ?
        this.sortDir === "asc" ? "fa-sort-up" : "fa-sort-down"
        : "fa-sort";
    },
    sortIconAuthor() {
      return this.sortCol === "creator" ?
        this.sortDir === "asc" ? "fa-sort-up" : "fa-sort-down"
        : "fa-sort";
    },
    sortIconDate() {
      return this.sortCol === "dateModified" ?
        this.sortDir === "asc" ? "fa-sort-up" : "fa-sort-down"
        : "fa-sort";
    }
  },
  watch: {
    pageNumber(newValue) {
      this.localPageNumber = newValue;
    },
    files() {
      if(this.isSelectedAll) {
        this.isSelectedAll = false;
        this.skipEvent=true;
      }
    },
    isSelectedAll(selected) {
      if(!this.skipEvent) {
        this.$emit("select-files-all", {selected});
      }
      else {
        this.skipEvent = false;
      }
    }
  },
  components: {
      ZoteroItemsItem
  }
}
</script>
