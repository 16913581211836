<template>
    <div class="table-body-item-wrapper" style="flex-direction: column">
      <div class="flex px-2 py-1 w-full items-center rounded-lg flex-col md:flex-row">
        <div class="flex w-full md:w-[65%] items-center">
          <div class="flex items-center justify-center mr-4">
            <div class="flex h-6 items-center">
              <input aria-describedby="comments-description" v-model="isSelected" type="checkbox" class="form-checkbox h-5 w-5 rounded border-gray-400 shadow-md text-sch-main-purple focus:ring-sch-main-purple" />
            </div>
          </div>
          <div class="flex w-full items-center justify-start text-sm">
            <span><i class="hidden md:inline mr-4 text-sch-light-purple text-3xl fas " :class="getPreview(fileItem.title)"></i></span>
            <span class="text-left">{{ fileItem.title }}</span>
          </div>
        </div>
        <div class="flex w-full md:w-[35%] flex-col md:flex-row text-sm pl-9 md:pl-0">
          <div class="flex w-full md:w-2/3  items-start pl-3 pr-1">
            <span class="inline md:hidden  font-semibold mr-1">Author:</span>
            <span class="text-left">{{ fileItem.author }}</span>
          </div>
          <div class="flex w-full md:w-1/3 items-center justify-end text-sm">
            <span class="inline md:hidden font-semibold">Added:</span>{{fileAddedDate}}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {DTFormat} from "../../util/helpersItem";

export default {
    props: {
        fileItem: {
            type: Object,
            required: true
        },
        selectedFiles: {
          type: Object
        }
    },
    data() {
        return {
          isSelected: false
        }
    },
    mounted() {
      // eslint-disable-next-line no-prototype-builtins
      this.isSelected = this.selectedFiles.hasOwnProperty(this.fileItem._id);
    },
    methods: {
      getPreview(name) {
        const pdf = /(\.pdf)$/i;
        const dox = /(\.docx?)$/i;
        const ppt = /(\.pptx?)$/i;
        const txt = /(\.txt|\.html?)$/i;
        const epub = /(\.epub)$/i;
        const codez = /(\.tex|\.xml|\.rtf)$/i;
        if(pdf.exec(name)){
          return 'fa-file-pdf';
        }
        if(dox.exec(name)){
          return 'fa-file-word';
        }
        if(ppt.exec(name)){
          return 'fa-file-powerpoint';
        }
        if(txt.exec(name)){
          return 'fa-file-alt';
        }
        if(epub.exec(name)){
          return 'fa-file-archive';
        }
        if(codez.exec(name)){
          return 'fa-file-code';
        }
        return 'fa-file';
      },
    },
    computed: {
        fileAddedDate() {
            return DTFormat(this.fileItem.dateAdded,"DD/MM/YYYY");
        }
    },
    watch: {
      isSelected(newVal) {
        // eslint-disable-next-line no-prototype-builtins
        if(newVal && !this.selectedFiles.hasOwnProperty(this.fileItem._id) || !newVal && this.selectedFiles.hasOwnProperty(this.fileItem._id)) {
          this.$emit("select-file", {_id: this.fileItem._id, selected: newVal, item: newVal ? this.fileItem : undefined})
        }
      },
      selectedFiles() {
        // eslint-disable-next-line no-prototype-builtins
        if(this.isSelected && !this.selectedFiles.hasOwnProperty(this.fileItem._id)) {
          this.isSelected = false;
        }
        // eslint-disable-next-line no-prototype-builtins
        else if(!this.isSelected && this.selectedFiles.hasOwnProperty(this.fileItem._id) ) {
          this.isSelected = true;
        }
      }
    },
    components: {}
}
</script>
