<template>
    <div class="middle-bar">
        <div class="block">
            <div class="search-bar grow">
                <div class="label">
                    <input
                        class="input mx-4 md:hidden"
                        type="search"
                        v-model="searchQuery"
                        @keyup.enter="onPressedEnter"
                        @keyup.esc="clearFilterQuery"
                        placeholder="Search"
                    />
                    <input
                            class="input mx-4 hidden md:flex"
                            type="search"
                            v-model="searchQuery"
                            @keyup.enter="onPressedEnter"
                            @keyup.esc="clearFilterQuery"
                            placeholder="Search flashcards or libraries"
                        />
                    <div class="action-buttons">
                        <div
                            class="input-group-append"
                            style="cursor: pointer"
                            title="Clear search"
                        >
                            <div
                                id="id-search-cancel"
                                @click="clearFilterQuery"
                            >
                                <i class="fa fa-times text-base"></i>
                            </div>
                        </div>
                        <div
                            class="input-group-append"
                            style="cursor: pointer"
                            title="Search in library"
                        >
                            <div
                                id="id-folder-search"
                                @click="() => handleFilterQuery(searchQuery)"
                            >
                                <i class="fa fa-search text-base"></i>
                            </div>
                        </div>
                        <div
                            class="input-group-append"
                            style="cursor: pointer"
                            title="Internet search"
                        >
                            <div
                                id="id-internet-search"
                                @click="() => handleFilterQuery(searchQuery,'internet')"
                            >
                                <i class="fab fa-internet-explorer text-base"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!isSearchViewMode && !isAdminPanelView" class="view-bar">
                <template v-if="[null, undefined].includes(libraryItemId) && isNotDeletedLibrary && isNotFavouritesLibrary">
                    <button title="Reorder libraries"
                        v-if="reorderLibraryList"
                        :disabled="searchQuery !== ''"
                        class="button button--white"
                        @click="reorderLibraryList"
                    >
                        <i class="fa fa-exchange-alt" aria-hidden="true"></i>
                        <span>Reorder libraries</span>
                    </button>
                    <button title="Create new library"
                        class="button button--purple"
                        @click="createLibrary"
                        v-if="!isSharedView && isNotDeletedLibrary && isNotFavouritesLibrary"
                        :disabled="searchQuery !== ''"
                    >
                        <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        <span>Create library</span>
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        handleFilterQuery: {
            type: Function,
        },
        isSearchViewMode: {
            type: Boolean,
        },
        reorderLibraryList: {
            type: Function,
        },
        moveAndExportState: {
            type: Boolean,
        },
        toggleMoveAndExportState: {
            type: Function,
        },
        setMoveAndExportType: {
            type: Function,
        },
        manageSharingState: {
            type: Boolean,
        },
        toggleManageSharingState: {
            type: Function,
        },
        setManageSharingType: {
            type: Function,
        },
        hideManageSharingDropdown: {
            type: Function,
        },
        showManageSharingDropdown: {
            type: Function,
        },
        isSharedLibrary: {
            type: Boolean,
        },
        isAdminPanelView: {
            type: Boolean,
        },
        uploadDocumentCallback: {
            type: Function,
        },
        reloadArticlesCallback: {
            type: Function,
        },
        filterQuery: {
            type: String,
        },
        isSharedView: {
            type: Boolean,
        },
        allowEdit: {
            type: Boolean,
        },
        sharedFrom: {
            type: [String, Number],
        },
        activeTabType: {
            type: String,
        }
    },
    created() {
      if(this.filterQuery) {
        this.searchQuery = this.filterQuery;
        this.handleFilterQuery(this.searchQuery);
      }
    },
    mounted() {
      this.$root.$on('upload-document.open', this.handleOpenDocumentUpload);
      this.$root.$on('search.clear', this.clearFilterQuery);
    },
    beforeDestroy() {
      this.$root.$off('upload-document.open', this.handleOpenDocumentUpload);
      this.$root.$off('search.clear', this.clearFilterQuery);
    },
    data() {
      return {
        searchQuery: '',
        preUrl: ''
      }
    },
    computed: {
        ...mapGetters(['selectedLibraryItem']),
        libraryItemName() {
          return this.selectedLibraryItem.name
        },
        libraryItemId() {
          return (this.selectedLibraryItem)?this.selectedLibraryItem._id : null
        },
        isNotDeletedLibrary() {
          return this.$route.params.id?.toString() !== this.$trashLibId.toString();
        },
        isNotFavouritesLibrary() {
          return this.$route.params.id?.toString() !== this.$favouritesId.toString();
        },
    },
    methods: {
        ...mapActions(['handleModalWindow']),
      handleOpenDocumentUpload(url) {
        this.preUrl = url;
        this.$refs['btn-import-documents'] && this.$refs['btn-import-documents'].click();
        this.preUrl = '';
      },
        hideImportExportDropdown() {
          // Parameter! May be missed!
          if(this.toggleMoveAndExportState && typeof this.toggleMoveAndExportState === 'function') {
            this.toggleMoveAndExportState(false)
          }
        },
        clearFilterQuery() {
          this.searchQuery = ''
          this.handleFilterQuery(this.searchQuery)
        },
        createLibrary() {
            this.$api
                .post('/user/lib', {})
                .then((result) => {
                    this.$notify({
                        group: 'auth',
                        type: 'success',
                        title: 'Success',
                        text: `Library created successfully`,
                    })
                })
                .then((result) => {
                    this.$store.dispatch('LOAD_USER_INFO')
                })
              .catch((err) => {
                captureReportableError(err);

                this.$notify({
                  group: 'auth',
                  type: 'error',
                  title: 'Error',
                  text: err.response?.data?.message || "Unexpected error. Please try again later.",
                })
              })
        },
      onPressedEnter(event) {
        let type = 'local';
        if(event.shiftKey) {
          type = 'internet';
        }
        this.handleFilterQuery(this.searchQuery, type);
      }
    },
}
</script>
