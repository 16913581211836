<template>
    <div>
        <!-- // Header Bar START // -->
                    <div class="top-navigation h-[70px]">
                        <div class="block">
                            <div class="top-navigation--route flex items-center">
                                <div class="h-7 w-7"></div>
                                <h2 class="top-navigation--title whitespace-nowrap">
                                Reports List
                                </h2>
                            </div>
                            <div class="flex items-center space-x-2">
                            </div>
                        </div>
                    </div>
                    <!-- // Header Bar END //  -->
        <div class="users-list-wrapper">
            <div class="users-list-wrapper-heading">
                <div class="users-list-wrapper-heading-blocklist-email">
                    <p class="email">Name</p>
                </div>
                <div class="users-list-wrapper-heading-description">
                    <p class="description">Description</p>
                </div>
                <div class="users-list-wrapper-heading-empty"></div>
            </div>
            <div class="users-list-wrapper-body">
                <div class="users-list-item-wrapper" >
                    <div class="users-list-wrapper-body-item">
                        <p class="users-list-wrapper-body-item-blocklist-email">
                            Library - folders - documents
                        </p>
                        <p class="users-list-wrapper-body-item-description">

                        </p>
                        <div class="users-list-wrapper-body-item-button-action">
                            <span
                                @click="generateReport"
                            >
                                <i class="fas fa-play"title="Start report generation"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { captureReportableError } from '@/util/errorTracker';

export default {
    data() {
        return {
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
      ...mapMutations(['storeSetGlobalDocumentBusy']),
        generateReport() {
          this.storeSetGlobalDocumentBusy(true);
            this.$api({
                url: '/admin/report-lib-folder-docs',
                method: 'GET',
                responseType: 'arraybuffer',
                timeout: 1800000
                })
                .then((response) => {
                    const blobData = new Blob([response.data])
                    const url = window.URL.createObjectURL(blobData)
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', "report."+new Date().toISOString()+".zip")
                    document.body.appendChild(link)
                    link.click()
                  this.storeSetGlobalDocumentBusy(false);
                })
                .catch((err) => {
                  captureReportableError(err);

                  this.storeSetGlobalDocumentBusy(false);
                  this.$notify({
                      group: 'auth',
                      type: 'error',
                      title: 'Error',
                      text: err.response?.data?.message || err.message
                  });
                })
        }
    },
    components: {
    },
}
</script>
