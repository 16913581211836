<template>
    <b-modal
        hide-footer
        centered
        no-close-on-backdrop
        no-close-on-esc
        id="idZoteroPicker"
        class="pl-[250px] mx-4"
    >
      <template #modal-title>
        <div>
          <span>Zotero Picker</span>
          <button class="button button--green"
          @click="getPickedFiles"
          :disabled="!allowImport"
          >
            <i aria-hidden="true" class="fa fa-upload"></i>
            Import
          </button>
        </div>
      </template>
      <div class="zotero-modal-div">
        <ZoteroPicker
          :items-per-page="10"
          :userID="userID"
          :apiKey="apiKey"
          :needFiles="needFiles"
          @picked-files="onPickedFiles"
          @need-credentials="onNeedCredentials"
          @allow-import="setAllowImport"
        ></ZoteroPicker>
      </div>
    </b-modal>
</template>

<script>
import ZoteroPicker from "../zotero/ZoteroPicker.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      needFiles: false,
      allowImport: false
    }
  },
  methods: {
    ...mapActions(["handleModalWindow"]),
    getPickedFiles() {
      this.needFiles = true;
    },
    onPickedFiles(pickedData) {
      this.needFiles = false;
      this.$root.$emit("ZoteroPicked", pickedData);
      this.hideModal();
    },
    onNeedCredentials() {
      this.$bvModal.show("idEditZoteroCredentials");
      this.hideModal();
    },
    hideModal() {
      this.$bvModal.hide('idZoteroPicker')
    },
    setAllowImport(payload) {
      this.allowImport = payload;
    }
  },
  computed: {
    ...mapGetters(["storeUserInfo"]),
    userInfo() {
      return this.storeUserInfo;
    },
    userID() {
      return this.userInfo?.extra_data?.zotero?.userID || ""
    },
    apiKey() {
      return this.userInfo?.extra_data?.zotero?.apiKey || ""
    },
  },
  components: {
    ZoteroPicker
  }
}
</script>
