<!-- @format -->
<template>
  <div>
    <nav
      class="side-navbar sidebar-background fixed top-0 flex h-[100vh] w-[250px] flex-col justify-between bg-sch-main-purple px-6 py-0 text-white transition-all duration-300 lg:left-0 lg:overflow-y-auto"
      :class="{
        opened: !leftMenu,
        'left-0 z-[9999] overflow-y-auto': isSideBarOpen,
        'left-[-250px] z-[9998]': !isSideBarOpen,
      }"
    >
      <div class="flex grow flex-col">
        <div class="navbar-header flex items-center text-center" :class="{ 'opacity-100': isSideBarOpen }">
          <router-link to="/">
            <div class="logo px-0 py-5">
              <img src="/img/logo_white.svg" alt="Scholarcy Logo" class="w-full" />
            </div>
          </router-link>
          <span
            class="burger relative block max-lg:-mr-6 max-lg:-mt-3 max-lg:bg-sch-main-purple max-lg:px-[26px] max-lg:py-[26px] lg:hidden"
            :class="{ 'active right-0 h-[70px] bg-sch-main-purple': isSideBarOpen, 'right-[-68px]': !isSideBarOpen }"
            @click="() => handleSideBar()"
          >
            <i
              class="fas fa-th-list"
              :class="{ 'text-white': !isSideBarOpen, 'text-sch-accent-green': isSideBarOpen }"
            ></i>
          </span>
        </div>
        <div class="font-figtree-important flex grow flex-col">
          <ul class="side-navbar-list grow text-gray-300" :class="{ block: isSideBarOpen }">
            <li class="relative">
              <p class="text-left text-sm font-bold leading-5 text-white lg:text-base">LIBRARY</p>
              <ul class="side-navbar-list--sublist">
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <a
                    :href="articleSummarizerMyLibrariesPage"
                    class="title-sublist flex items-center whitespace-nowrap p-2 text-sm leading-5 lg:text-base"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    My Libraries
                  </a>
                </li>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <a
                    :href="articleSummarizerSharedLibrariesPage"
                    class="title-sublist flex items-center whitespace-nowrap p-2 text-sm leading-5 lg:text-base"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
                          clip-rule="evenodd"
                        />
                        <path
                          d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"
                        />
                      </svg>
                    </div>
                    Shared libraries
                  </a>
                </li>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <div class="title-sublist flex items-center p-2">
                    <div class="mr-3 flex w-5 justify-center">
                      <svg class="h-5 w-5" viewBox="0 0 12 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M5.02014 0.0520394L10.7952 1.60207L10.7952 1.602C11.6003 1.81694 12.0853 2.65202 11.8653 3.46203L10.0152 10.3622V4.10205C10.0152 2.71209 8.88521 1.58707 7.49518 1.58707H3.0351L3.1601 1.12706C3.26511 0.737052 3.51511 0.407046 3.87012 0.207042C4.22012 0.00203855 4.62513 -0.0529624 5.02014 0.0520394ZM1.50003 2.5898C0.671585 2.5898 0 3.26138 0 4.08982V12.5C0 13.3284 0.671585 14 1.50003 14H7.51013C8.33857 14 9.01016 13.3284 9.01016 12.5V4.08982C9.01016 3.26138 8.33857 2.5898 7.51013 2.5898H1.50003ZM2.89351 5.58985L4.50263 7.19897L6.11175 5.58985L7.20522 6.68333L5.58929 8.29926L7.20522 9.9152L6.11175 11.0087L4.50263 9.39956L2.89351 11.0087L1.80003 9.9152L3.41597 8.29926L1.80003 6.68333L2.89351 5.58985Z"
                        />
                      </svg>
                    </div>
                    <a
                      class="whitespace-nowrap text-sm leading-5 lg:text-base"
                      :href="articleSummarizerDeletedFlashcardsPage"
                      >Deleted flashcards</a
                    >
                  </div>
                </li>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <div class="title-sublist flex items-center p-2">
                    <div class="mr-3 flex w-5 justify-center">
                      <svg class="h-5 w-5" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 7C14 10.8769 10.8769 14 7 14C3.12308 14 0 10.8769 0 7C0 3.12308 3.12308 0 7 0C10.8769 0.0358735 14 3.15898 14 7ZM10.0153 5.74362L8.0769 5.60001L7.35895 3.80514C7.21534 3.48203 6.74876 3.48203 6.60514 3.80514L5.88719 5.60001L3.9487 5.74362C3.58972 5.7795 3.44611 6.21035 3.73334 6.46157L5.2051 7.71798L4.73838 9.58459C4.66663 9.94356 5.02561 10.2308 5.34857 10.0153L6.99983 9.01011L8.65108 10.0153C8.97419 10.1948 9.33316 9.94355 9.26127 9.58459L8.79479 7.71798L10.2666 6.46157C10.5179 6.24621 10.3743 5.77949 10.0153 5.74362Z"
                        />
                      </svg>
                    </div>
                    <a
                      class="whitespace-nowrap text-sm leading-5 lg:text-base"
                      :href="articleSummarizerFavouriteFlashcardsPage"
                      >Favourites</a
                    >
                  </div>
                </li>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <a
                    :href="articleSummarizerRecentFlashcardsPage"
                    class="title-sublist flex items-center whitespace-nowrap p-2 text-sm leading-5 lg:text-base"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="h-5 w-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                    Recent flashcards
                  </a>
                </li>
              </ul>
            </li>

            <div class="pt-4">
              <p class="text-left text-sm font-bold leading-5 text-white lg:text-base">ACCOUNT</p>
              <ul>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <a
                    :href="articleSummarizerProfilePage"
                    class="title-sublist flex items-center whitespace-nowrap p-2 text-sm leading-5 lg:text-base"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    Profile
                  </a>
                </li>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <a
                    :href="articleSummarizerSettingsPage"
                    class="title-sublist flex items-center whitespace-nowrap p-2 text-sm leading-5 lg:text-base"
                  >
                    <div class="mr-3 flex w-5 justify-center hover:text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                        <path
                          fill-rule="evenodd"
                          d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    Settings
                  </a>
                </li>
                <li class="relative my-1 hover:text-white" @click="() => handleSideBar()">
                  <a
                    :href="articleSummarizerSubscriptionPage"
                    class="title-sublist flex items-center whitespace-nowrap p-2 text-sm leading-5 lg:text-base"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                        <path
                          fillRule="evenodd"
                          d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    Subscription
                  </a>
                </li>
                <li
                  v-if="userRole === 'admin' || userRole === 'reseller' || userRole === 'agent'"
                  class="relative text-gray-300"
                >
                  <details>
                    <summary class="inline cursor-pointer">
                      <span
                        class="side-navbar-list--title mx-[-25px] my-0 flex items-center justify-start py-2 pl-4 text-sm leading-5 text-gray-300 transition duration-300 hover:text-white max-sm:py-[1.4vh] lg:text-base"
                      >
                        <div class="ml-4 mr-3 flex w-5 justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            class="h-5 w-5"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
                              clip-rule="evenodd"
                            />
                            <path
                              d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"
                            />
                          </svg>
                        </div>
                        Admin
                      </span>
                    </summary>
                    <ul>
                      <li class="hover:text-whitemy-1 relative" @click="() => handleSideBar()">
                        <router-link
                          to="/usersList"
                          class="title-sublist flex items-center whitespace-nowrap p-2 text-sm"
                          ><p class="flex items-center justify-center text-sm leading-5 lg:text-base">
                            Users list
                          </p></router-link
                        >
                      </li>

                      <li
                        v-if="userRole === 'admin'"
                        class="relative my-1 hover:text-white"
                        @click="() => handleSideBar()"
                      >
                        <router-link
                          to="/approvelist"
                          class="title-sublist flex items-center whitespace-nowrap p-2 text-sm"
                          ><p class="flex items-center justify-center text-sm leading-5 lg:text-base">
                            Approved list
                          </p></router-link
                        >
                      </li>
                      <li
                        v-if="userRole === 'admin'"
                        class="relative my-1 hover:text-white"
                        @click="() => handleSideBar()"
                      >
                        <router-link
                          to="/blocklist"
                          class="title-sublist flex items-center whitespace-nowrap p-2 text-sm"
                          ><p class="flex items-center justify-center text-sm leading-5 lg:text-base">
                            Blocked list
                          </p></router-link
                        >
                      </li>
                      <li
                        v-if="userRole === 'admin'"
                        class="relative my-1 hover:text-white"
                        @click="() => handleSideBar()"
                      >
                        <router-link to="/reports" class="title-sublist flex items-center whitespace-nowrap p-2 text-sm"
                          ><p class="flex items-center justify-center text-sm leading-5 lg:text-base">
                            Reports list
                          </p></router-link
                        >
                      </li>
                      <li
                        v-if="userInfo.role === 'admin'"
                        class="relative my-1 hover:text-white"
                        @click="() => handleSideBar()"
                      >
                        <router-link
                          to="/deadList"
                          class="title-sublist flex items-center whitespace-nowrap p-2 text-sm"
                          ><p class="flex items-center justify-center text-sm leading-5 lg:text-base">
                            Dead accounts
                          </p></router-link
                        >
                      </li>
                    </ul>
                  </details>
                </li>
              </ul>
            </div>
            <div class="pt-4">
              <p class="text-left text-sm font-bold leading-5 text-white lg:text-base">HELP</p>
              <ul>
                <li class="relative my-1 hover:text-white">
                  <a
                    href="https://help.scholarcy.com"
                    class="sidebar-external-link flex items-center p-2"
                    target="blank"
                    title="Scholarcy Help Centre"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg class="h-5 w-5" viewBox="0 0 16 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M2.28594 4.85582V7.99968C2.28594 8.24539 2.44307 8.46425 2.67676 8.54193L7.81951 10.2562C7.93665 10.295 8.06349 10.295 8.18063 10.2562L13.3234 8.54193C13.5571 8.46419 13.7142 8.24539 13.7142 7.99968V4.85582L15.6204 4.17643C15.8433 4.09703 15.9941 3.88846 15.9998 3.65185C16.0055 3.41585 15.865 3.19984 15.6467 3.1096L8.21827 0.0433031C8.07827 -0.0144344 7.92172 -0.0144344 7.78173 0.0433031L0.353319 3.1096C0.135053 3.1999 -0.00554628 3.41591 0.000167887 3.65185C0.00588205 3.88839 0.156706 4.09696 0.379558 4.17643L2.2858 4.85582H2.28594ZM12.5714 5.26265L8.1921 6.82376C8.06812 6.86775 7.93211 6.86775 7.80813 6.82376L3.42879 5.26265V7.58778L8.00012 9.11171L12.5715 7.58778L12.5714 5.26265Z"
                        />
                      </svg>
                    </div>
                    <p class="whitespace-nowrap text-sm leading-5 lg:text-base">Help centre</p>
                  </a>
                </li>
                <li class="relative my-1 hover:text-white">
                  <a
                    href="https://www.scholarcy.com/support"
                    class="sidebar-external-link flex items-center p-2"
                    target="blank"
                    title="Opens support form in new window"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg class="h-5 w-5" viewBox="0 0 14 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M0.602003 3.64321C2.58991 4.79652 4.576 5.95188 6.5602 7.10938C6.69372 7.19761 6.84602 7.24417 7.00123 7.24417C7.15635 7.24417 7.30866 7.19761 7.44217 7.10948C9.41706 5.95414 11.3947 4.80916 13.3752 3.67454C13.4311 3.63815 13.4915 3.6108 13.5543 3.59332C13.663 3.55144 13.7834 3.57492 13.8728 3.65563C13.9622 3.73624 14.008 3.86259 13.9939 3.98986V8.4022C14.009 9.78379 13.9959 9.16611 13.9547 9.54515C13.8752 10.1114 13.615 10.6267 13.222 10.9962C12.829 11.3657 12.3296 11.5645 11.8156 11.5561H2.21478C1.73035 11.568 1.25651 11.3981 0.86905 11.0737C0.48159 10.7492 0.203257 10.2889 0.0784743 9.76675C0.0282557 9.56325 0.0019156 9.35334 0 9.14222V4.04289C0 3.60254 0.246368 3.44021 0.602003 3.64321ZM12.9574 0.355187C13.3226 0.60554 13.6124 0.971994 13.7895 1.40737C13.8617 1.51919 13.8809 1.66302 13.8408 1.79344C13.8007 1.92375 13.7064 2.02429 13.5879 2.06311L7.27416 5.73225C7.11202 5.83594 6.91269 5.83594 6.75056 5.73225L0.355576 2.03188C0.252584 1.99763 0.170647 1.91011 0.135648 1.79677C0.10065 1.68332 0.116873 1.55809 0.179215 1.4604C0.345186 1.01407 0.629822 0.635031 0.994673 0.374313C1.35962 0.113674 1.78726 -0.0162293 2.22026 0.00216849H11.7399C12.1683 -0.0181614 12.5922 0.104834 12.9574 0.355187Z"
                        />
                      </svg>
                    </div>
                    <p class="whitespace-nowrap text-sm leading-5 lg:text-base">Support</p>
                  </a>
                </li>
              </ul>
            </div>
            <div class="pt-4">
              <p class="text-left text-sm font-bold leading-5 text-white lg:text-base">MORE</p>
              <ul>
                <li class="relative my-1 hover:text-white">
                  <a
                    :href="articleSummarizerHost"
                    class="sidebar-external-link flex items-center p-2"
                    title="Article Summarizer"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg class="h-5 w-5" viewBox="0 0 12 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10.0357 0.33897L11.5978 1.90107H11.5978C11.8188 2.12212 11.9367 2.40213 11.9367 2.71159L11.9368 9.66734C11.9368 10.301 11.421 10.8168 10.7873 10.8168H4.3326C3.69889 10.8168 3.18311 10.301 3.18311 9.66734V1.14949C3.18311 0.515787 3.69889 0 4.3326 0H9.2252C9.51994 0 9.81467 0.117919 10.0357 0.33897ZM5.34945 8.78314H5.45259C5.7326 8.78314 5.9831 8.54736 5.9831 8.25263C5.9831 7.95789 5.74733 7.72211 5.45259 7.72211H5.34945C5.05472 7.72211 4.81894 7.95789 4.81894 8.25263C4.81894 8.54736 5.05472 8.78314 5.34945 8.78314ZM5.34945 6.4842H5.45259C5.7326 6.4842 5.9831 6.24843 5.9831 5.95369C5.9831 5.65896 5.74733 5.42318 5.45259 5.42318H5.34945C5.05472 5.42318 4.81894 5.65896 4.81894 5.95369C4.81894 6.24843 5.05472 6.4842 5.34945 6.4842ZM5.34945 4.18527H5.45259C5.7326 4.18527 5.9831 3.94949 5.9831 3.65475C5.9831 3.36002 5.74733 3.12424 5.45259 3.12424H5.34945C5.05472 3.12424 4.81894 3.36002 4.81894 3.65475C4.81894 3.94949 5.05472 4.18527 5.34945 4.18527ZM7.19153 8.78314H9.77046C10.0652 8.78314 10.301 8.54736 10.301 8.25263C10.301 7.95789 10.0652 7.72211 9.77046 7.72211H7.19153C6.89679 7.72211 6.66102 7.95789 6.66102 8.25263C6.66102 8.54736 6.89679 8.78314 7.19153 8.78314ZM7.19153 6.4842H9.77046C10.0652 6.4842 10.301 6.24843 10.301 5.95369C10.301 5.65896 10.0652 5.42318 9.77046 5.42318H7.19153C6.89679 5.42318 6.66102 5.65896 6.66102 5.95369C6.66102 6.24843 6.89679 6.4842 7.19153 6.4842ZM7.19153 4.18527H9.77046C10.0652 4.18527 10.301 3.94949 10.301 3.65475C10.301 3.36002 10.0652 3.12424 9.77046 3.12424H7.19153C6.89679 3.12424 6.66102 3.36002 6.66102 3.65475C6.66102 3.94949 6.89679 4.18527 7.19153 4.18527ZM2.12211 3.18311V9.66728L2.12214 9.66732C2.12214 10.8904 3.10945 11.8778 4.33266 11.8778H8.75369V12.8504C8.75369 13.4842 8.2379 13.9999 7.60419 13.9999H1.14949C0.515787 13.9999 0 13.4842 0 12.8504V4.3326C0 3.69889 0.515787 3.18311 1.14949 3.18311H2.12211Z"
                        />
                      </svg>
                    </div>
                    <p class="whitespace-nowrap text-sm leading-5 lg:text-base">Article Summarizer</p>
                  </a>
                </li>
                <li class="relative my-1 hover:text-white">
                  <a
                    :href="articleSummarizerBrowserExtensions"
                    class="sidebar-external-link flex items-center p-2"
                    title="Browser extensions"
                  >
                    <div class="mr-3 flex w-5 justify-center">
                      <svg class="h-5 w-5" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M7.00003 0.000143242C4.68799 0.000143242 2.64058 1.11784 1.36662 2.84245L3.52877 6.58744C3.62923 5.74476 4.03247 4.96724 4.66345 4.39963C5.29444 3.83206 6.11014 3.51309 6.95881 3.50206C6.97254 3.50056 6.98638 3.49991 7.00016 3.50001H13.0632C11.8539 1.40742 9.59427 0 7.00016 0L7.00003 0.000143242ZM0.937224 3.49938C0.342134 4.52895 0 5.72376 0 7.00003C0 10.6054 2.71628 13.5677 6.2168 13.9563L8.37561 10.2174V10.2174C7.94088 10.4036 7.47287 10.4998 6.99989 10.5001C6.39502 10.4993 5.80074 10.3417 5.27484 10.0428C4.74903 9.74391 4.30964 9.3138 3.99954 8.79451C3.98811 8.78053 3.97783 8.76569 3.96876 8.7501L0.937039 3.49951L0.937224 3.49938ZM7.00003 4.20005C6.25741 4.20005 5.54525 4.49506 5.02012 5.02017C4.49501 5.54523 4.2 6.25742 4.2 7.00008C4.2 7.49155 4.32938 7.9744 4.57516 8.40006C4.82089 8.82571 5.17436 9.17917 5.60001 9.42491C6.02566 9.67064 6.50846 9.80002 6.99999 9.80002C7.49151 9.80002 7.97431 9.67064 8.39996 9.42491C8.82562 9.17917 9.17908 8.82571 9.42481 8.40006C9.6706 7.9744 9.79998 7.49155 9.79998 7.00008C9.79998 6.50855 9.6706 6.0257 9.42481 5.6001C9.17908 5.17445 8.82562 4.82098 8.39996 4.57525C7.97431 4.32952 7.49151 4.20014 6.99999 4.20014L7.00003 4.20005ZM9.09428 4.20005C9.53001 4.52544 9.88392 4.94793 10.128 5.43394C10.372 5.92 10.4994 6.45618 10.5001 7.00011C10.4987 7.59385 10.3462 8.17745 10.0571 8.69602C10.0501 8.71471 10.0415 8.73281 10.0315 8.75006L7.0005 14C10.8704 13.9998 14.0001 10.87 14.0001 7.00012C14.0001 6.00394 13.7917 5.05742 13.4177 4.20016L9.09428 4.20005Z"
                        />
                      </svg>
                    </div>
                    <p class="whitespace-nowrap text-sm leading-5 lg:text-base">Browser Extensions</p>
                  </a>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </div>
      <div class="mb-6 mt-6 lg:mb-0">
        <a class="logout flex items-center py-2 pr-2 text-sm text-gray-300" href="#" @click="logout()">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="mr-2 h-6 w-6"
          >
            <path
              d="M9.54296 9.33494C9.37422 9.33494 9.2124 9.40198 9.09311 9.52128C8.97383 9.64057 8.90677 9.8024 8.90677 9.97113V11.2435C8.90677 11.4123 8.83972 11.5741 8.72043 11.6934C8.60114 11.8127 8.43931 11.8797 8.27058 11.8797H5.08961V2.973H8.27058C8.43932 2.973 8.60114 3.04004 8.72043 3.15934C8.83972 3.27863 8.90677 3.44046 8.90677 3.60919V4.88158C8.90677 5.10886 9.02804 5.31891 9.22487 5.43253C9.42169 5.5462 9.66424 5.5462 9.86106 5.43253C10.0579 5.31891 10.1792 5.10886 10.1792 4.88158V3.60919C10.1792 3.10302 9.97806 2.61752 9.62015 2.25957C9.26224 1.90162 8.77675 1.70056 8.27053 1.70056H5.01957C4.92777 1.41581 4.73855 1.17257 4.48517 1.01347C4.11125 0.782504 3.64464 0.760873 3.25096 0.95621L1.05609 2.05046C0.738243 2.20917 0.47094 2.4534 0.284357 2.7558C0.0977739 3.05814 -0.000683518 3.40656 3.5717e-06 3.76183V11.5997C0.000749115 11.9995 0.127044 12.3891 0.361044 12.7132C0.595049 13.0374 0.924966 13.2799 1.3042 13.4065L3.41637 14.1127C3.54549 14.156 3.68098 14.1775 3.81717 14.1763C4.08333 14.1745 4.34273 14.0925 4.56151 13.9409C4.83269 13.7559 5.02192 13.4733 5.08956 13.152H8.27053C8.77669 13.152 9.2622 12.9509 9.62015 12.593C9.9781 12.2351 10.1792 11.7496 10.1792 11.2434V9.97103C10.1792 9.80228 10.1121 9.64046 9.99282 9.52118C9.87352 9.40189 9.7117 9.33483 9.54296 9.33483V9.33494Z"
            />
            <path
              d="M13.8119 6.97463L12.5395 5.70225C12.3781 5.54086 12.1429 5.47784 11.9225 5.53694C11.702 5.59598 11.5298 5.76816 11.4708 5.98864C11.4117 6.20906 11.4747 6.44427 11.6361 6.60564L11.8269 6.79014H6.99824C6.77095 6.79014 6.5609 6.91141 6.44728 7.10824C6.33361 7.30506 6.33361 7.5476 6.44728 7.74443C6.5609 7.94126 6.77095 8.06253 6.99824 8.06253H11.8269L11.6361 8.24702C11.5157 8.36646 11.4479 8.52909 11.4479 8.69872C11.4479 8.86836 11.5157 9.03098 11.6361 9.15042C11.7555 9.27085 11.9182 9.33859 12.0878 9.33859C12.2574 9.33859 12.42 9.27085 12.5395 9.15042L13.8119 7.87803C13.9323 7.75859 14 7.59597 14 7.42633C14 7.2567 13.9323 7.09407 13.8119 6.97463Z"
            />
          </svg>

          <p class="whitespace-nowrap font-figtree text-lg">Sign out</p>
        </a>
      </div>
      <SideSlideoutPanel></SideSlideoutPanel>
    </nav>
    <div @click="handleSideBar" v-if="isSideBarOpen" class="drawer"></div>
  </div>
</template>

<script>
import SideSlideoutPanel from "@/components/SideSlideoutPanel.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SideBar",
  data() {
    return {
      articleSummarizerHost: import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST,
      articleSummarizerMyLibrariesPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/my-libraries`,
      articleSummarizerSharedLibrariesPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/libraries/shared`,
      articleSummarizerSubscriptionPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/subscription`,
      articleSummarizerProfilePage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/profile`,
      articleSummarizerSettingsPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/settings`,
      articleSummarizerRecentFlashcardsPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/flashcards/recent`,
      articleSummarizerFavouriteFlashcardsPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/flashcards/favourites`,
      articleSummarizerDeletedFlashcardsPage: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/flashcards/deleted`,
      articleSummarizerBrowserExtensions: `${import.meta.env.VITE_ARTICLE_SUMMARIZER_HOST}/browser-extensions`,
      trashLib: { _id: this.$trashLibId, name: "Trash" },
      libSelected: null,
      containerBehaviour: "move",
      loading: false,
      libList: [],
      isActive: true,
      sidebar: {
        Dashboard: {
          ref: "#dashvariants",
          icon: " ti-desktop ",
          name: "Dashboard",
          id: "dashvariants",
          group: [
            {
              path: "analytics",
              text: "Analytics",
            },
            {
              path: "ecommerce",
              text: "Ecommerce",
            },
          ],
        },
      },
    };
  },
  methods: {
    ...mapActions(["handleModalWindow", "handleSideBar"]),
    ...mapGetters(["getOpenedArticleContent"]),
    ...mapMutations(["LOGOUT2_USER"]),
    logout() {
      // "google" may be undefined, or incomplete due to the China Great Firewall or extensions.
      if (typeof google !== "undefined") {
        google?.accounts?.id?.disableAutoSelect();
      }
      this.handleSideBar();
      this.$api.post(`${this.articleSummarizerHost}/x/logout`).then(() => {
        this.LOGOUT2_USER(true);
        this.$router.push("/login");
      });
    },
  },
  computed: {
    ...mapGetters(["sideBarState", "userLibraryList", "storeUserInfo", "storeLeftMenu"]),
    leftMenu() {
      return this.storeLeftMenu;
    },
    userInfo() {
      return this.storeUserInfo;
    },
    userRole() {
      return this.userInfo && this.userInfo.role ? this.userInfo.role : "";
    },
    isSideBarOpen() {
      return this.sideBarState.isOpen;
    },
  },
  components: { SideSlideoutPanel },
};
</script>
