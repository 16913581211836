<template>
  <div class="zotero-picker">
    <BusyPanel :bShowLoadingSpinner="bShowLoadingSpinner"/>

    <div class="middle-bar">
      <div class="block">
        <div class="search-bar grow">
          <div class="label">
            <input
              class="input"
              type="search"
              v-model="searchText"
              @keyup.enter="handleSearch"
              @keyup.esc="clearSearch"
              placeholder="Search items"
            />
            <div class="action-buttons">
              <div
                class="input-group-append"
                style="cursor: pointer"
                title="Clear search"
              >
                <div
                  id="id-search-cancel"
                  @click="clearSearch"
                >
                  <i class="fa fa-times"></i>
                </div>
              </div>
              <div
                class="input-group-append"
                style="cursor: pointer"
                title="Search in library"
              >
                <div
                  id="id-folder-search"
                  @click="handleSearch"
                >
                  <i class="fa fa-search"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inside-library-item">
      <div class="libraryList">
        <ZoteroGroupList v-if="groups.length > 1"
          :groups="groups"
          :selectedGroup="selectedGroup"
          @change-group="loadGroupData"
        >
        </ZoteroGroupList>
        <ZoteroCollectionList v-if="collections.length > 0 && bShowCollections"
          :collections="collections"
          :parentCollectionId="parentIds.length > 2 && parentIds.at(-2) || ''"
          libraryItemId="-2"
          :currentName="currentCollectionName"
          @change-collection="loadCollectionData"
        >
        </ZoteroCollectionList>
        <ZoteroItemsList
          :files="files"
          :itemsPerPage="itemsPerPage"
          :itemsCount="totalItemsCount"
          :pageNumber="pageNumber"
          :selectedFiles="selectedFiles"
          @setpage="paginatorSetPage"
          @select-file="onSelectFile"
          @select-files-all="onSelectFilesAll"
        >
        </ZoteroItemsList>
      </div>
    </div>
  </div>
</template>

<script>
import { ZoteroAPI } from "../../util/helperZoteroAPI"
import BusyPanel from '../ui/ParentBlockBusyPanel.vue';
import ZoteroGroupList from "./ZoteroGroupList.vue";
import ZoteroCollectionList from "./ZoteroCollectionList.vue";
import ZoteroItemsList from "./ZoteroItemsList.vue";
import { captureReportableError } from '@/util/errorTracker';

export default {
  name: "ZoteroPicker",
  props: {
    itemsPerPage : {
      type: Number,
      default: 10
    },
    userID: {
      type: String
    },
    apiKey: {
      type: String
    },
    needFiles: {
      type: Boolean
    }
  },
  data() {
    return {
      api: null,
      groups: [], // full groups list includes "virtual" group "My Library"
      collections: [], // full collections list on level
      files: [], // full files list on level with pagination
      currentCollectionId: null, // ID of the selected collection
      currentCollectionName: "", // Title of the selected collection
      parentIds: [], // list of collections IDs
      bShowLoadingSpinner: true, // show / hide loader
      bShowCollections: true,
      pageNumber: 1, // current page number
      searchText:"",
      cntItems: {},
      totalItemsCount: -1, // Total count items by request
      currentMeta: {},
      selectedFiles: {},
      selectedGroup: {}
    }
  },
  async mounted() {
    this.api = new ZoteroAPI();
    this.setAllowImport(false);
    try {
      // load all groups from API
      await this.initGroupList();

      // load all collections and files from the TOP level library from API
      await this.loadCollectionData(null, true);
    }
    catch(e) {
      console.error(e.message);
      this.bShowLoadingSpinner = false;
    }
  },
  watch: {
    async needFiles(newVal) {
      if(newVal) {
        await this.collectPickedFiles();
      }
    }
  },
  methods: {
    async collectPickedFiles() {
      this.bShowLoadingSpinner = true;
      const filesForImport = [];
      for(const file of Object.values(this.selectedFiles)) {
        let needStub=true;
        if(file?.meta?.numChildren > 0) {
          // handling "container" item type: "journal", "book" and so on
          const res = await this.api.getAttachments(this.selectedGroup?._id, file._id);
          if (res.list?.length > 0) {
            // Add to each attachment basic data from parent Item. Preparing to create a basic
            // Scholarcy document in case of some error with Extract API
            for (const attachment of res.list) {
              const parentData = {
                author: file.author || "",
                date: file.date,
                abstract: file.abstract,
                title: file.title || ""
              }
              attachment.parentData = parentData;
              filesForImport.push(attachment);
            }
            needStub = false;
          }
        }
        else if(file?.itemType === "attachment" && file?.linkMode === "imported_file") {
          // handle simple file item type: "attachment" + "imported_file"
          filesForImport.push(file);
          needStub = false;
        }
        if(needStub) {
          // for some types we have numChildren:1 and no attachments but data.url is not empty
          // we handle it as "attachment"
          if(file.docUrl) {
            file.url = file.docUrl;
            delete file.docUrl;
            delete file.doiUrl;
            filesForImport.push(file);
          }
          else if(file.doiUrl) {
            file.url = file.doiUrl;
            delete file.docUrl;
            delete file.doiUrl;
            filesForImport.push(file);
          }
          else {
            // if data.url is empty - we try to create a flashcard manually
            const newItem = {
              _id: file._id,
              itemType: "manual_created",
              data: {
                filename: file.filename || file.title || undefined,
                metadata: {
                  title: file.title || "Zotero untitled",
                  abstract : file.abstract || "",
                  date: file.meta?.parsedDate || "",
                  author: file.meta?.creatorSummary || ""
                }
              }
            }
            filesForImport.push(newItem);
          }
        }
        // skip unknown file types for avoid any issues. I have tested all, but maybe :-)
      }
      this.bShowLoadingSpinner = false;
      this.selectedFiles = {};
      this.$emit("picked-files", {items: filesForImport, apiKey:this.apiKey});
    },
    async initGroupList() {
      this.bShowLoadingSpinner = true;
      this.selectedGroup = {_id: 0, name: "My Library"};
      this.groups.push(this.selectedGroup);
      const data = await this.api.getGroups();
      if(data.list.length > 0) {
        for(const group of data.list) {
          this.groups.push(group);
        }
      }
    },
    async loadGroupData(payload) {
      this.selectedGroup = payload;
      this.loadCollectionData();
    },
    async loadCollectionData(payload=null, init=false) {
      this.bShowLoadingSpinner = true;
      this.currentCollectionName = payload?.name || ""
      try {
        const data = await this.api.getCollections(this.selectedGroup?._id, payload?._id || null);
        this.currentCollectionId = payload?._id || null;
        if (payload?.name === "...") {
          this.parentIds.pop();
        }
        if (this.currentCollectionId) {
          if (payload && payload.name !== "...") {
            this.currentCollectionId && this.parentIds.push(this.currentCollectionId);
          }
          data.list.unshift({_id: this.parentIds.length > 1 && this.parentIds.at(-2), name: "...", link: ""});
        }
        this.collections = data.list;
        await this.paginatorSetPage(1);
        this.totalItemsCount = this.currentMeta["total-results"] && Number.parseInt(this.currentMeta["total-results"]) || this.files.length;
      }
      catch (e) {
        captureReportableError(e);

        this.$notify({
          group: 'auth',
          type: 'error',
          title: 'Error',
          text: e.response?.data?.message || e.message
        });
        if(init) {
          this.$emit("need-credentials");
        }
      }
      this.bShowLoadingSpinner = false;
    },
    async paginatorSetPage(data) {
      const page = data?.page || 1;
      const sort = data?.sortBy || "title:asc";
      this.bShowLoadingSpinner = true;
      this.pageNumber = page;
      const startIndex = (this.pageNumber-1)*this.itemsPerPage;
      const collection = this.searchText === "" ? this.currentCollectionId : null;
      const response = await this.api.getItems(this.selectedGroup?._id, collection,this.searchText, startIndex, this.itemsPerPage, sort);
      this.currentMeta = response.meta;
      this.files = response.list;
      this.bShowLoadingSpinner = false;
    },
    onSelectFile(file) {
      if(file.selected) {
        this.selectedFiles[file._id] = file.item;
      }
      else {
        delete this.selectedFiles[file._id];
      }
      this.setAllowImport(Object.keys(this.selectedFiles).length > 0);
    },
    onSelectFilesAll(data) {
      const _t = {...this.selectedFiles }
      if(data.selected) {
        for(const file of this.files) {
          // eslint-disable-next-line no-prototype-builtins
          if(!_t.hasOwnProperty(file._id)) {
            _t[file._id] = file;
          }
        }
      }
      else {
        for(const file of this.files) {
          // eslint-disable-next-line no-prototype-builtins
          if(_t.hasOwnProperty(file._id)) {
            delete _t[file._id]
          }
        }
      }
      this.selectedFiles = _t;
      this.setAllowImport(Object.keys(this.selectedFiles).length > 0);
    },
    async handleSearch() {
      this.bShowCollections = this.searchText === "";
      try {
        await this.paginatorSetPage(1);
        this.totalItemsCount = this.currentMeta["total-results"] && Number.parseInt(this.currentMeta["total-results"]) || this.files.length;
      }
      catch (e) {
        captureReportableError(e);

        this.$notify({
          group: 'auth',
          type: 'error',
          title: 'Error',
          text: e.response?.data?.message || e.message
        })
      }
      this.bShowLoadingSpinner = false;
    },
    async clearSearch() {
      this.searchText="";
      this.bShowCollections = false;
      await this.handleSearch();
    },
    setAllowImport(allow) {
      this.$emit("allow-import", allow);
    }
  },
  components: {
    ZoteroGroupList,
    ZoteroCollectionList,
    ZoteroItemsList,
    BusyPanel
  }
}
</script>
