<template>
    <div>
        <form>
            <div class="form-group">
                <label for="">Operation</label>
                <multiselect
                    v-model="selectImportType"
                    label="name"
                    :options="operationOptionList"
                    :searchable="false"
                    :show-labels="false"
                >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </multiselect>
            </div>
            <div class="form-group">
                <label for="">If user exists</label>
                <multiselect
                    v-model="ifExist"
                    label="name"
                    :options="userExistOptionList"
                    :searchable="false"
                    :show-labels="false"
                >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </multiselect>
            </div>
            <div class="form-group">
                <label for="">Pricing plan</label>
                <multiselect
                    v-model="pricingPlan"
                    label="name"
                    :options="pricingPlanOptionList"
                    :searchable="false"
                    :show-labels="false"
                >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </multiselect>
            </div>
            <div class="form-group">
                <label for="">Parent agent</label>
                <multiselect
                    v-model="parentAgent"
                    :options="parentAgentOptionList"
                    label="email"
                    :searchable="false"
                    :show-labels="false"
                >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </multiselect>
            </div>

            <vue-dropzone
                ref="myVueDropzone"
                id="id-documents-dropzone"
                class="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                :include-styling="false"
            >
                <div class="dropzone-custom-content">
                    <i class="fa fa-inbox" aria-hidden="true"></i>
                    <p class="drag-title">
                        Click or drag file to this area to upload
                    </p>
                    <p class="drag-subtitle">
                        Support for a single or bulk upload
                    </p>
                </div>
            </vue-dropzone>
        </form>
        <div class="modal-footer">
            <button
                class="button button--white button--small"
                @click.prevent="hideModal"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const apiScheme = import.meta.env.VITE_API_SCHEME || 'http'
const apiHost = import.meta.env.VITE_API_HOST || 'localhost:4000'
const apiPath =
    import.meta.env.VITE_API_PATH === 'empty' || !import.meta.env.VITE_API_PATH
        ? ''
        : import.meta.env.VITE_API_PATH


export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    data() {
        return {
            selectImportType: { name: 'Import users', value: 'user-import' },
            operationOptionList: [
                { name: 'Import users', value: 'user-import' },
                { name: 'Attach users to an agent', value: 'user-attach' },
            ],
            ifExist: { name: 'Update', value: 'update' },
            userExistOptionList: [
                { name: 'Skip', value: 'skip' },
                { name: 'Update', value: 'update' },
            ],
            pricingPlan: null,
            pricingPlanOptionList: [],
            parentAgent: null,
            parentAgentOptionList: [],

            url: null,
            dropzoneOptions: {
                success: this.fileComplete,
                error: this.dropzoneError,
                sending: this.sendingEvent,
                previewsContainer: false,
                url:
                    apiScheme +
                    '://' +
                    apiHost +
                    apiPath +
                    '/user/users-import',
                withCredentials: true,
                createImageThumbnails: false,
                timeout: 1800000,
                maxFiles: 1,
                parallelUploads: 1,
                maxFilesize: 30,
                acceptedFiles:
                    '.json,.txt,.csv,application/json,text/plain,text/csv',
            },
        }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
      ...mapMutations(['storeSetGlobalDocumentBusy']),
        fileComplete(file, response) {
            this.$notify({
                group: 'auth',
                type: 'success',
                title: 'Success',
                text: 'Finished uploading and processing file',
            })
            if (response.success && response.data) {
                const aCsv = ["User email,Result,New password,Parent,Comment"];
                for(const row of response.data) {
                    aCsv.push(`${row.user_email},${row.result},${row.user_password || ""},${row.parent || ""},${row.comment || ""}`);
                }
                const csvContent = `data:text/csv;charset=utf-8,${aCsv.join("\r\n")}`;
                const data = encodeURI(csvContent)
                const link = document.createElement('a')
                link.setAttribute('href', data)
                link.setAttribute('download', 'import-result.csv')
                this.importCallback && this.importCallback()
              this.storeSetGlobalDocumentBusy(false);
                link.click()
            }
            this.hideModal()
        },
        dropzoneError(file, message, xhr) {
            if (xhr && xhr.statusText) {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Error',
                    text:
                        xhr.status + ' : ' + message.message
                            ? message.message
                            : xhr.statusText,
                })
            } else if (xhr && xhr.status !== 200 && message.message) {
                this.$notify({
                    group: 'auth',
                    type: 'error',
                    title: 'Import error',
                    text: message.message,
                })
            }
          this.storeSetGlobalDocumentBusy(false);
            this.hideModal()
        },
        sendingEvent(file, xhr, formData) {
          this.storeSetGlobalDocumentBusy(true);
            formData.append('import_type', this.selectImportType.value)
            formData.append('if_exist', this.ifExist.value)
            formData.append('pricing_plan', this.pricingPlan.value)
            if (this.parentAgent) {
                formData.append('parent_user', this.parentAgent._id)
            }
        },
        getPricingPlansList() {
            this.$api.get('/admin/pricing-plans').then((list) => {
                this.pricingPlan = {
                    value: list.data.institution_annual.new,
                    name: 'Annual Institutional Subscription',
                }
                this.pricingPlanOptionList = [
                    {
                        value: list.data.institution.new,
                        name: 'Monthly Institutional Subscription',
                    },
                    {
                        value: list.data.institution_annual.new,
                        name: 'Annual Institutional Subscription'
                    }
                ]
            })
        },
    },
    computed: {
        ...mapGetters(['modalWindowState', 'userLibraryList', 'storeUserInfo']),
        userInfo() {
            return this.storeUserInfo;
        },
        libraryItemId() {
            return (
                this.modalWindowState.config &&
                this.modalWindowState.config.libraryItemId
            )
        },
        importCallback() {
            return this.modalWindowState.config.importCallback
        },
    },
    mounted() {
      if (this.userInfo.role === 'admin' || this.userInfo.role === 'agent') {
            this.getPricingPlansList()
        }
        if (this.modalWindowState.config.parentsList) {
            this.parentAgentOptionList = this.modalWindowState.config.parentsList
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
        Multiselect,
    },
    watch: {},
}
</script>
