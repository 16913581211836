<template>
    <div class="middle-bar mt-8">
        <div class="block">
            <div class="middle-bar-title">
                <h2 v-if="isUserList">Roles</h2>
                <h2 v-if="isApproveList">Domain</h2>
                <h2 v-if="isBlockList">Emails</h2>
                <multiselect
                    v-if="isUserList"
                    v-model="pickedUserRoles"
                    :options="userRolesData"
                    :searchable="false"
                    :show-labels="false"
                    style="min-width: 10rem!important;"
                >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                </multiselect>
            </div>
            <template v-if="isUserList">
                <div class="view-bar">
                  <button title="Invoices"
                          @click="
                            () =>
                                handleModalWindow({
                                    type: 'ImportInvoices',
                                    config: {}
                                })
                        "
                          class="button button--white button--small"
                  >
                    <i class="fas fa-sync"></i>
                    <span>Invoices</span>
                  </button>
                    <div class="custom-dropdown">
                        <div class="dropdown-select">
                            <button title="Import/export"
                                class="button button--white"
                                @click="showImportExportDropdown"
                                v-click-outside="hideImportExportDropdown"
                            >
                                <i class="fas fa-file-export"></i>
                                <span>Import/Export</span>
                            </button>
                        </div>
                        <div
                            v-if="importAndExportState"
                            class="dropdown-options"
                        >
                            <div>
                                <button
                                    @click="
                                        () => {
                                            this.hideImportExportDropdown()
                                            this.handleModalWindow({
                                                type: 'ImportUsers',
                                                config: {
                                                    parentsList:
                                                        parentsList.agent,
                                                    importCallback: fetchUserCallback
                                                }
                                            })
                                        }
                                    "
                                    class="button button--white"
                                >
                                    Import users
                                </button>
                            </div>
                            <div>
                                <button
                                    @click="() => { 
                                        this.hideImportExportDropdown()
                                        this.toggleExportUserState()
                                    }"
                                    class="button button--white"
                                >
                                    Export users
                                </button>
                            </div>
                        </div>
                    </div>
                    <button title="Bulk unsubscribe"
                        @click="
                            () =>
                                handleModalWindow({
                                    type: 'BulkUnsubscribe',
                                    config: {
                                        importCallback: fetchUserCallback
                                    }
                                })
                        "
                        class="button button--white"
                    >
                        <i class="fas fa-user-times"></i>
                        <span>Bulk unsubscribe</span>
                    </button>
                    <button title="Add user"
                        @click="
                            () =>
                                handleModalWindow({
                                    type: 'AddUser',
                                    config: {
                                        fetchUserCallback: fetchUserCallback,
                                        parentsList
                                    }
                                })
                        "
                        class="button button--green button--small"
                    >
                        <i class="fas fa-plus-circle"></i>
                        <span>Add user</span>
                    </button>
                </div>
            </template>
            <template v-if="isDeadList">
                <div class="view-bar">
                  <multiselect
                      v-if="isDeadList"
                      v-model="pickedDeadSelected"
                      :options="deadSelectedData"
                      :searchable="false"
                      :show-labels="false"
                      label="name"
                      style="width: 9rem!important;"
                      title="Selected / Unselected / Any accounts"
                  >
                    <span
                        slot="caret"
                        slot-scope="{ toggle }"
                        class="arrow"
                        @mousedown.prevent.stop="toggle"
                    >
                        <i class="fa fa-chevron-down"></i>
                    </span>
                  </multiselect>

                  <button :title="`Calculate dead accounts${deadParams.createdAt?'. Latest at: '+deadParams.createdAt:''}`"
                          @click="$emit('calculateDead')"
                          class="button button--white button--small"
                  >
                    <i class="fas fa-sync"></i>
                    <span>Recalculate list</span>
                  </button>
                  <button title="Export XLSX"
                          @click="$emit('exportXLSX')"
                          class="button button--white button--small"
                  >
                    <i class="fas fa-file-excel"></i>
                    <span>Export XLSX</span>
                  </button>
                  <button :title="`Bulk email${deadParams.emailedAt?'. Latest at: '+deadParams.emailedAt:'. No emailing yet'}`"
                      @click="$emit('bulkEmail')"
                      class="button button--white"
                  >
                      <i class="fas fa-envelope"></i>
                      <span>Bulk email</span>
                  </button>
                  <button title="Bulk delete"
                      @click="$emit('bulkDelete')"
                      class="button button--red"
                  >
                      <i class="fas fa-trash-alt"></i>
                      <span>Bulk delete</span>
                  </button>
                </div>
            </template>
            <template v-if="isApproveList">
                <div class="view-bar">
                    <button
                        @click="
                            () =>
                                handleModalWindow({
                                    type: 'ImportApproveListFile',
                                    config: {
                                        importCallback: fetchUserCallback
                                    }
                                })
                        "
                        class="button button--white"
                    >
                        <i class="fas fa-file-import"></i>
                        Import file
                    </button>

                    <button
                        @click="
                            () =>
                                handleModalWindow({
                                    type: 'AddDomain',
                                    config: {
                                        domaineCallback: domaineCallback,
                                    },
                                })
                        "
                        class="button button--green button--small"
                    >
                        <i class="fas fa-plus-circle"></i>
                        Add domain
                    </button>
                </div>
            </template>
            <template v-if="isBlockList">
                <div class="view-bar">
                    <button
                        @click="
                            () =>
                                handleModalWindow({
                                    type: 'AddEmail',
                                    config: {
                                        addEmailCallback: addEmailCallback,
                                    },
                                })
                        "
                        class="button button--green button--small"
                    >
                        <i class="fas fa-plus-circle"></i>
                        Add email
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

export default {
    props: {
      deadParams: {
        type: Object
      },
        isApproveList: {
            type: Boolean,
        },
        isUserList: {
            type: Boolean,
        },
        isDeadList: {
            type: Boolean,
        },
        isBlockList: {
            type: Boolean,
        },
        toggleExportUserState: {
            type: Function,
        },
        addEmailCallback: {
            type: Function,
        },
        domaineCallback: {
            type: Function,
        },
        fetchUserCallback: {
            type: Function,
        },
        handleRoleSelect: {
            type: Function,
        },
        parentsList: {
            type: Object,
        },
    },
    data() {
        return {
          importAndExportState: false,
          pickedUserRoles: null,
          pickedDeadReason: {name: 'All reasons', value:-1},
          deadReasonsData: [
            {name: 'All reasons', value:-1},
            {name: '30d No activation', value:0},
            {name: '90d No trial', value:1},
            {name: '12m canceled', value:2},
          ],
          pickedDeadSelected: {name: 'Any accounts', value:'any'},
          deadSelectedData: [
            {name: 'Any accounts', value:'any'},
            {name: 'Only Selected', value:'yes'},
            {name: 'Only Unselected', value:'no'}
          ],
          deadToggle100: false,
          deadToggleAll: false
        }
    },
    computed: {
      ...mapGetters(['modalWindowState','storeUserInfo']),
      userInfo() {
        return this.storeUserInfo
      },
      userRolesData() {
        let a = ['All', 'User']
        if(this.userInfo.role === 'admin') {
          a = ['All', 'User', 'Agent', 'Reseller', 'Admin']
        }
        else if(this.userInfo.role === 'reseller') {
          a = ['All', 'User', 'Agent']
        }
        return a;
      }
    },
    methods: {
        ...mapActions(['handleModalWindow']),
        hideImportExportDropdown() {
            this.importAndExportState = false
        },
        showImportExportDropdown() {
            this.importAndExportState = true
        },
    },
    watch: {
        pickedUserRoles(val) {
            const userRoleParams = val === 'All' ? '' : val.toLowerCase()
            this.handleRoleSelect(userRoleParams)
        },
        pickedDeadReason(val) {
          this.$emit("pickedDeadReason", val)
        },
        pickedDeadSelected(val) {
          this.$emit("pickedSelectedSearch", val)
        },
    },
    components: {
        Multiselect,
    },
}
</script>
