<template>
  <ul tabindex="-1" class="ul-context-menu" :style="contextPosition" ref="ulContextMenu" @blur="blurFunction()">
    <li v-for="item in contextOptions" :key="item.value" @click="$root.$emit('ContextMenu:select', {value : item.value})">{{item.text}}</li>
  </ul>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ContextMenu",
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['storeSetContextMenuOptions']),
    blurFunction() {
      this.storeSetContextMenuOptions({top:0, left:0, show: false});
    }
  },
  computed: {
    ...mapGetters(['storeGetContextMenuOptions']),
    contextOptions() {
      return this.storeGetContextMenuOptions.options;
    },
    contextPosition() {
      let top = this.storeGetContextMenuOptions.top;
      let left = this.storeGetContextMenuOptions.left;
      if(this.$refs.ulContextMenu) {
        const clientRects = this.$refs.ulContextMenu.getClientRects()
        const menuClientRect = clientRects[0];
        if(menuClientRect && menuClientRect.width && menuClientRect.height) {
          if (left + menuClientRect.width >= window.innerWidth) {
            left -= menuClientRect.width;
          }
          if (top + menuClientRect.height >= window.innerHeight) {
            top -= menuClientRect.height;
          }
        }
      }
      return `top: ${top}px; left: ${left}px`
    },
    contextShow() {
      return this.storeGetContextMenuOptions.show;
    }
  },
  watch: {
    contextShow() {
      if(this.contextShow && this.$refs.ulContextMenu) {
        this.$refs.ulContextMenu.focus();
      }
    }
  }
}
</script>
