<template id="accordion">
    <div>
        <div class="tab-item">
            <div
                class="tab-heading"
                @click="expandList"
                :class="{ isExpanded: visible }"
            >
                <p class="form-title" name="heading">{{ title }}</p>
                <i class="fa fa-chevron-down" aria-hidden="true"></i>
            </div>

                <div class="tab-body" :class="{ 'hidden': !visible }">
                    <slot></slot>
                </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title'],
    inject: ['Accordion'],
    data() {
        return {
          expanded: false,
          index: null,
        }
    },
    computed: {
        visible() {
          return this.index === this.Accordion.active
        },
    },
    methods: {
      expandList() {
        if (this.visible) {
          this.Accordion.active = null
        } else {
          this.Accordion.active = this.index
          }
        }
    },
    created() {
        this.index = this.Accordion.count++
    },
}
</script>
