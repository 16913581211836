import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'
import router from './router'

import Notifications from 'vue-notification'
import * as Sentry from '@sentry/vue';
Vue.use(Notifications)
Vue.use(Vuex);

const getErrorMessage = (error) =>
  error.response?.data?.message ?? error?.message ?? null;

export default new Vuex.Store({
  state: {
    isLogged: false,
    generalProgressBarState: 0,
    generalUploadProgressBarCount: 0,
    generalUploadProgressBarCurrent: 0,
    userInfo: null,
    modalVisible: false,
    accountTab: null,
    leftMenu: true,
    parentFolder: null,
    authExpiration: null,
    oldPath: null,
    libraryContent: null,
    librariesSummary: null,
    uploadDocumentCallback: null,
    selectedLibraryItem: null,
    currentFolderId:null,
    folderPathArray: null,
    modalWindowState: {
      isOpen: false,
      isNetworkRequestLoading: false,
      type: '',
      itemTitle: null,
      config: {}
    },
    sideBarState: {
      isOpen: false,
    },
    shareState: false,
    sharingType: '',
    folders: [],
    userRoles: [
      { name: 'Academic/Lecturer', value: 'academic' },
      { name: 'Postdoc researcher', value: 'researcher' },
      { name: 'PhD student', value: 'postgraduate-student' },
      { name: 'Masters student', value: 'postgraduate-student' },
      {
        name: 'Undergraduate student',
        value: 'undergraduate-student',
      },
      { name: 'Journalist', value: 'journalist' },
      {
        name: 'Researcher/analyst – commercial',
        value: 'researcher',
      },
      {
        name: 'Researcher/analyst – public sector',
        value: 'researcher',
      },
      {
        name: 'Researcher/analyst – charitable sector',
        value: 'researcher',
      },
      { name: 'Other', value: 'other' },
    ],
    subscribtionPlanList: [
      { name: 'No subscription', value: 'none' },
      { name: 'Individual Free', value: 'premium-free' },
      { name: 'Institution monthly', value: 'institution' },
      { name: 'Institution Annual', value: 'institution_annual' },
      { name: 'Individual', value: 'premium' },
    ],
    sharedFromList: [],
    sharedMeList: [],
    openedArticleContent: {},
    contextMenuArticleView: false,
    contextMenuArticleTop: '0px',
    contextMenuArticleLeft: '0px',
    contextMenuOptions: {top: 0, left: 0, options: {value: 0, text:''}, show: false},
    selectedFoldersIds: [],
    selectedText: '',
    isGP_GISLoaded: false,
    isGP_APILoaded: false,
    isArticlesShowDuplicates: false,
    isGlobalDocumentBusy: false,
    filterText:''
  },
  mutations: {
    openShareState(state) {
      state.shareState = true;
    },
    closeShareState(state) {
      state.shareState = false;
    },
    setSharingType(state, params) {
      state.sharingType = params;
    },
    storeSetCurrentFolderId(state, payload) {
      state.currentFolderId = payload;
    },
    storeSetFilterText(state, payload) {
      state.filterText = payload;
    },
    storeSetGlobalDocumentBusy(state, payload) {
      state.isGlobalDocumentBusy = payload;
    },
    smtToggleLoaderDiv(state, divId, styleDisplay='none') {
      const el = document.querySelector(divId);
      if(el && el.style.display !== styleDisplay) {
        el.style.display = styleDisplay;
      }
    },
    showContextMenuArticle(state, params) {
      if("show" in params) {
        state.contextMenuArticleView = params.show;
      }
      state.contextMenuArticleTop = ("top" in params)?params.top + 'px':'0px';
      state.contextMenuArticleLeft = ("left" in params)?params.left + 'px':'0px';
    },
    LOGIN_USER(state) {
      state.isLogged = true;
    },
    LOGOUT_USER(state) {
      state.isLogged = false;
      // When we log out need to close opened modal window if exists
      const modal = document.querySelector(".modal");
      modal && modal.parentNode && modal.parentNode?.remove();
    },
    LOGOUT2_USER(state, toHome=false) {
      state.isLogged = false;
      if(toHome) {
        localStorage.setItem("curPath", null);
        localStorage.setItem("oldPath", null);
      }
      // When we log out need to close opened modal window if exists
      const modal = document.querySelector(".modal");
      modal && modal.parentNode && modal.parentNode?.remove();
    },
    UPDATE_USER_INFO(state, newUserInfo) {
      state.userInfo = newUserInfo;
    },
    UPDATE_LIBS_ORDER(state, newLibs) {
      state.userInfo.user_libs = newLibs;
    },
    UPDATE_USER_SETTINGS(state, newOptions) {
      state.userInfo.options = newOptions;
    },
    UPDATE_USER_COLUMNS(state, newColumns) {
      state.userInfo.settings.columns = newColumns;
    },
    UPDATE_USER_EXPORT(state, newSections) {
      state.userInfo.settings.exportSections = newSections;
    },
    UPDATE_USER_EXPORT_DEFAULT_FORMAT(state, newFormat) {
      state.userInfo.settings.exportDefault = newFormat;
    },
    UPDATE_USER_COLUMNS_LENGTH(state, newLength) {
      state.userInfo.settings.columnsLength = newLength;
    },
    UPDATE_USER_VIEW(state, newViewConfig) {
      state.userInfo.settings.userView = newViewConfig;
    },
    hideModal(state) {
      state.modalVisible = false;
    },
    showMenu(state) {
      state.leftMenu = true;
    },
    hideMenu(state) {
      state.leftMenu = false;
    },
    SET_PARENT(state, folder) {
      state.parentFolder = folder;
    },
    setGeneralUploadProgressBarCount(state, payload) {
      state.generalUploadProgressBarCount = payload
    },
    setGeneralUploadProgressBarCurrent(state, payload) {
      state.generalUploadProgressBarCurrent = payload
    },
    increaseGeneralUploadProgressBarCurrent(state) {
      state.generalUploadProgressBarCurrent += 1
    },
    decreaseGeneralUploadProgressBarCurrent(state) {
      state.generalUploadProgressBarCount -= 1
    },
    increaseGeneralProgressBarState(state, payload) {
      state.generalProgressBarState = payload
    },
    decreaseGeneralProgressBarState(state, payload) {
      state.generalProgressBarState = payload
    },
    setLibraryContent(state, payload) {
      state.libraryContent = payload
    },
    setLibraryContentSortOrder(state, payload) {
      state.userInfo.settings.userView.sortOrder = payload
    },
    setSharedFromList(state, payload) {
      state.sharedFromList = payload
    },
    setSharedMeList(state, payload) {
      state.sharedMeList = payload
    },
    setLibrariesSummary(state, payload) {
      state.librariesSummary = payload
    },
    setUploadDocumentCallback(state, payload) {
      state.uploadDocumentCallback = payload
    },
    startModalNetworkRequest(state) {
      state.modalWindowState.isNetworkRequestLoading = true;
    },
    endModalNetworkRequest(state) {
      state.modalWindowState.isNetworkRequestLoading = false;
    },
    handleModalWindow(state, payload) {
      const { modalWindowState } = state;
      const { isNetworkRequestLoading, isOpen } = modalWindowState;

      if(payload.type === "hide" && isNetworkRequestLoading) {
        payload.preventDefault();
        return
      }

      state.modalWindowState.isOpen = !state.modalWindowState.isOpen;
      state.modalWindowState.type = state.modalWindowState.isOpen ? payload.type : ''
      state.modalWindowState.itemTitle = state.modalWindowState.isOpen ? payload.itemTitle : null
      state.modalWindowState.config = state.modalWindowState.isOpen ? payload.config : {}
    },
    handleSideBar(state) {
      state.sideBarState.isOpen = !state.sideBarState.isOpen;
    },


    setSelectedLibraryItem(state, payload) {
      state.selectedLibraryItem = payload
    },
    setFolderPathArray(state, payload) {
      state.folderPathArray = payload
    },
    setOpenedArticleContent(state, payload) {
      state.openedArticleContent = payload;
    },
    storeSetSelectedText(state, payload) {
      state.selectedText = payload;
    },
    storeSetIsArticlesShowDuplicates(state, payload) {
      state.isArticlesShowDuplicates = payload;
    },
    storePushToSelectedFoldersIds(state, payload) {
      state.selectedFoldersIds.push(payload);
    },
    storeSpliceSelectedFoldersIds(state, payload) {
      state.selectedFoldersIds.splice(payload.pos,payload.cnt || 1);
    },
    storeClearSelectedFoldersIds(state) {
      state.selectedFoldersIds = [];
    },
    storeSetGP_GISLoaded(state, payload) {
      state.isGP_GISLoaded = payload;
    },
    storeSetGP_APILoaded(state, payload) {
      state.isGP_APILoaded = payload;
    },
    storeSetContextMenuOptions(state, payload) {
      state.contextMenuOptions = {...state.contextMenuOptions, ...payload};
    }
  },
  actions: {
    actionShowContextMenuArticle({commit}, payload) {
      commit('showContextMenuArticle', payload);
    },
    setOpenedArticleContent({commit}, payload) {
      commit('setOpenedArticleContent', payload);
    },
    async waitingInMs({},payload) {
      return new Promise(resolve => setTimeout(resolve, payload.delay));
    },
    async LOAD_USER_INFO({ commit, dispatch }, payload) {
      try {
        dispatch('increaseGeneralProgressBarState');
        const response = await api.get('/user/profile');
        const userData = response.data;
        commit('UPDATE_USER_INFO', userData)
        dispatch('fetchLibrariesSummary');
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    openShareState({ commit }) {
      commit('openShareState')
    },
    closeShareState({ commit }) {
      commit('closeShareState')
    },
    setSharingType({ commit }, params) {
      commit('setSharingType', params)
    },
    increaseGeneralUploadProgressBarCurrent({ commit }) {
      commit('increaseGeneralUploadProgressBarCurrent');
    },
    decreaseGeneralUploadProgressBarCurrent({ commit }) {
      commit('decreaseGeneralUploadProgressBarCurrent');
    },
    setGeneralUploadProgressBarCurrent({ commit }, payload) {
      commit('setGeneralUploadProgressBarCurrent', payload);
    },
    setGeneralUploadProgressBarCount({ commit }, payload) {
      commit('setGeneralUploadProgressBarCount', payload);
    },
    increaseGeneralProgressBarState({ commit, getters }) {
      const generalProgressBarState = getters.generalProgressBarState
      commit('increaseGeneralProgressBarState', generalProgressBarState + 1);
    },
    decreaseGeneralProgressBarState({ commit, getters }) {
      const generalProgressBarState = getters.generalProgressBarState
      commit('decreaseGeneralProgressBarState', generalProgressBarState - 1);
    },
    async redirectToUrl({ commit, dispatch }, payload) {
      await router.push(payload);
    },
    async fetchLibraryContent({ commit, dispatch, getters }, payload) {
      try {
        dispatch('increaseGeneralProgressBarState');
        const response = await api.get('/library', { params: payload })
        commit('setLibraryContent', response.data);
        dispatch('setFolderPath', response.data.fullPath);
        dispatch('setSelectedLibraryItem', payload.libraryId);
        commit('storeSetCurrentFolderId', payload.folderId || null);
        commit('setLibraryContentSortOrder', response.data.sort || {});
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('decreaseGeneralProgressBarState');
        if(error.response && error.response.status === 404) {
          await router.push('/')
        }
      }
    },
    async fetchLibrariesSummary({ commit, dispatch }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        const response = await api.get(`/library/summary`)
        commit('setLibrariesSummary', response.data.summary);
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    async fetchSharedFromList({ commit, dispatch }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        const response = await api.get('/library/sharedFrom')
        commit('setSharedFromList', response.data);
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    async fetchSharedMeList({ commit, dispatch }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        const response = await api.get('/library/sharedMe')
        commit('setSharedMeList', response.data);
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    // Pass file or document to Extract API
    async uploadDocument({ commit, dispatch }, { body, extraHeaders, text = 'Completed', errorText = "", durationMessage, durationErrorMessage }) {
      try {
        dispatch('increaseGeneralUploadProgressBarCurrent')
        const response = await api.post('/extract', body, {headers: extraHeaders || {}});
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text, duration: durationMessage || 5000 });
        const doc = (response.data && Array.isArray(response.data) && response.data.length > 0)?response.data[0] : response.data || null;
        return doc;
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', duration: durationErrorMessage || 5000, text: errorText || 'We were unable to process this document. Please try it again, or contact support for assistance', });
      }
    },
    // Pass JSON block directly to the Library
    async addDocument({ commit, dispatch }, { body, extraHeaders, text = 'Completed', errorText, durationMessage, durationErrorMessage }) {
      try {
        dispatch('increaseGeneralUploadProgressBarCurrent')
        const response = await api.post("/library/add", body, {headers: extraHeaders || {}});
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text, duration: durationMessage || 5000 });
        const doc = (response.data && Array.isArray(response.data) && response.data.length > 0)?response.data[0] : response.data || null;
        return doc;
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', duration: durationErrorMessage || 5000, text: errorText || 'We were unable to process this document. Please try it again, or contact support for assistance', });
      }
    },
    async handleDeleteArticle({ commit, dispatch }, { params, method = 'post', text = 'Deleted Article' }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        await api[method]('/library/remove', params);
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text });
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', text: getErrorMessage(error), });
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    async handleSharedMeStop({ commit, dispatch }, { params, method = 'delete', text = 'The sharing has been completed' }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        await api[method](`/library/sharedMe`, {data: params});
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text });
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', text: getErrorMessage(error), });
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    async handleSharedMeUpdate({ commit, dispatch }, { params, method = 'patch', text = 'The sharing has been updated' }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        await api[method](`/library/sharedMe`, params);
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text });
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', text: getErrorMessage(error), });
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    async handleSharingStop({ commit, dispatch }, { params, method = 'delete', text = 'The sharing has been completed' }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        await api[method](`/library/share/${params.id}`, params);
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text });
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', text: getErrorMessage(error), });
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    setSelectedLibraryItem({ commit, getters }, payload) {
      let selectedLibraryItem = { _id: -999, name: 'Unknown' };
      if(!Number.isNaN(payload) && Number.parseInt(payload) === this._vm.$trashLibId) {
        selectedLibraryItem = { _id: this._vm.$trashLibId, name: 'Trash' }
      }
      else if(!Number.isNaN(payload) && Number.parseInt(payload) === this._vm.$favouritesId) {
        selectedLibraryItem = { _id: this._vm.$favouritesId, name: 'Favourites' }
      }
      else {
        selectedLibraryItem = payload ? getters.userLibraryList.find((el) => el._id.toString() === payload.toString()) : undefined;
        if(!selectedLibraryItem) {
          const _a = payload ? getters.storeUserInfo.shared_libs.find((el) => el.library_id.toString() === payload.toString()) : []
          selectedLibraryItem = _a ? { _id: _a.library_id, name: _a.library_name }:{ _id: -999, name: 'Unknown' }
        }
      }
      commit('setSelectedLibraryItem', selectedLibraryItem);
    },
    deleteLibraryItem({ commit }, payload) {
      commit('deleteLibraryItem', payload);
    },
    async handleDeleteFolder({ commit, dispatch }, { params, method = 'post', text = 'Deleted Folder' }) {
      try {
        dispatch('increaseGeneralProgressBarState');
        await api[method]('/library/folder-delete', params);
        dispatch('handleActionNotification', { group: 'auth', type: 'success', title: 'Success', text });
        dispatch('decreaseGeneralProgressBarState');
      } catch (error) {
        Sentry.captureException(error);
        dispatch('handleActionNotification', { group: 'auth', type: 'error', title: 'Error', text: getErrorMessage(error), });
        dispatch('decreaseGeneralProgressBarState');
      }
    },
    setFolderPath({ commit }, payload) {
      const foldersPathArray = payload.split('</a>');
      const filteredFoldersPathArray = [];
      foldersPathArray.forEach((path) => {
        if (path !== '') {
          const startUrlString = "library/"
          const endUrlString = "'>"
          const startUrlPosition = path.indexOf(startUrlString)
          const endUrlPosition = path.indexOf(endUrlString)

          const url = path.substring(startUrlPosition + startUrlString.length, endUrlPosition)
          const name = path.substring(endUrlPosition + endUrlString.length)
          const idArray = url.split('/')

          filteredFoldersPathArray.push({ libraryPathId: idArray[0], libraryFolderId: idArray[1], folderPathName: name })
        }
      });
      commit('setFolderPathArray', filteredFoldersPathArray);
    },
    handleSideBar({ commit }) {
      commit('handleSideBar');
    },

    startModalNetworkRequest({ commit }) {
      commit('startModalNetworkRequest');
    },
    endModalNetworkRequest({ commit }) {
      commit('endModalNetworkRequest');
    },
    handleModalWindow({ commit }, payload) {
      commit('handleModalWindow', payload);
    },
    setUploadDocumentCallback({ commit }, payload) {
      commit('setUploadDocumentCallback', payload);
    },
    handleActionNotification({ commit }, payload) {
      this._vm.$notify(payload)
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLogged
    },
    getParent: state => {
      return state.parentFolder
    },
    authExpiration: state => {
      return state.authExpiration;
    },
    generalProgressBarState: state => {
      return state.generalProgressBarState;
    },
    generalUploadProgressBarCurrent: state => {
      return state.generalUploadProgressBarCurrent;
    },
    generalUploadProgressBarCount: state => {
      return state.generalUploadProgressBarCount;
    },
    userLibraryList: state => {
      return (state.userInfo)?state.userInfo.user_libs:null
    },
    userLibraryListOrder: state => {
      return (state.userInfo && state.userInfo.settings.userView && state.userInfo.settings.userView.sortOrder)?state.userInfo.settings.userView.sortOrder:{orderIndex:-1}
    },
    userArticleColumnsList: state => {
      return (state.userInfo)?state.userInfo.settings.columns: null
    },
    userArticleRowsOnPage: state => {
      return (state.userInfo)?state.userInfo.settings.userView.rowsOnPage: null
    },
    libraryContent: state => {
      return state.libraryContent
    },
    modalWindowState: state => {
      return state.modalWindowState
    },
    selectedLibraryItem: state => {
      return state.selectedLibraryItem ? state.selectedLibraryItem : {_id: 0, name: ''}
    },
    folderPathArray: state => {
      return state.folderPathArray
    },
    sideBarState: state => {
      return state.sideBarState
    },

    shareState: state => {
      return state.shareState
    },
    folders: state => {
      return state.folders
    },
    sharingType: state => {
      return state.sharingType
    },
    userRolesList: state => {
      return state.userRoles
    },
    subscribtionPlanList: state => {
      return state.subscribtionPlanList
    },
    librariesSummary: state => {
      return state.librariesSummary
    },
    uploadDocumentCallback: state => {
      return state.uploadDocumentCallback
    },
    sharedFromList: state => {
      return state.sharedFromList
    },
    sharedMeList: state => {
      return state.sharedMeList
    },
    getOpenedArticleContent: state => {
      return state.openedArticleContent;
    },
    getShowContextMenuArticleView: state => {
      return state.contextMenuArticleView;
    },
    getShowContextMenuArticlePosition: state => {
      return {top: state.contextMenuArticleTop, left: state.contextMenuArticleLeft}
    },
    storeGetContextMenuOptions: state => {
      return state.contextMenuOptions;
    },
    storeUserInfo: state => {return state.userInfo},
    storeLeftMenu: state => {return state.leftMenu},
    storeSelectedFoldersIds: state => {return state.selectedFoldersIds},
    storeSelectedText: state => { return state.selectedText},
    storeIsArticlesShowDuplicates: state => { return state.isArticlesShowDuplicates},
    storeIsGP_GIS_Loaded: state => { return state.isGP_GISLoaded},
    storeIsGP_API_Loaded: state => { return state.isGP_APILoaded},
    storeGetGlobalDocumentBusy: state => { return state.isGlobalDocumentBusy},
    storeFilterText: state => { return state.filterText },
    storeCurrentFolderId: state => { return state.currentFolderId }
  }
})
