<template>
    <div>
        <form class="form">
            <div class="form-group">
              <label>Start import date:</label>
                <input type="date" v-model="startDate" style="margin-left:1rem "/>
            </div>
        </form>
        <div class="modal-footer">
            <button
                class="button button--purple button--small"
                @click="handleImport"
            >
                <i class="fas fa-check-circle"></i>
                Import
            </button>
            <button
                class="button button--white button--small"
                @click.prevent="hideModal"
            >
                Cancel
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { captureReportableError } from '@/util/errorTracker';

export default {
    props: {
        hideModal: {
            type: Function,
        },
    },
    data() {
        return {
            startDate: null
        }
    },
    methods: {
      ...mapMutations(['storeSetGlobalDocumentBusy']),
        async handleImport() {
          this.storeSetGlobalDocumentBusy(true);
          const url = '/admin/import-stripe-invoices/'
          try {
              const result = await this.$api.get(url, {params: {startDate: this.startDate}, timeout:0})
              this.$notify({
                  group: 'auth',
                  type: 'success',
                  title: 'Success',
                  text: `${result.data.cntInvoices} invoices are imported from Stripe`,
              })
          } catch (err) {
              captureReportableError(err);

              this.$notify({
                  group: 'auth',
                  type: 'error',
                  title: 'Error',
                  text: err.response?.data?.message || err.response?.message || "Unexpected error. Please try again later.",
              })
          }
          this.storeSetGlobalDocumentBusy(false);
          this.hideModal()
        },
    },
    computed: {
        ...mapGetters(['modalWindowState']),
    },
    mounted() {
        const dt = new Date();
        dt.setTime(dt.getTime()-60*60*24*2*1000);
        this.startDate = dt.toISOString().substr(0,10);
    },
}
</script>
