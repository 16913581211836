<template>
  <div class="component-parent-block-busy-panel" v-if="bShowLoadingSpinner">
    <Spinner size="huge"></Spinner>
  </div>
</template>

<script>
import Spinner from "vue-simple-spinner";

export default {
  name: "ParentBlockBusyPanel",
  props: {
    bShowLoadingSpinner: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Spinner
  }
}
</script>
